import { useEffect, useState } from "react"

function Cardbacks(props){
    const [userCardback, setUserCardBack] = useState(null)

    useEffect(() => {
        if(props.selectedCardBack === props.cardBack){
            setUserCardBack(props.selectedCardBack)
        }else{
            setUserCardBack(null)
        }
    },[props.selectedCardBack,props.cardBack])
    //Card Display Component
    return (
        <div id={userCardback ? "selectedCardback" : null} className={`deck ${props.cardBack} cardBackDefaultSettings`} onClick={props.onClick}></div>
    )
}

export default Cardbacks