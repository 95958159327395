import React, { useContext, useState, useEffect } from "react"
import Card from "../components/card"
import { GameContext } from "../App"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { toLower } from "lodash"
import { FaBurn } from "react-icons/fa"

const CardDetail = () => {
  const { user, cards, setUser, socket } = useContext(GameContext)
  const params = useParams()
  const [card, setCard] = useState(null)
  const [ownedStyles, setOwnedStyles] = useState([])
  const [cardStyle, setCardStyle] = useState("normalCard")
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const card = cards.filter((c) => {
      return toLower(c.id) === params.id
    })[0]
    setCard(card)
    setOwnedStyles(user.owned)
    setCardStyle(user.usersettings?.[card.name]?.style ?? cardStyle)
  }, [user, cards])

  useEffect(() => {}, [cardStyle])

  const setTitle = (text) => {
    return text[0].toUpperCase() + text.slice(1)
  }

  const doFocusCard = (style) => {
    if (cardStyle !== style && cardStyleOwned(style)) {
      confirmSetStyle(style)
    }
  }

  const confirmSetStyle = (style) => {
    socket.current.emit("update-user", {
      ...user,
      style: style,
      cardName: card.name,
      type: "cardStyle",
    })
  }

  const cardStyleOwned = (style) => {
    return (
      ownedStyles &&
      ownedStyles[card.name] &&
      ownedStyles[card.name]?.style?.[style]
    )
  }

  const redirectToProfile = () => {
    if (location?.state?.from === "/profile") navigate(-1)
    else navigate("/profile")
  }

  const render = () => {
    if (card) {
      return (
        <div className="cardCollectionContainer">
          <h1>
            #{card.id} <span>{card.name}</span> <FaBurn fontSize={27} />{" "}
            {card.cost}
          </h1>
          <div className="basicFlexDisplay cardDetail ">
            <div className="storeDisplay">
              <Card
                cardStyle={cardStyle}
                key={`${card.id}${card.index}`}
                card={card}
                onClick={redirectToProfile}
              />
            </div>
            <div className="cardDetail cardInfo">
              <h3>
                <span className="type">
                  {setTitle(card.type) + "-" + setTitle(card.class)}
                </span>
                <span className="rarity">{setTitle(card.rarity)}</span>
              </h3>
              <label>{card.text}</label>
              <p>
                <i>-{"Lore Flavor Text ;)"}</i>
              </p>
            </div>
          </div>
          <div className="basicFlexDisplay cardStyleDisplay">
            <div className="cardDisplay">
              <Card
                card={card}
                cardStyle={"normalCard"}
                onClick={() => {
                  if (cardStyle !== "normalCard") confirmSetStyle("normalCard")
                }}
              />
              <div
                onClick={() => {
                  if (!cardStyleOwned("fullArtCard"))
                    navigate(`/store/card/${card.id}`)
                }}
                className={
                  "cardStoreDisplay " +
                  (cardStyleOwned("fullArtCard") ?? "unowned")
                }
              >
                <Card
                  card={card}
                  cardStyle={"fullArtCard"}
                  onClick={() => {
                    if (cardStyleOwned("fullArtCard"))
                      doFocusCard("fullArtCard")
                  }}
                />
              </div>
              <div
                onClick={() => {
                  if (!cardStyleOwned("threeDCard"))
                    navigate(`/store/card/${card.id}`)
                }}
                className={
                  "cardStoreDisplay " +
                  (cardStyleOwned("threeDCard") ?? "unowned")
                }
              >
                <Card
                  card={card}
                  cardStyle={"threeDCard"}
                  onClick={() => {
                    if (cardStyleOwned("threeDCard")) doFocusCard("threeDCard")
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )
    } else return <></>
  }

  return (
    <>
      <div>{render()}</div>
    </>
  )
}

export default CardDetail
