import { useContext } from "react"
import GameResult from "../gameResult"
import { GameContext } from "../../App"

const MatchHistory = () => {
  const { user } = useContext(GameContext)
  const matchHistory = user?.usersettings?.matchHistory || []

  return (
    <>
      {matchHistory.map((gameObject) => {
        return <GameResult gameObject={gameObject} />
      })}
    </>
  )
}

export default MatchHistory
