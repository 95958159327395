import React, { useState, useEffect } from 'react';

const ManaDisplay = ({ mana, totalMana }) => {
  const [isManaChanging, setIsManaChanging] = useState(false);

  useEffect(() => {
    if (mana) {
      setIsManaChanging(true);
      const timer = setTimeout(() => {
        setIsManaChanging(false);
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [mana, totalMana]); 

  return (
    <div id="mana" className={`${isManaChanging ? 'mana-change' : ''} gameFontColors`}>
       Mana: {mana}/{totalMana}
    </div>
  );
};

export default ManaDisplay;