import { GameContext } from "../../App"
import { useContext } from "react"
import ToggleOnIcon from "@mui/icons-material/ToggleOn"
import ToggleOffOutlinedIcon from "@mui/icons-material/ToggleOffOutlined"

const Settings = () => {
  const { user, socket } = useContext(GameContext)

  // Define a style object for larger toggle icons
  const toggleIconStyle = {
    color: "#28292d",
    fontSize: "4rem", // Adjust the font size here to make the toggles larger
  }

  // Function to toggle mute sound setting
  const toggleMuteSound = () => {
    socket.current.emit("update-user", {
      ...user,
      type: "muteSound",
    })
  }

  // Function to toggle automatic fullscreen setting
  const toggleAutomaticFullScreen = () => {
    socket.current.emit("update-user", {
      ...user,
      type: "autoFullScreen",
    })
  }

  // Function to toggle automatic menu hide setting
  const toggleAutomaticMenuHide = () => {
    socket.current.emit("update-user", {
      ...user,
      type: "menuHide",
    })
  }

  return (
    <div className="settingsMenu">
      <div className="optionsContainer">
        <div className="option">
          <h1 className="gameFontColors optionWidth">Mute Sound:</h1>
          <div className="toggleContainer optionWidth">
            <h1 className="settingsToggle">
              {user.usersettings?.muteSound ? (
                <ToggleOnIcon
                  onClick={toggleMuteSound}
                  style={toggleIconStyle}
                />
              ) : (
                <ToggleOffOutlinedIcon
                  onClick={toggleMuteSound}
                  style={toggleIconStyle}
                />
              )}
            </h1>
          </div>
        </div>
        <div className="option">
          <h1 className="gameFontColors optionWidth">Automatic Full Screen:</h1>
          <div className="toggleContainer optionWidth">
            <h1 className="settingsToggle">
              {user.usersettings?.automaticFullScreen ? (
                <ToggleOnIcon
                  onClick={toggleAutomaticFullScreen}
                  style={toggleIconStyle}
                />
              ) : (
                <ToggleOffOutlinedIcon
                  onClick={toggleAutomaticFullScreen}
                  style={toggleIconStyle}
                />
              )}
            </h1>
          </div>
        </div>
        <div className="option">
          <h1 className="gameFontColors optionWidth">Automatic Menu Hide:</h1>
          <div className="toggleContainer optionWidth">
            <h1 className="settingsToggle">
              {user.usersettings?.automaticMenuHide ||
              user.usersettings?.automaticMenuHide === undefined ? (
                <ToggleOnIcon
                  onClick={toggleAutomaticMenuHide}
                  style={toggleIconStyle}
                />
              ) : (
                <ToggleOffOutlinedIcon
                  onClick={toggleAutomaticMenuHide}
                  style={toggleIconStyle}
                />
              )}
            </h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings
