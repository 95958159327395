import React, { useContext, useState, useEffect } from "react"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { GameContext } from "../App"
import { toLower } from "lodash"
import Card from "../components/card"
import PointImage from "../background-images/pointLogo.png"
import Carousel from "../components/global/carousel"

const CardStore = () => {
  const { user, cards, socket } = useContext(GameContext)
  const params = useParams()
  const [storeCard, setCard] = useState()
  const [cardStyle, setCardStyle] = useState("normalCard")
  const navigate = useNavigate()
  const location = useLocation()
  const [popUpImg, setPopUpImg] = useState(false)
  const [popUpImgArray, setPopUpImgArray] = useState([])

  useEffect(() => {
    if (storeCard?.alternateArts) {
      const promises = storeCard.alternateArts.map((alternateArt, i) =>
        import(`../images/${alternateArt}.png`)
          .then(() => ({ index: i, exists: true }))
          .catch(() => ({ index: i, exists: false }))
      )

      Promise.all(promises)
        .then((threeDExists) => {
          setPopUpImgArray(threeDExists)
        })
        .catch((err) => {
          console.error("Error:", err)
        })
    } else if (storeCard) {
      import(`../images/${storeCard?.image}.png`)
        .then(() => {
          setPopUpImg(true)
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }, [storeCard])

  useEffect(() => {
    const card = cards.filter((c) => {
      return toLower(c.id) === params.id
    })[0]
    setCard(card)
    setCardStyle(user.usersettings?.[card.name]?.style ?? cardStyle)
  }, [user, cards])

  useEffect(() => {}, [cardStyle])

  const selectPurchase = (card, style, alternateArt = false) => {
    const newUser = { ...user }
    if (newUser.owned?.[card.name]?.style?.[style] || style === "normalCard") {
      socket.current.emit("update-user", {
        ...user,
        style,
        cardName: card.name,
        cardImage: card.image,
        alternateArt: alternateArt,
        type: "cardStyle",
      })
    } else {
      if (!newUser.owned?.[card.name]) {
        newUser.owned[card.name] = {}
      }
      if (!newUser.owned?.[card.name]?.style) {
        newUser.owned[card.name].style = {}
      }
    }
  }

  const buyArt = (card, style, alternateArt = false) => {
    const newUser = { ...user }
    if (
      !newUser.owned?.[card.name]?.style?.[style] ||
      !newUser.owned?.[card.name]?.[card.image]?.[style]
    )
      socket.current.emit("purchase-card-style", {
        card,
        style,
        email: newUser.email,
        alternateArt: alternateArt,
      })
  }

  const returnToStore = () => {
    if (location?.state?.from === "/store") navigate(-1)
    else navigate("/store")
  }

  const cardArtGenerator = () => {
    const artStyle = ["normalCard", "fullArtCard", "threeDCard"]
    const alternateStyles = storeCard?.alternateArts
    const artsArray = []
    if (!alternateStyles) {
      for (let i = 0; i < artStyle.length; i++) {
        if (!popUpImg && artStyle[i] === "threeDCard") continue
        artsArray.push(
          (() => {
            return (
              <div
                className={`${
                  user.owned?.[storeCard.name]?.style?.[artStyle[i]] ||
                  artStyle[i] === "normalCard"
                    ? "owned"
                    : ""
                }`}
              >
                <Card
                  card={storeCard}
                  cardStyle={artStyle[i]}
                  onClick={() => selectPurchase(storeCard, artStyle[i])}
                />
                {artStyle[i] !== "normalCard" &&
                  !user.owned?.[storeCard.name]?.style?.[artStyle[i]] && (
                    <div
                      className="pointDisplayCost"
                      onClick={() => buyArt(storeCard, artStyle[i])}
                    >
                      <img
                        className="pointImgStore"
                        src={PointImage}
                        alt="pointLogo"
                      ></img>
                      <div>{artStyle[i] === "fullArtCard" ? 100 : 200}</div>
                    </div>
                  )}
              </div>
            )
          })()
        )
      }
    } else {
      for (let i = 0; i < alternateStyles?.length; i++) {
        const card = { ...storeCard }
        card.image = storeCard?.alternateArts[i]

        const popUpImgInfo = popUpImgArray.find((popUp) => popUp.index === i)
        for (let j = 0; j < artStyle.length; j++) {
          if (!popUpImgInfo?.exists && artStyle[j] === "threeDCard") continue
          artsArray.push(
            (() => {
              return (
                <div
                  className={`${
                    artStyle[j] !== "normalCard" &&
                    ((i === 0 &&
                      !user.owned?.[card.name]?.style?.[artStyle[j]]) ||
                      (i > 0 &&
                        !user.owned?.[card.name]?.[card.image]?.[artStyle[j]]))
                      ? ""
                      : "owned"
                  }`}
                >
                  <Card
                    key={`${card.image}${artStyle[j]}`}
                    card={card}
                    cardStyle={artStyle[j]}
                    onClick={() => selectPurchase(card, artStyle[j], i > 0)}
                  />
                  {artStyle[j] !== "normalCard" &&
                    ((i === 0 &&
                      !user.owned?.[card.name]?.style?.[artStyle[j]]) ||
                      (i > 0 &&
                        !user.owned?.[card.name]?.[card.image]?.[
                          artStyle[j]
                        ])) && (
                      <div
                        className="pointDisplayCost"
                        onClick={() => buyArt(card, artStyle[j], i > 0)}
                      >
                        <img
                          className="pointImgStore"
                          src={PointImage}
                          alt="pointLogo"
                        ></img>
                        <div>{artStyle[j] === "fullArtCard" ? 100 : 200}</div>
                      </div>
                    )}
                </div>
              )
            })()
          )
        }
      }
    }

    return artsArray
  }

  return (
    <>
      <div className="storeDisplayContainer">
        {storeCard && (
          <>
            <div className="storeDisplay">
              <Card
                card={storeCard}
                cardStyle={user.usersettings?.[storeCard.name]?.style}
                onClick={() => returnToStore()}
              />
            </div>
            <div className="storeDisplay">
              <Carousel>{cardArtGenerator()}</Carousel>
              {/* <Card
                card={storeCard}
                cardStyle={"normalCard"}
                onClick={() => selectPurchase(storeCard, "normalCard")}
              />
              <div
                className={`cardStoreDisplay ${
                  !user.owned?.[storeCard.name]?.style?.["fullArtCard"]
                    ? "pointDisplayCostAlignment"
                    : ""
                }`}
              >
                <Card
                  card={storeCard}
                  cardStyle={"fullArtCard"}
                  onClick={() => selectPurchase(storeCard, "fullArtCard")}
                />
                {!user.owned?.[storeCard.name]?.style?.["fullArtCard"] && (
                  <div
                    className="pointDisplayCost"
                    onClick={() => buyArt(storeCard, "fullArtCard")}
                  >
                    <img
                      className="pointImgStore"
                      src={PointImage}
                      alt="pointLogo"
                    ></img>
                    <div>100</div>
                  </div>
                )}
              </div>
              {popUpImg && (
                <div
                  className={`cardStoreDisplay ${
                    !user.owned?.[storeCard.name]?.style?.["threeDCard"]
                      ? "pointDisplayCostAlignment"
                      : ""
                  }`}
                >
                  <Card
                    card={storeCard}
                    cardStyle={"threeDCard"}
                    onClick={() => selectPurchase(storeCard, "threeDCard")}
                  />
                  {!user.owned?.[storeCard.name]?.style?.["threeDCard"] && (
                    <div
                      className="pointDisplayCost"
                      onClick={() => buyArt(storeCard, "threeDCard")}
                    >
                      <img
                        className="pointImgStore"
                        src={PointImage}
                        alt="pointLogo"
                      ></img>
                      <div>200</div>
                    </div>
                  )}
                </div>
              )} */}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default CardStore
