import { useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { GameContext } from "../App"

const AuthHook = () => {
  const { signedIn, socket } = useContext(GameContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (!signedIn && ! socket?.current?.activeGame) {
      navigate("/")
    }
  }, [signedIn])
}

export default AuthHook