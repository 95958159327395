import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts"

function Chart(props) {
  const chartData = () => {
    const data = [
      { manaCost: 0, minions: 0, spells: 0 },
      { manaCost: 1, minions: 0, spells: 0 },
      { manaCost: 2, minions: 0, spells: 0 },
      { manaCost: 3, minions: 0, spells: 0 },
      { manaCost: 4, minions: 0, spells: 0 },
      { manaCost: 5, minions: 0, spells: 0 },
      { manaCost: 6, minions: 0, spells: 0 },
      { manaCost: 7, minions: 0, spells: 0 },
      { manaCost: 8, minions: 0, spells: 0 },
      { manaCost: 9, minions: 0, spells: 0 },
      { manaCost: "10+", minions: 0, spells: 0 },
    ]

    const allCards = [...props.cards]
    for (let i = 0; i < allCards.length; i++) {
      if (allCards[i].cost >= 10 && allCards[i].type === "spell") {
        data[10].spells++
      } else if (allCards[i].cost >= 10) {
        data[10].minions++
      } else if (allCards[i].type === "spell") {
        data[allCards[i].cost].spells++
      } else if(allCards[i].type === "creature" || allCards[i].type === "spellcaster") {
        data[allCards[i].cost].minions++
      }
    }
    return data
  }

  const data = chartData()
  const maxCardCount = data.map((item) => item.minions + item.spells)
  const max = Math.max(...maxCardCount)

  const renderTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p>{`Mana Cost: ${label}`}</p>
          <p
            style={{ color: "rgb(112 60 10)" }}
          >{`Minions: ${payload[0].payload.minions}`}</p>
          <p
            style={{ color: "rgb(150, 97, 47)" }}
          >{`Spells: ${payload[0].payload.spells}`}</p>
        </div>
      )
    }
    return null
  }

  return (
    <div id="manaCurveChart">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart title="Main Deck" width={450} height={250} data={data}>
          <CartesianGrid stroke="#555555" strokeDasharray="3 3" />
          <XAxis dataKey="manaCost" tick={{ fill: "black", interval: 1 }} />
          <YAxis
            type="number"
            domain={[0, max]}
            tickCount={max + 1}
            tick={{ fill: "black" }}
          />
          <Tooltip content={renderTooltip} />
          <Legend />
          <Bar
            dataKey={"minions"}
            name="Minions"
            fill={"rgb(112 60 10)"}
            stackId="a"
          />
          <Bar
            dataKey={"spells"}
            name="Spells"
            fill={"rgb(150, 97, 47)"}
            stackId="a"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default Chart
