// Constants.js
const prod = {
  url: {
    API_URL: "https://combackendserver.onrender.com",
  },
}
const dev = {
  url: {
    API_URL: "http://localhost:8000",
  },
}
export const rarityIndex = {
  omnipotent: 1,
  mythic: 1,
  legendary: 2,
  common: 3,
}
export const config = process.env.NODE_ENV === "development" ? dev : prod
