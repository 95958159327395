import { useContext, useState, useEffect, useRef } from "react"
import spellFunctions from "../../spellFunctions"
import { GameContext } from "../../App"
import { useNavigate } from "react-router-dom"
import GameLog, { logAction } from "../../components/GameLog/GameLog"
import Board from "../../components/board"
import InfoText from "../../components/infoText"

const Replay = () => {
  const { socket } = useContext(GameContext)
  const [replayActive, setReplayActive] = useState(false)
  const [stateUpdate, setStateUpdate] = useState(false)
  const [worship, setWorship] = useState({})
  const [worshipArray, setWorshipArray] = useState([])
  const [resolveMulligan, setResolveMulligan] = useState(true)
  const [isTurn, setIsTurn] = useState(false)
  const [discardSelection, setDiscardSelection] = useState(false)
  const [discardChoices, setDiscardChoices] = useState([])
  const [discardNumber, setDiscardNumber] = useState(0)
  const [handSelection, setHandSelection] = useState(false)
  const [handChoices, setHandChoices] = useState([])
  const [handNumber, setHandNumber] = useState(0)
  const playerChoiceIndex = useRef(null)
  const [gameDeck, setGameDeck] = useState([])
  const [theirGameDeck, setTheirGameDeck] = useState([])
  const [hand, setHand] = useState([])
  const [opponentsHand, setOpponentsHand] = useState([])
  const [myBoard, setMyBoard] = useState([])
  const [theirBoard, setTheirBoard] = useState([])
  const [mana, setMana] = useState(0)
  const [currentMana, setCurrentMana] = useState(0)
  const [opponentsMana, setOpponentsMana] = useState(0)
  const [opponentsCurrentMana, setOpponentsCurrentMana] = useState(0)
  const [attacker, setAttacker] = useState(null)
  const [attacked, setAttacked] = useState([])
  const [theirAttacked, setTheirAttacked] = useState([])
  const [myGraveyard, setMyGraveyard] = useState([])
  const [theirGraveyard, setTheirGraveyard] = useState([])
  const [castingSpell, setCastingSpell] = useState(false)
  const [spellCast, setSpellCast] = useState(null)
  const [opponentUser, setOpponentUser] = useState({})
  const [expandMyGY, setExpandMyGY] = useState(false)
  const [expandOpponentGY, setExpandOpponentGY] = useState(false)
  const [player, setPlayer] = useState({
    userName: "Guest",
    defense: 30,
    damageTaken: 0,
    type: "self",
    block: 0,
    durability: 0,
    poison: 0,
    revitalize: 0,
    spellsCasted: 0,
    healAbsorb: 0,
    sacrifices: 0,
  })
  const [opponent, setOpponent] = useState({
    userName: "Guest",
    defense: 30,
    damageTaken: 0,
    type: "opponent",
    block: 0,
    durability: 0,
    poison: 0,
    revitalize: 0,
    spellsCasted: 0,
    healAbsorb: 0,
    sacrifices: 0,
  })
  const [dontResolveUpkeep, setDontResolveUpkeep] = useState(true)
  const [spellID, setSpellID] = useState(null)
  const [usedPlayerAbility, setUsedPlayerAbility] = useState(false)
  const [extraTurn, setExtraTurn] = useState(false)
  const gameIsFinished = useRef(false)
  const [infoText, setInfoText] = useState("")
  const [usedThisAbility, setUsedThisAbility] = useState("")
  const [drawFromGraveyard, setDrawFromGraveyard] = useState(false)
  const [opponentLeft, setOpponentLeft] = useState(false)
  const [opponentDrawFromGraveyard, setOpponentDrawFromGraveyard] =
    useState(false)
  const [statsLogged, setStatsLogged] = useState(false)
  const [playerSelection, setPlayerSelection] = useState(false)
  const [playerSelectionOptions, setPlayerSelectionOptions] = useState([])
  const [opponentChoice, setOpponentChoice] = useState(null)
  const [recursionTrue, setRecursionTrue] = useState(false)
  const [mulliganedCards, setMulliganedCards] = useState([])
  const gameUsers = useRef([])
  const spectate = useRef(false)
  const [sideBoarding, setSideBoarding] = useState(true)
  const [opponentSideBoarding, setOpponentSideBoarding] = useState(true)
  const [onDeathGlobals, setOnDeathGlobals] = useState([])
  const [opponentDeathGlobals, setOpponentDeathGlobals] = useState([])
  const [onCastGlobals, setOnCastGlobals] = useState([])
  const [opponentCastGlobals, setOpponentCastGlobals] = useState([])
  const [onDrawGlobals, setOndrawGlobals] = useState([])
  const [opponentDrawGlobals, setOpponentDrawGlobals] = useState([])
  const [myCountersArray, setMyCountersArray] = useState([])
  const [opponentsCountersArray, setOpponentsCountersArray] = useState([])
  const [onHealGlobals, setOnHealGlobals] = useState([])
  const [opponentHealGlobals, setOpponentHealGlobals] = useState([])
  const [onPlayGlobals, setOnPlayGlobals] = useState([])
  const [opponentOnPlayGlobals, setOpponentOnPlayGlobals] = useState([])
  const [spellReduction, setSpellReduction] = useState({
    reduceCost: 0,
    reducedCost: false,
    lifeCost: { payLife: false, amount: 0 },
    dontSplice: false,
    reset: false,
  })
  const [creatureReduction, setCreatureReduction] = useState({
    reduceCost: 0,
    reducedCost: false,
    lifeCost: { payLife: false, amount: 0 },
    dontSplice: false,
    reset: false,
  })
  const [myKingdomOfHeaven, setMyKingdomOfHeaven] = useState(false)
  const [opponentKingdomOfHeaven, setOpponentKingdomOfHeaven] = useState(false)
  const [myKingdomOfHell, setMyKingdomOfHell] = useState(false)
  const [opponentKingdomOfHell, setOpponentKingdomOfHell] = useState(false)
  const [logs, setLogs] = useState([])
  const [prophecyArray, setProphecyArray] = useState([])
  const [opponentProphecyArray, setOpponentProphecyArray] = useState([])
  // const log = (message) => logAction(message, setLogs)
  const [callStack, setCallStack] = useState([])
  const [nextCastDamage, setNextCastDamage] = useState({
    spellDamage: 0,
    reset: false,
  })
  const [doubleCast, setDoubleCast] = useState({
    doubleCast: false,
    reset: false,
  })
  const [replayData, setReplayData] = useState({})
  const turnCount = useRef(1)
  const clickCount = useRef(1)
  const activePlayer = useRef("playerOne")
  const [autoPlay, setAutoPlay] = useState(false)
  const autoPlayCancel = useRef(false)
  const [handReveal, setHandReveal] = useState(true)
  const [opponentsTurn, setOpponentsTurn] = useState(false)
  const flipBoard = useRef(false)
  const [user, setUser] = useState({})
  const pauseGame = useRef(false)

  useEffect(() => {
    if (socket.current) {
      socket.current.emit("get-replay")

      socket.current.on("send-replay", (data) => {
        const initialState = JSON.parse(data.initialState)
        const playerOne = JSON.parse(data.playerOne)
        const playerTwo = JSON.parse(data.playerTwo)
        
        setReplayData({
          initialState: initialState,
          playerOne: playerOne,
          playerTwo: playerTwo,
        })
        setUser(playerOne.user)
        setOpponentUser(playerTwo.user)
      })
    }

    return () => {
      socket.current.off("send-replay")
    }
  }, [])

  useEffect(() => {
    if (autoPlay) {
      setTimeout(() => nextAction(true), 333)
    }
  }, [autoPlay])

  // Ends the game when it is lost/won
  useEffect(() => {
    if (!spectate.current) {
      if (isTurn || opponentLeft) {
        if (opponent.defense - opponent.damageTaken <= 0) {
          socket.current.activeGame = false
          setInfoText("Victory!")
          gameIsFinished.current = true
          if (!statsLogged) {
            setStatsLogged(true)
            // socket.current.emit("game-over", {
            //   room: room.current,
            //   socketID: socket.current.id,
            //   winLose: "Defeat!",
            //   deck: { deck: deck, won: true },
            //   opponentsDeck: { deck: originalOpponentDeck, won: false },
            //   winner: { userName: user.userName, email: user.email },
            //   loser: {
            //     userName: opponentUser.userName,
            //     email: opponentUser.email,
            //   },
            // })
          }
        }
        if (player.defense - player.damageTaken <= 0) {
          socket.current.activeGame = false
          setInfoText("Defeat!")
          gameIsFinished.current = true
          if (!statsLogged) {
            setStatsLogged(true)
            // socket.current.emit("game-over", {
            //   room: room.current,
            //   socketID: socket.current.id,
            //   winLose: "Victory!",
            //   deck: { deck: deck, won: false },
            //   opponentsDeck: { deck: originalOpponentDeck, won: true },
            //   winner: {
            //     userName: opponentUser.userName,
            //     email: opponentUser.email,
            //   },
            //   loser: { userName: user.userName, email: user.email },
            // })
          }
        }
      }
    }
  }, [player, opponent, opponentLeft, statsLogged, opponentUser, user])

  // useEffect(() => {
  //   for (let i = 0; i < logs.length; i++) {
  //     console.log(logs[i])
  //   }
  // }, [logs])

  useEffect(() => {
    if (!castingSpell && callStack.length) {
      const data = setInitialState({})
      updateBoardState({ ...data, spellCast: data.callStack[0] })
    }
  }, [castingSpell, callStack])

  // Watches for data from the server to start your turn
  useEffect(() => {
    if (!spectate.current) {
      if (isTurn) {
        if (!dontResolveUpkeep) {
          resolveTurnState()
        } else {
          setDontResolveUpkeep(false)
          const data = setInitialState({
            mana: mana + 1,
            myCurrentMana: mana + 1,
          })
          updateBoardState(data)
        }
      }
    } else {
      setIsTurn(false)
    }
  }, [isTurn])

  useEffect(() => {
    sendSpectatorBoard({ ...stateUpdate })
  }, [stateUpdate])

  const findCard = (data, id, cardName) => {
    const findCardInArray = (array, id, cardName) => {
      return array.find((card) => card.id === id || card.name === cardName)
    }

    const dataSets = [
      data.attackerCurrentBoard,
      data.defenderCurrentBoard,
      data.myHand,
      data.theirHand,
      data.myCurrentGY,
      data.theirCurrentGY,
      data.deck,
      data.theirDeck,
    ]

    for (const dataSet of dataSets) {
      const card = findCardInArray(dataSet, id, cardName)
      if (card) return card
    }

    return null // Card not found
  }

  const isolationCheck = (cards) => {
    const cardNamesSet = new Set()

    for (const card of cards) {
      if (cardNamesSet.has(card.name)) {
        // Duplicate card name found
        return false
      }
      cardNamesSet.add(card.name)
    }

    // All card names are unique
    return true
  }

  const handleDamageToArmor = (player, damage) => {
    player.durability &&
      (player.durability < damage
        ? (player.durability = 0)
        : (player.durability -= damage))
    player.durability === 0 && (player.block = 0)
    return player
  }

  function sendSpectatorBoard(roomData) {
    if (socket.current?.socketID === gameUsers.current[0]) {
      let board = {
        defenderCurrentBoard: [...theirBoard],
        attackerCurrentBoard: [...myBoard],
        theirCurrentGY: [...theirGraveyard],
        myCurrentGY: [...myGraveyard],
        myPlayer: { ...player },
        theirPlayer: { ...opponent },
        deck: [...gameDeck],
        myHand: [...hand],
        theirDeck: [...theirGameDeck],
        theirHand: [...opponentsHand],
        attacked: [...attacked],
        mana: mana,
        myCurrentMana: currentMana,
        opponentsMana: opponentsMana,
        opponentsCurrentMana: opponentsCurrentMana,
        gameUsers: gameUsers.current,
      }
      socket.current?.emit("board-current-send", { ...roomData, ...board })
    }
  }

  function boardAreaOfEffect(data, damage, player = "opponent", kill = false) {
    //ADD ADDITIONAL FUNCTIONS, ADD TYPE: STRING TO SELECT A AOE TYPE, RANDOM X TARGETS OR ALL TARGETS
    //ADD SINGLE TARGET FUNCTION THAT DOES SAME THING
    const boards = {
      self: data.attackerCurrentBoard,
      opponent: data.defenderCurrentBoard,
    }
    const whoseBoard = {
      self: player === "self" || player === "both",
      opponent: player === "opponent" || player === "both",
    }
    for (let i = 0; i <= 1; i++) {
      const boardSide = i === 0 ? "self" : "opponent"
      if (whoseBoard[boardSide]) {
        for (let i = 0; i < boards[boardSide].length; i++) {
          boards[boardSide][i].damageTaken = kill
            ? boards[boardSide][i].defense
            : (boards[boardSide][i].damageTaken += resolveDamage(
                data,
                damage,
                "dontKill",
                false,
                false,
                boards[boardSide][i]
              ))
        }

        for (let i = 0; i < boards[boardSide].length; ) {
          data.attackerKill = false
          data.defenderKill = false
          data = data.killCreature({
            ...data,
            location: boardSide === "self" ? "attacker" : "defender",
            card: boards[boardSide][i],
          })
          if (data.attackerKill || data.defenderKill) {
            data.attackerKill = false
            data.defenderKill = false
          } else {
            i++
          }
        }
      }
    }

    return data
  }

  const cancelSummon = () => {
    setIsTurn(true)
  }

  const isHuman = (card) => {
    const allowedTypes = [
      "human",
      "demigod",
      "cultist",
      "shapeshifter",
      "nephilim",
      "vampire",
      "werewolf",
    ]

    if (myKingdomOfHell) {
      allowedTypes.push("demon")
    }

    return allowedTypes.some((type) => card.class.includes(type))
  }

  const isUndead = (card) => {
    const allowedTypes = ["undead", "vampire"]

    return allowedTypes.some((type) => card.class.includes(type))
  }

  const isDemon = (card) => {
    const allowedTypes = ["demon"]

    if (myKingdomOfHell) {
      allowedTypes.push("human")
      allowedTypes.push("demigod")
      allowedTypes.push("cultist")
      allowedTypes.push("shapeshifter")
      allowedTypes.push("nephilim")
    }

    return allowedTypes.some((type) => card.class.includes(type))
  }

  const handleProphecy = (data) => {
    data.prophecyArray = [
      ...data.prophecyArray,
      findCard(data, data.mySpellCast.id),
    ]
    return data
  }

  const isAngel = (card) => {
    const allowedTypes = [
      "angel",
      "archangel",
      "seraphim",
      "nephilim",
      "valkyrie",
    ]
    return allowedTypes.some((type) => card.class.includes(type))
  }

  const isAGod = (card) => {
    const allowedTypes = ["god", "titan", "demigod"]
    return allowedTypes.some((type) => card.class.includes(type))
  }

  const isAMonster = (card) => {
    const allowedTypes = ["monster", "shapeshifter", "giant", "werewolf"]
    return allowedTypes.some((type) => card.class.includes(type))
  }

  const removePairEffects = (card, board) => {
    const targetCard = board.find((creature) => creature.id === card.pairID)

    if (targetCard && targetCard.pairEffect) {
      targetCard.effects = targetCard.effects.filter(
        (effect) => effect !== targetCard.pairEffect.effects
      )
      targetCard.text = targetCard.text.replace(targetCard.pairEffect.text, "")
      delete targetCard.pairEffect
    }

    delete card.pairID
  }

  const processDeathGlobals = (data, globals) => {
    for (let i = 0; i < globals.length; i++) {
      data.OnDeathFunction = true
      const abilityIndex = globals[i].abilities[0]
      data.myFunction = spellFunctions[abilityIndex]
      data = resolveSpell(data, false)
      data.OnDeathFunction = false
    }
    return data
  }

  const addWorshipCreature = (card) => {
    const data = setInitialState({})
    if (data.worshipArray.find((arrayCard) => arrayCard.id === card.id)) {
      setWorshipArray(
        data.worshipArray.filter((arrayCard) => arrayCard.id !== card.id)
      )
    } else {
      if (data.worshipArray.length < worship.cost) {
        if (
          !data.attacked.find((attackedCard) => attackedCard.id === card.id)
        ) {
          setWorshipArray([...data.worshipArray, { ...card }])
        }
      } else {
        if (
          !data.attacked.find((attackedCard) => attackedCard.id === card.id)
        ) {
          const newWorshipArray = [...data.worshipArray]
          newWorshipArray.shift()
          newWorshipArray.unshift(card)
          setWorshipArray(newWorshipArray)
        }
      }
    }
  }

  function killCreature(data) {
    const isAttacker = data.location === "attacker"
    const card = isAttacker
      ? data.attackerCurrentBoard.find((card) => card.id === data.card.id)
      : data.defenderCurrentBoard.find((card) => card.id === data.card.id)
    const kill = data.kill || card?.damageTaken >= card?.defense
    const board = isAttacker
      ? data.attackerCurrentBoard
      : data.defenderCurrentBoard
    const graveyard = isAttacker ? data.myCurrentGY : data.theirCurrentGY
    const logs = data.logs

    if (kill && card) {
      data.startTurn = false
      if (data.sacrifice) {
        isAttacker
          ? (data.myPlayer.sacrifices += 1)
          : (data.theirPlayer.sacrifices += 1)
      }

      if (isAttacker) {
        data.defenderKill = true
        data.attackerSurvive = false
      } else {
        data.attackerKill = true
        data.defenderSurvive = false
      }

      const frozen = card.effects.includes("frozen")
      if (frozen) {
        logs.push(`${data.card.name} freeze is shattered`)
        data = resolveDamage(data, 3, isAttacker ? "self" : "opponent")
        card.effects.splice(card.effects.indexOf("frozen"), 1)
      }

      card.damageTaken = 0

      if (card.pairID) {
        removePairEffects(card, board)
      }

      const creatureIndex = board.findIndex(
        (creature) => creature.id === card.id
      )

      if (!card.token && !card.oneUse) {
        const [removedCreature] = board.splice(creatureIndex, 1)
        graveyard.unshift(removedCreature)
        logs.push(`${removedCreature.name} has died`)
      } else {
        board.splice(creatureIndex, 1)
        logs.push(`${card.name} has been removed from the game`)
      }

      if (card.effects.includes("globalEffect")) {
        data = removeGlobals(data, "targetedGlobal", data.card)
      }

      data.location = "attacker"
      data = processDeathGlobals(data, data.onDeathGlobals)
      data.location = "defender"
      data = processDeathGlobals(data, data.opponentDeathGlobals)

      if (card.effects.includes("lastRites")) {
        const location = isAttacker ? "attacker" : "defender"
        data = resolveSpell(
          { ...data, myFunction: spellFunctions[card.abilities[0]], location },
          false
        )
      }

      if (card.sacrificialPawn && data.sacrifice) {
        const location = isAttacker ? "attacker" : "defender"
        data = resolveSpell(
          {
            ...data,
            myFunction: spellFunctions[card.sacrificialPawn],
            location,
          },
          false
        )
        data.sacrifice = false
      }
    }
    data.killCreatureFunction = false
    return data
  }

  // At the start of your turn sets all the variables needed for you to play
  function resolveTurnState(data) {
    if (playerSelection || worship.id) {
      handlePlayerSelection()
    } else {
      handleNonPlayerSelection(data)
    }
  }

  function handlePlayerSelection() {
    if (!discardSelection && !handSelection && !worship.id) {
      if (spellCast?.spellcaster) {
        playerChoiceIndex.current === 0
          ? chooseAttacker(spellCast, spellCast.index)
          : playerChoiceIndex.current === 1
          ? activateSpellCast()
          : cancelSpell()
      } else if (spellCast.activatesOn.includes("selfChoice")) {
        resolveSpell({
          myFunction:
            spellFunctions[spellCast.abilities[playerChoiceIndex.current]],
        })
      } else {
        resolveSpell({
          recursion: recursionTrue,
          myFunction: spellFunctions[spellCast.abilities[opponentChoice]],
        })
      }
      playerChoiceIndex.current = null
    }
    if (worship.id) {
      setWorship({})
      setWorshipArray([])
    }
    resetHandSelectionValues()
    resetDiscardChoices()
    setPlayerSelection(false)
  }

  function resetDiscardChoices() {
    setDiscardChoices([])
    setDiscardNumber(0)
    setDiscardSelection(false)
  }

  function resetHandSelectionValues() {
    setHandChoices([])
    setHandNumber(0)
    setHandSelection(false)
  }

  function handleNonPlayerSelection(data) {
    data = data ? data : setInitialState({})
    data.mana = mana + 1
    data.myCurrentMana = mana + 1
    const originalBoard = data.attackerCurrentBoard.map((card) => card.id)

    data = drawCards(data, 1)

    if (data.myPlayer.poison > 0) {
      data.myPlayer.damageTaken += data.myPlayer.poison
      data.myPlayer.poison -= 1
    }

    if (data.myPlayer.revitalize > 0) {
      data = calculateHealing(data, data.myPlayer.revitalize)
      data.myPlayer.revitalize -= 1
    }

    data = resolveProphecySpells(data)
    data = resolveInGraveyardSpells(data)
    data = resolveStartTurnSpells(data)
    data = updateElusiveCreatures(data)

    const tappedCreatures = getTappedCreatures(data, originalBoard)
    updateBoardState({ ...data, tappedCreatures }, false, true)
  }

  function resolveProphecySpells(data) {
    for (let i = data.prophecyArray.length - 1; i >= 0; i--) {
      data.prophecyArray[i].prophecyCounter -= 1
      const card = findCard(data, data.prophecyArray[i].id)
      if (!card) {
        data.prophecyArray.splice(i, 1)
        continue
      }
      // card.prophecyCounter -= 1

      if (
        data?.prophecyArray[i]?.prophecyCounter === 0 ||
        (data?.prophecyArray[i]?.prophecyActivation?.includes(
          data?.prophecyArray[i]?.prophecyCounter
        ) ??
          false)
      ) {
        data = resolveSpell(
          {
            ...data,
            myFunction: spellFunctions[data.prophecyArray[i].abilities[0]],
            prophecy: true,
            prophecyCard: data.prophecyArray[i],
          },
          false
        )

        data.prophecy = false
        if (data.prophecyArray[i].prophecyCounter === 0) {
          data.prophecyArray[i].prophecyCounter = data.prophecyArray[i].prophecy
          card.prophecyCounter = card.prophecy
          data.prophecyArray.splice(i, 1)
        }
      }
    }

    return data
  }

  function resolveStartTurnSpells(data) {
    for (let i = 0; i < data.attackerCurrentBoard.length; i++) {
      if (data.attackerCurrentBoard[i].activatesOn.includes("startTurn")) {
        data = resolveSpell(
          {
            ...data,
            target: data.attackerCurrentBoard[i],
            index: i,
            myFunction:
              spellFunctions[data.attackerCurrentBoard[i].abilities[0]],
            startTurn: true,
            card: data.attackerCurrentBoard[i],
          },
          false
        )

        if (data?.buffCard) {
          data.card.attack += data.buffCard.attack
          data.card.defense += data.buffCard.defense
          delete data.buffCard
        }
      }
    }
    return data
  }

  function resolveInGraveyardSpells(data) {
    for (let i = 0; i < data.myCurrentGY.length; i++) {
      if (data.myCurrentGY[i].activatesOn.includes("inGraveyard")) {
        data = resolveSpell(
          {
            ...data,
            target: data.myCurrentGY[i],
            index: i,
            myFunction: spellFunctions[data.myCurrentGY[i].abilities[0]],
            i: i,
          },
          false
        )
        i = data.i
      }
    }
    return data
  }

  function updateElusiveCreatures(data) {
    for (let i = 0; i < data.attackerCurrentBoard.length; i++) {
      if ("elusive" in data.attackerCurrentBoard[i]) {
        data.attackerCurrentBoard[i].elusive = true
      }
    }
    return data
  }

  function getTappedCreatures(data, originalBoard) {
    const remainTappedArray = data.attackerCurrentBoard.filter((card) =>
      card.effects.includes("frozen")
    )
    const newCreatures = data.attackerCurrentBoard.filter(
      (card) =>
        !originalBoard.includes(card.id) && !card.effects.includes("haste")
    )

    return [...remainTappedArray, ...newCreatures]
  }

  const activateSpellCast = () => {
    let data = setInitialState({ creature: spellCast })

    data.attacked = [...data.attacked, spellCast]
    if (!data.creature.activatesOn.includes("noTarget")) {
      data.castingSpell = true
      data.spellCast = data.creature
    } else {
      data = resolveSpell({
        ...data,
        target: { ...data.creature },
        myFunction: spellFunctions[data.creature.abilities[0]],
      })
    }
  }

  const cancelSpell = () => {
    if (callStack.length) {
      setSpellCast(callStack[0])
      setCastingSpell(true)
      setCallStack((callStack) => {
        callStack.shift()
        return callStack
      })
    } else {
      setSpellCast(null)
      setCastingSpell(false)
    }
  }

  // Passes the turn to the other player
  function passTurn(data) {
    data = data ? data : setInitialState({})
    delete data.turnPass
    data.attackerCurrentBoard = data.attackerCurrentBoard.map((card) => {
      if (card.effects.includes("frozen")) {
        data = resolveDamage(data, 1, "self", false)
        card.effects.splice(card.effects.indexOf("frozen"), 1)
      }
      return card
    })
    for (let i = 0; i < data.attackerCurrentBoard.length; ) {
      if (data.attackerCurrentBoard[i]?.perishable) {
        data = data.killCreature({
          ...data,
          sacrifice: true,
          location: "attacker",
          card: data.attackerCurrentBoard[i],
          kill: true,
        })
      } else {
        i++
      }
    }
    const endTurnDuplicate = data.attackerCurrentBoard.filter((card) =>
      card.effects.includes("startTurnDuplicate")
    )
    for (let i = 0; i < endTurnDuplicate.length; i++) {
      data = resolveStartTurnSpells(data)
    }
    if (extraTurn) {
      let tempMana = mana
      if (!dontResolveUpkeep) {
        resolveTurnState(data)
      } else {
        setDontResolveUpkeep(false)
      }
      tempMana++
      setCurrentMana(tempMana)
      setMana(tempMana)
      setExtraTurn(false)
    } else {
      updateBoardState(data, null, null, true)
    }
  }

  function playCreatureFromGraveyard(target, index) {
    let data = setInitialState({ target: target, index: index })
    data.playCreatureFunction = true
    const resurrectedCreature = data.myCurrentGY.splice(
      data.myCurrentGY.findIndex((cardInGY) => target.id === cardInGY.id),
      1
    )[0]
    if (
      resurrectedCreature.abilities.length > 0 &&
      (resurrectedCreature.activatesOn.length === 0 ||
        resurrectedCreature.activatesOn.includes("friendly") ||
        resurrectedCreature.activatesOn.includes("myGraveyard"))
    ) {
      setCastingSpell(true)
      setSpellCast(resurrectedCreature)
    } else if (
      resurrectedCreature.abilities.length > 0 &&
      !resurrectedCreature.activatesOn.includes("onKill") &&
      !resurrectedCreature.activatesOn.includes("onBlock") &&
      !resurrectedCreature.activatesOn.includes("spellCast") &&
      !resurrectedCreature.activatesOn.includes("onAttack") &&
      !("veilOfShadows" in resurrectedCreature)
    ) {
      data = resolveSpell(
        {
          ...data,
          myFunction: spellFunctions[resurrectedCreature.abilities[0]],
        },
        false
      )
      setCastingSpell(false)
      setSpellCast(null)
    }
    if (!resurrectedCreature.effects.includes("haste")) {
      data.attacked.push(resurrectedCreature)
    } else {
      if (
        data.attacked.findIndex(
          (card) => card.id === resurrectedCreature.id
        ) !== -1
      ) {
        data.attacked.splice(
          data.attacked.findIndex((card) => card.id === resurrectedCreature.id),
          1
        )
      }
    }
    data.myCurrentMana -= Math.floor(resurrectedCreature.recursionCost)
    resurrectedCreature.recursionCost += 2
    resurrectedCreature.cost += 2

    data = processPlayGlobals(data, data.onPlayGlobals, "onPlayGlobalsFunction")
    data = processPlayGlobals(
      data,
      data.opponentOnPlayGlobals,
      "opponentOnPlayGlobalsFunction"
    )

    resurrectedCreature.attack = data?.buffCard
      ? resurrectedCreature.attack + data.buffCard.attack
      : resurrectedCreature.attack
    resurrectedCreature.defense = data?.buffCard
      ? resurrectedCreature.defense + data.buffCard.defense
      : resurrectedCreature.defense
    if (data?.buffCard) delete data.buffCard
    data.playCreatureFunction = false
    data.attackerCurrentBoard.push(resurrectedCreature)
    updateBoardState({ ...data, playedCreature: resurrectedCreature })
  }

  function calculateHealing(data, healAmount, player = "self") {
    if (player === "self") {
      if (
        data.onHealGlobals.find(
          (card) => card.name === "Sekhmet, Divine Healer"
        )
      ) {
        const additionalHealing = data.onHealGlobals.filter(
          (card) => card.name === "Sekhmet, Divine Healer"
        ).length
        healAmount = healAmount + additionalHealing * 3
      }

      if (
        data.onHealGlobals.find((card) => card.name === "Isis, Bringer Of Life")
      ) {
        const multiplier = data.onHealGlobals.filter(
          (card) => card.name === "Isis, Bringer Of Life"
        ).length
        for (let i = 0; i < multiplier; i++) {
          healAmount = healAmount * 2
        }
      }
      if (data.myPlayer.healAbsorb > healAmount) {
        data.logs.push(`${healAmount} for ${data.myPlayer.name} is absorbed`)
        data.myPlayer.healAbsorb -= healAmount
      } else {
        healAmount -= data.myPlayer.healAbsorb
        data.logs.push(`${data.myPlayer.name} is healed for ${healAmount}`)
        data.myPlayer.healAbsorb = 0
        data.myPlayer.damageTaken > healAmount
          ? (data.myPlayer.damageTaken -= healAmount)
          : (data.myPlayer.damageTaken = 0)
      }
    } else {
      if (
        data.opponentHealGlobals.find(
          (card) => card.name === "Sekhmet, Divine Healer"
        )
      ) {
        const additionalHealing = data.opponentHealGlobals.filter(
          (card) => card.name === "Sekhmet, Divine Healer"
        ).length
        healAmount = healAmount + additionalHealing * 2
      }

      if (
        data.opponentHealGlobals.find(
          (card) => card.name === "Isis, Bringer Of Life"
        )
      ) {
        const multiplier = data.opponentHealGlobals.filter(
          (card) => card.name === "Isis, Bringer Of Life"
        ).length
        for (let i = 0; i < multiplier; i++) {
          healAmount = healAmount * 2
        }
      }
      if (data.theirPlayer.healAbsorb > healAmount) {
        data.logs.push(`${healAmount} for ${data.theirPlayer.name} is absorbed`)
        data.theirPlayer.healAbsorb -= healAmount
      } else {
        healAmount -= data.theirPlayer.healAbsorb
        data.logs.push(`${data.theirPlayer.name} is healed for ${healAmount}`)
        data.theirPlayer.healAbsorb = 0
        data.theirPlayer.damageTaken > healAmount
          ? (data.theirPlayer.damageTaken -= healAmount)
          : (data.theirPlayer.damageTaken = 0)
      }
    }

    for (let i = 0; i < data.onHealGlobals.length; i++) {
      data = spellFunctions[data.onHealGlobals[i].abilities[0]]({
        ...data,
        onHeal: true,
        healAmount: healAmount,
        heal: "myHeal",
        player: player,
      })
      data.onHeal = false
    }
    for (let i = 0; i < data.opponentHealGlobals.length; i++) {
      data = spellFunctions[data.opponentHealGlobals[i].abilities[0]]({
        ...data,
        onHeal: true,
        healAmount: healAmount,
        heal: "opponentHeal",
        player: player,
      })
      data.onHeal = false
    }

    return data
  }

  function playCreatureOnOpponentBoard(data) {
    if (data) {
      const playedCreature = { ...data.creature }
      if (
        data.creature.abilities.length > 0 &&
        data.castSpell &&
        (data.creature.activatesOn.length === 0 ||
          data.creature.activatesOn.includes("friendly") ||
          data.creature.activatesOn.includes("myGraveyard")) &&
        !data.creature.abilities.includes("doNotCast")
      ) {
        data.castingSpell = true
        data.spellCast = data.creature
      } else if (
        data.creature.abilities.length > 0 &&
        data.castSpell &&
        !data.creature.activatesOn.includes("onKill") &&
        !data.creature.activatesOn.includes("onBlock") &&
        !data.creature.activatesOn.includes("spellCast") &&
        !data.creature.abilities.includes("odinAllfather") &&
        !data.creature.activatesOn.includes("doNotCast")
      ) {
        data = resolveSpell(
          {
            ...data,
            target: { ...data.creature },
            myFunction: spellFunctions[data.creature.abilities[0]],
          },
          false
        )
        data.castingSpell = false
        data.spellCast = null
      }
      if (data.creature.effects.includes("haste")) {
        for (let i = 0; i < data.attacked.length; i++) {
          if (data.attacked[i].id === data.creature.id) {
            data.attacked.splice(i, 1)
          }
        }
      } else {
        data.theirAttacked = [...data.theirAttacked, data.creature]
      }
      data.defenderCurrentBoard = [...data.defenderCurrentBoard, playedCreature]
      if (data.creature.effects.includes("globalEffect")) {
        if (data.creature.effects.includes("onDeath")) {
          data.opponentDeathGlobals.push(data.creature)
        }
        if (data.creature.effects.includes("onCast")) {
          data.opponentCastGlobals.push(data.creature)
        }
        if (data.creature.effects.includes("onDraw")) {
          data.opponentDrawGlobals.push(data.creature)
        }
        if (data.creature.effects.includes("onHeal")) {
          data.opponentHealGlobals.push(data.creature)
        }

        data = removeGlobals(data, "creatureSwap", data.creature, "self")
      }

      return data
    }
  }

  function removeGlobals(data, resetType, target, side) {
    if (resetType === "allGlobals") {
      data.onDeathGlobals = []
      data.opponentDeathGlobals = []
      data.onCastGlobals = []
      data.opponentCastGlobals = []
      data.onDrawGlobals = []
      data.opponentDrawGlobals = []
      data.onHealGlobals = []
      data.opponentHealGlobals = []
    } else if (resetType === "creatureSwap") {
      if (side === "self") {
        data.onDeathGlobals = data.onDeathGlobals.filter(
          (card) => card.id !== target.id
        )
        data.onCastGlobals = data.onCastGlobals.filter(
          (card) => card.id !== target.id
        )
        data.onDrawGlobals = data.onDrawGlobals.filter(
          (card) => card.id !== target.id
        )
        data.onHealGlobals = data.onHealGlobals.filter(
          (card) => card.id !== target.id
        )
        data.onPlayGlobals = data.onPlayGlobals.filter(
          (card) => card.id !== target.id
        )
      } else {
        data.opponentDeathGlobals = data.opponentDeathGlobals.filter(
          (card) => card.id !== target.id
        )
        data.opponentCastGlobals = data.opponentCastGlobals.filter(
          (card) => card.id !== target.id
        )
        data.opponentDrawGlobals = data.opponentDrawGlobals.filter(
          (card) => card.id !== target.id
        )
        data.opponentHealGlobals = data.opponentHealGlobals.filter(
          (card) => card.id !== target.id
        )
        data.opponentOnPlayGlobals = data.opponentOnPlayGlobals.filter(
          (card) => card.id !== target.id
        )
      }
    } else if (resetType === "targetedGlobal") {
      data.opponentDeathGlobals = data.opponentDeathGlobals.filter(
        (card) => card.id !== target.id
      )
      data.opponentCastGlobals = data.opponentCastGlobals.filter(
        (card) => card.id !== target.id
      )
      data.opponentDrawGlobals = data.opponentDrawGlobals.filter(
        (card) => card.id !== target.id
      )
      data.opponentHealGlobals = data.opponentHealGlobals.filter(
        (card) => card.id !== target.id
      )
      data.opponentOnPlayGlobals = data.opponentOnPlayGlobals.filter(
        (card) => card.id !== target.id
      )
      data.onDeathGlobals = data.onDeathGlobals.filter(
        (card) => card.id !== target.id
      )
      data.onCastGlobals = data.onCastGlobals.filter(
        (card) => card.id !== target.id
      )
      data.onDrawGlobals = data.onDrawGlobals.filter(
        (card) => card.id !== target.id
      )
      data.onHealGlobals = data.onHealGlobals.filter(
        (card) => card.id !== target.id
      )
      data.onPlayGlobals = data.onPlayGlobals.filter(
        (card) => card.id !== target.id
      )
    }

    return data
  }

  function creatureSelfChoice(data, card, index) {
    if (card.activatesOn.includes("selfChoice")) {
      pauseGame.current = true
      setIsTurn(false)
      setPlayerSelection(true)
      setPlayerSelectionOptions(card.choiceText)
      data.spellCast = { ...card, index }
    }
    return data
  }
  function processPlayGlobals(data, globals, location) {
    for (let i = 0; i < globals.length; i++) {
      data[location] = true
      const abilityIndex = globals[i].abilities[0]
      data.myFunction = spellFunctions[abilityIndex]
      data = resolveSpell(data, false)
      data[location] = false
    }
    return data
  }

  // Plays your clicked on creature card
  function playCreature(
    data,
    card,
    index,
    playedFromHand = false,
    worshipSummon = false
  ) {
    if (data) {
      const playedCreature = { ...data.creature }
      data.playCreatureFunction = true
      data.card = data.creature

      if (playedCreature.effects.includes("globalEffect")) {
        const effectMapping = {
          onDeath: "onDeathGlobals",
          onCast: "onCastGlobals",
          onDraw: "onDrawGlobals",
          onHeal: "onHealGlobals",
          onPlay: "onPlayGlobals",
        }

        for (const effect of playedCreature.effects) {
          if (effectMapping[effect]) {
            data[effectMapping[effect]].push(playedCreature)
          }
        }

        data = removeGlobals(data, "creatureSwap", playedCreature, "opponent")
      }

      if (data.myKingdomOfHell) {
        if (isHuman(data.card)) {
          data.card.class = "demon"
        }
        if (isDemon(playedCreature)) {
          data = calculateHealing(data, 1)
          data = resolveDamage(data, 1, "opponent", false)
        }
      }

      if (data.myKingdomOfHeaven) {
        if (isAngel(playedCreature)) {
          data = drawCards(data, 1)
          data = resolveDamage(data, 2, "self")
        }
        if (isHuman(playedCreature)) {
          data = calculateHealing(data, 1)
          data.myPlayer.defense += 1
        }
      }

      if (
        playedCreature.abilities.length > 0 &&
        data.castSpell &&
        (playedCreature.activatesOn.length === 0 ||
          playedCreature.activatesOn.includes("friendly") ||
          playedCreature.activatesOn.includes("myGraveyard")) &&
        !playedCreature.abilities.includes("doNotCast") &&
        (!playedCreature.effects.includes("manachase") ||
          (playedCreature.effects.includes("manachase") &&
            mana <= opponentsMana))
      ) {
        data = handleCallStack(data, playedCreature)
      } else if (
        playedCreature.abilities.length > 0 &&
        data.castSpell &&
        !playedCreature.activatesOn.includes("onKill") &&
        !playedCreature.activatesOn.includes("onBlock") &&
        !playedCreature.activatesOn.includes("spellCast") &&
        !playedCreature.abilities.includes("odinAllfather") &&
        !playedCreature.activatesOn.includes("doNotCast") &&
        !playedCreature.activatesOn.includes("onAttack") &&
        (!playedCreature.effects.includes("manachase") ||
          (playedCreature.effects.includes("manachase") &&
            mana <= opponentsMana))
      ) {
        data = resolveSpell(
          {
            ...data,
            target: { ...playedCreature },
            myFunction: spellFunctions[playedCreature.abilities[0]],
          },
          false
        )
      }
      if (playedCreature.effects.includes("haste")) {
        for (let i = 0; i < data.attacked.length; i++) {
          if (data.attacked[i].id === playedCreature.id) {
            data.attacked.splice(i, 1)
          }
        }
      } else {
        data.attacked = [...data.attacked, playedCreature]
      }

      data = processPlayGlobals(
        data,
        data.onPlayGlobals,
        "onPlayGlobalsFunction"
      )
      data = processPlayGlobals(
        data,
        data.opponentOnPlayGlobals,
        "opponentOnPlayGlobalsFunction"
      )

      playedCreature.attack = data?.buffCard
        ? playedCreature.attack + data.buffCard.attack
        : playedCreature.attack
      playedCreature.defense = data?.buffCard
        ? playedCreature.defense + data.buffCard.defense
        : playedCreature.defense
      if (data?.buffCard) delete data.buffCard

      if (!data?.dontPlayCreature) {
        data.attackerCurrentBoard.push(playedCreature)
      }

      data.playCreatureFunction = false
      return data
    } else {
      data = setInitialState({})
      data.playCreatureFunction = true
      data.myHand.splice(index, 1)
      data.card = card

      if (card.effects.includes("globalEffect")) {
        const effectMapping = {
          onDeath: "onDeathGlobals",
          onCast: "onCastGlobals",
          onDraw: "onDrawGlobals",
          onHeal: "onHealGlobals",
          onPlay: "onPlayGlobals",
        }

        for (const effect of card.effects) {
          if (effectMapping[effect]) {
            data[effectMapping[effect]].push(card)
          }
        }
      }

      if (data.card.effects.includes("hindered") && playedFromHand) {
        data.card.damageTaken += data.card.hindered
      }

      if (data.myKingdomOfHell) {
        if (isHuman(data.card)) {
          data.card.class = "demon"
        }
        if (isDemon(data.card)) {
          data = calculateHealing(data, 1)
          data = resolveDamage(data, 1, "opponent", false)
        }
      }

      if (data.myKingdomOfHeaven) {
        if (isAngel(data.card)) {
          data = drawCards(data, 1)
          data = resolveDamage(data, 2, "self")
        }
        if (isHuman(data.card)) {
          data = calculateHealing(data, 1)
          data.myPlayer.defense += 1
        }
      }

      if (playedFromHand) {
        data.playedFromHand = true
      }
      data = creatureSelfChoice(data, card, index)

      if (!worshipSummon) {
        if (data.creatureReduction.reducedCost) {
          if (data.creatureReduction.lifeCost.payLife) {
            if (data.creatureReduction.lifeCost.amount === 10000) {
              data = data.resolveDamage(data, card.cost, "self")
            } else {
            }
          }
          data.creatureReduction.reset = true
        } else {
          data.myCurrentMana -= card.cost
        }
      } else {
        data.myCurrentMana -= card.cost - data.worshipArray.length
        data.attacked = [...data.attacked, ...data.worshipArray]
        pauseGame.current = false
        setIsTurn(true)
      }

      if (
        card.abilities.length > 0 &&
        card.activatesOn.includes("noTarget") &&
        !card.activatesOn.includes("startTurnOnly") &&
        !card.activatesOn.includes("onBlock") &&
        !card.activatesOn.includes("spellCast") &&
        !card.activatesOn.includes("doNotCast") &&
        (!card.effects.includes("manachase") ||
          (card.effects.includes("manachase") && mana <= opponentsMana))
      ) {
        data = resolveSpell(
          {
            ...data,
            creature: card,
            myFunction: spellFunctions[card.abilities[0]],
          },
          false
        )
      } else if (
        card.abilities.length > 0 &&
        (card.activatesOn.length === 0 ||
          card.activatesOn.includes("friendly") ||
          (card.activatesOn.includes("myGraveyard") &&
            !card.activatesOn.includes("spellCast"))) &&
        !card.abilities.includes("doNotCast") &&
        (!card.effects.includes("manachase") ||
          (card.effects.includes("manachase") && mana <= opponentsMana))
      ) {
        data = handleCallStack(data, card)
      }

      if (!card.effects.includes("haste")) {
        data.attacked.push(card)
      }

      data = processPlayGlobals(
        data,
        data.onPlayGlobals,
        "onPlayGlobalsFunction"
      )
      data = processPlayGlobals(
        data,
        data.opponentOnPlayGlobals,
        "opponentOnPlayGlobalsFunction"
      )

      card.attack = data?.buffCard
        ? card.attack + data.buffCard.attack
        : card.attack
      card.defense = data?.buffCard
        ? card.defense + data.buffCard.defense
        : card.defense
      if (data?.buffCard) delete data.buffCard

      if (!data.dontPlayCreature) {
        data.attackerCurrentBoard.push(card)
      }

      data.playCreatureFunction = false
      updateBoardState({ ...data, playedFromHand: playedFromHand })
    }
  }

  // Sets a creature to be your next attacker
  function chooseAttacker(card, index) {
    if (isTurn && !attacked.map((card) => card.id).includes(card.id)) {
      if (card.attack > 0 && !card.effects.includes("cantAttack")) {
        setAttacker({ ...card, index })
      }
    }
    cancelSpell()
  }

  // Test if card played is a realm

  const isARealm = (card, location) => {
    return (
      location === "hand" &&
      isTurn &&
      card.cost <= currentMana &&
      card.activatesOn.includes("inGraveyard")
    )
  }

  //Test if opponent has to make a choice

  const isOpponentChooses = (card, location) => {
    return (
      location === "hand" &&
      card.activatesOn.includes("opponentChooses") &&
      card.cost <= currentMana &&
      !spellCast
    )
  }

  const selfChoice = (card, location) => {
    return (
      location === "hand" &&
      card.activatesOn.includes("selfChoice") &&
      card.cost <= currentMana &&
      !spellCast &&
      card.type === "spell"
    )
  }

  // Handles what happens when any card is clicked on
  function clickLocation(card, index, location) {
    if (!isTurn) {
      handleNonTurnClick(card, location)
    } else {
      if (isARealm(card, location)) {
        setCastingSpell(true)
        setSpellCast({ ...card, index })
        setSpellID(card.id)
        if (spellID === card.id) {
          realmCard(card, index)
        }
      } else if (isOpponentChooses(card, location)) {
        if (card.type === "spell") {
          setSpellID(card.id)
        }
        setCastingSpell(true)
        setSpellCast({ ...card, index })
      } else if (selfChoice(card, location)) {
        if (card.type === "spell") {
          setSpellID(card.id)
        }
        setCastingSpell(true)
        setSpellCast({ ...card, index })
      } else if (location === "hand") {
        handleHandClick(card, index)
      } else if (location === "theirHand") {
      } else if (location === "theirBoard") {
        receiveDamage(card, index)
      } else if (location === "theirGraveyard") {
        handleTheirGraveyardClick(card, index)
      } else if (location === "myBoard") {
        handleBoardClick(card, index)
      } else if (location === "myGraveyard") {
        handleMyGraveyardClick(card, index)
      }
    }
  }

  //Breakdown of click card function
  function handleNonTurnClick(card, location) {
    if (location === "hand") {
      handleNonTurnHandClick(card)
    } else if (location === "theirGraveyard") {
      setExpandOpponentGY((expandOpponentGY) => !expandOpponentGY)
    } else if (location === "myGraveyard") {
      setExpandMyGY((expandMyGY) => !expandMyGY)
    } else if (location === "myBoard" && worship.id) {
      addWorshipCreature(card)
    }
  }

  function handleNonTurnHandClick(card) {
    if (discardSelection) {
      chooseDiscardCards(card)
    } else if (handSelection) {
      chooseHandSelectCards(card)
    }
  }

  function chooseHandSelectCards(card) {
    if (handChoices.find((cardInArray) => cardInArray.id === card.id)) {
      setHandChoices(
        handChoices.filter((cardInArray) => cardInArray.id !== card.id)
      )
    } else {
      setHandChoices([...handChoices, card])
    }
  }

  function chooseDiscardCards(card) {
    if (discardChoices.find((cardInArray) => cardInArray.id === card.id)) {
      setDiscardChoices(
        discardChoices.filter((cardInArray) => cardInArray.id !== card.id)
      )
    } else {
      setDiscardChoices([...discardChoices, card])
    }
  }

  function handleMyGraveyardClick(card, index) {
    if (!expandMyGY) {
      setExpandMyGY(true)
    } else if (spellCast && !castingSpell && card.name !== "Bargain with Hel") {
      resolveSpell({
        target: card,
        index,
        myFunction: spellFunctions[spellCast.abilities[1]],
      })
    } else if (expandMyGY && card.activatesOn.includes("recursion")) {
      if (
        card.type === "spell" &&
        spellID !== card.id &&
        card.recursionCost <= currentMana
      ) {
        setSpellID(card.id)
        setCastingSpell(true)
        setSpellCast({ ...card, index })
      } else if (
        card.type === "spell" &&
        spellID === card.id &&
        card.activatesOn.includes("noTarget")
      ) {
        resolveSpell({
          target: card,
          recursion: true,
          myFunction: spellFunctions[spellCast.abilities[0]],
        })
      } else if (
        card.type === "spell" &&
        spellID === card.id &&
        card.activatesOn.includes("opponentChooses")
      ) {
        pauseGame.current = true
        setIsTurn(false)
        setPlayerSelection(true)
        // socket.current.emit("player-choice", {
        //   room: room.current,
        //   socketID: socket.current.id,
        //   options: card.choiceText,
        //   recursion: true,
        // })
      }
    } else if (
      castingSpell &&
      expandMyGY &&
      spellCast?.activatesOn.includes("myGraveyard")
    ) {
      castOnGY(card, index)
    } else if (
      (card.type === "creature" || card.type === "spellcaster") &&
      card.recursionCost <= currentMana &&
      card.activatesOn.includes("resurrection")
    ) {
      playCreatureFromGraveyard(card, index)
    } else if (expandMyGY) {
      setExpandMyGY(false)
    }
  }

  function handleBoardClick(card, index) {
    if (spellCast?.activatesOn.includes("friendly") && !card?.spellImmune) {
      resolveSpell({
        target: card,
        index,
        myFunction: spellFunctions[spellCast.abilities[0]],
      })
    } else {
      if (
        card.activatesOn.includes("spellCast") &&
        !attacked.map((card) => card.id).includes(card.id)
      ) {
        pauseGame.current = true
        setIsTurn(false)
        setPlayerSelection(true)
        setSpellCast({ ...card, index, spellcaster: true })
        setCastingSpell(true)
        setPlayerSelectionOptions(["Attack", "Activate ability", "Cancel"])
      } else {
        setCastingSpell(false)
        setSpellCast(null)
        chooseAttacker(card, index)
      }
    }
  }

  function canCast(card) {
    if (spellReduction.reducedCost) {
      if (
        spellReduction.reduceCost === 10000 ||
        spellReduction.lifeCost.amount === 10000
      ) {
        return true
      } else if (
        card.cost <= currentMana + spellReduction.reduceCost ||
        card.cost <= currentMana + spellReduction.lifeCost.amount
      ) {
        return true
      }
    } else {
      if (
        creatureReduction.reduceCost === 10000 ||
        creatureReduction.lifeCost.amount === 10000
      ) {
        return true
      } else if (
        card.cost <= currentMana + creatureReduction.reduceCost ||
        card.cost <= currentMana + creatureReduction.lifeCost.amount
      ) {
        return true
      }
      return card.cost <= currentMana
    }
  }

  const availableWorshipMana = () => {
    const creatureExhausts = myBoard.length - attacked.length
    return creatureExhausts + currentMana
  }

  function handleHandClick(card, index) {
    if (
      card.effects.includes("worship") &&
      availableWorshipMana() >= card.cost &&
      myBoard.length > attacked.length
    ) {
      pauseGame.current = true
      setIsTurn(false)
      setWorship(card)
    } else if (
      card.activatesOn.includes("opponentChooses") &&
      canCast(card) &&
      card.type === "spell" &&
      spellCast &&
      card.id !== spellID
    ) {
      setSpellID(card.id)
      setCastingSpell(true)
      setSpellCast({ ...card, index })
    } else if (
      card.activatesOn.includes("opponentChooses") &&
      card.id === spellID
    ) {
      pauseGame.current = true
      setIsTurn(false)
      setPlayerSelection(true)
      setPlayerSelectionOptions(card.choiceText)
      // socket.current.emit("player-choice", {
      //   room: room.current,
      //   socketID: socket.current.id,
      //   options: card.choiceText,
      // })
    } else if (card.activatesOn.includes("selfChoice") && card.id === spellID) {
      pauseGame.current = true
      setIsTurn(false)
      setPlayerSelection(true)
      if (card.choiceText) {
        setPlayerSelectionOptions(card.choiceText)
      }
    } else if (
      card.abilities.length > 0 &&
      canCast(card) &&
      !spellCast &&
      !card.activatesOn.includes("startTurnOnly") &&
      !card.activatesOn.includes("onKillOnly") &&
      !card.abilities.includes("extraTurn") &&
      !card.activatesOn.includes("spellCast") &&
      !card.activatesOn.includes("onAttack") &&
      !card.activatesOn.includes("onBlock") &&
      !card.activatesOn.includes("doNotCast")
    ) {
      if (card.type === "spell") {
        setSpellID(card.id)
      }
      setCastingSpell(true)
      setSpellCast({ ...card, index })
    } else if (
      card.activatesOn.includes("noTarget") &&
      card.type === "spell" &&
      spellCast &&
      card.id === spellID
    ) {
      resolveSpell({
        myFunction: spellFunctions[spellCast.abilities[0]],
        card: card,
      })
    } else if (
      card.abilities.length > 0 &&
      canCast(card) &&
      spellCast &&
      !card.activatesOn.includes("startTurnOnly") &&
      !card.activatesOn.includes("onKillOnly") &&
      !card.abilities.includes("extraTurn") &&
      !card.activatesOn.includes("spellCast") &&
      !card.activatesOn.includes("onAttack") &&
      !card.activatesOn.includes("onBlock") &&
      !card.activatesOn.includes("doNotCast")
    ) {
      setSpellID(card.id)
      setSpellCast({ ...card, index })
    }
    if (
      (card.type === "creature" || card.type === "spellcaster") &&
      canCast(card) &&
      (!card.effects.includes("worship") || !(myBoard.length > attacked.length))
    ) {
      if (!spellCast) {
        playCreature(null, card, index, true)
      } else {
        setSpellCast(null)
        setCastingSpell(false)
        setSpellID(null)
      }
    }
  }

  function handleTheirGraveyardClick(card, index) {
    if (!expandOpponentGY) {
      setExpandOpponentGY((expandOpponentGY) => !expandOpponentGY)
    } else if (spellCast?.activatesOn.includes("theirGraveyard")) {
      resolveSpell({
        target: card,
        index,
        myFunction: spellFunctions[spellCast.abilities[0]],
        // attacked: spellCast.type === "spellcaster" ? [...attacked,...spellCast] : null
      })
    } else if (expandOpponentGY) {
      setExpandOpponentGY((expandOpponentGY) => !expandOpponentGY)
    }
  }

  function setInitialState(data) {
    return {
      myCountersArray: [...myCountersArray],
      opponentsCountersArray: [...opponentsCountersArray],
      myKingdomOfHeaven: data.myKingdomOfHeaven
        ? data.myKingdomOfHeaven
        : myKingdomOfHeaven,
      opponentKingdomOfHeaven: data.opponentKingdomOfHeaven
        ? data.opponentKingdomOfHeaven
        : opponentKingdomOfHeaven,
      myKingdomOfHell: data.myKingdomOfHell
        ? data.myKingdomOfHell
        : myKingdomOfHell,
      opponentKingdomOfHell: data.opponentKingdomOfHell
        ? data.opponentKingdomOfHell
        : opponentKingdomOfHell,
      playerAbility: data.playerAbility ? data.playerAbility : false,
      defenderCurrentBoard: data.defenderCurrentBoard
        ? [...data.defenderCurrentBoard]
        : [...theirBoard],
      attackerCurrentBoard: data.attackerCurrentBoard
        ? [...data.attackerCurrentBoard]
        : [...myBoard],
      myHand: data.myHand ? [...data.myHand] : [...hand],
      theirCurrentGY: data.theirCurrentGY
        ? [...data.theirCurrentGY]
        : [...theirGraveyard],
      nextCastDamage: { ...nextCastDamage },
      doubleCast: { ...doubleCast },
      myCurrentGY: data.myCurrentGY ? [...data.myCurrentGY] : [...myGraveyard],
      target: data.target ? { ...data.target } : null,
      index: data.index,
      creature: data.creature ? data.creature : null,
      myPlayer: data.myPlayer ? { ...data.myPlayer } : { ...player },
      theirPlayer: data.theirPlayer ? { ...data.theirPlayer } : { ...opponent },
      mySpellCast: data.mySpellCast ? data.mySpellCast : spellCast,
      spellCast: data.spellCast ? data.spellCast : spellCast ? null : spellCast,
      deck: data.deck ? [...data.deck] : [...gameDeck],
      theirDeck: data.theirDeck ? data.theirDeck : [...theirGameDeck],
      theirHand: data.theirHand ? data.theirHand : [...opponentsHand],
      attacked: data.attacked ? data.attacked : [...attacked],
      logs: [],
      theirAttacked: data.theirAttacked
        ? data.theirAttacked
        : [...theirAttacked],
      spellReduction: data.spellReduction
        ? data.spellReduction
        : { ...spellReduction },
      creatureReduction: data.creatureReduction
        ? data.creatureReduction
        : { ...creatureReduction },
      callStack: data.callStack ? data.callStack : [...callStack],
      resolveSpell: resolveSpell,
      resolveDamage: resolveDamage,
      drawCards: drawCards,
      spellSplice: spellSplice,
      calculateHealing: calculateHealing,
      killCreature: killCreature,
      playCreature: playCreature,
      playCreatureOnOpponentBoard: playCreatureOnOpponentBoard,
      randomId: randomId,
      discardCards: discardCards,
      removeGlobals: removeGlobals,
      handleCallStack: handleCallStack,
      isHuman: isHuman,
      isAGod: isAGod,
      isAMonster: isAMonster,
      isAngel: isAngel,
      isDemon: isDemon,
      isUndead: isUndead,
      handleProphecy: handleProphecy,
      findCard: findCard,
      boardAreaOfEffect: boardAreaOfEffect,
      handleDamageToArmor: handleDamageToArmor,
      isolationCheck: isolationCheck,
      myFunction: data.myFunction ? data.myFunction : null,
      castingSpell: data.castingSpell
        ? data.castingSpell
        : castingSpell
        ? false
        : castingSpell,
      side: data.side ? data.side : null,
      mana: "mana" in data ? data.mana : mana,
      opponentsMana:
        "opponentsMana" in data ? data.opponentsMana : opponentsMana,
      myCurrentMana: "myCurrentMana" in data ? data.myCurrentMana : currentMana,
      didAttackOccur: data.didAttackOccur ? true : false,
      attacker: data.attacker ? data.attacker : attacker,
      drawFromGraveyard: drawFromGraveyard,
      opponentDrawFromGraveyard: opponentDrawFromGraveyard,
      recursion: data.recursion ? data.recursion : false,
      usedPlayerAbility: usedPlayerAbility,
      card: data.card ? data.card : null,
      startTurn: data.startTurn ? true : false,
      location: data.location ? data.location : "attacker",
      defenderKill: data.defenderKill ? data.defenderKill : false,
      defenderSurvive: data.defenderSurvive ? data.defenderSurvive : true,
      attackerKill: data.attackerKill ? data.attackerKill : false,
      attackerSurvive: data.attackerSurvive ? data.attackerSurvive : true,
      playedCreature: data.playedCreature ? data.playedCreature : false,
      onDeathGlobals: data.onDeathGlobals
        ? data.onDeathGlobals
        : [...onDeathGlobals],
      opponentDeathGlobals: data.opponentDeathGlobals
        ? data.opponentDeathGlobals
        : [...opponentDeathGlobals],
      onCastGlobals: data.onCastGlobals
        ? data.onCastGlobals
        : [...onCastGlobals],
      opponentCastGlobals: data.opponentCastGlobals
        ? data.opponentCastGlobals
        : [...opponentCastGlobals],
      onDrawGlobals: data.onDrawGlobals
        ? data.onDrawGlobals
        : [...onDrawGlobals],
      opponentDrawGlobals: data.opponentDrawGlobals
        ? data.opponentDrawGlobals
        : [...opponentDrawGlobals],
      onHealGlobals: data.onHealGlobals
        ? data.onHealGlobals
        : [...onHealGlobals],
      opponentHealGlobals: data.opponentHealGlobals
        ? data.opponentHealGlobals
        : [...opponentHealGlobals],
      onPlayGlobals: data.onPlayGlobals
        ? data.onPlayGlobals
        : [...onPlayGlobals],
      opponentOnPlayGlobals: data.opponentOnPlayGlobals
        ? data.opponentOnPlayGlobals
        : [...opponentOnPlayGlobals],
      sacrifice: data.sacrifice ? data.sacrifice : false,
      castAbility: data.castAbility ? data.castAbility : false,
      handNumber: data.handNumber ? data.handNumber : handNumber,
      handChoices: data.handChoices ? data.handChoices : [...handChoices],
      handSelection: data.handSelection ? data.handSelection : handSelection,
      prophecyArray: [...prophecyArray],
      opponentProphecyArray: [...opponentProphecyArray],
      worshipArray: [...worshipArray],
    }
  }

  // Resolves spell casts when you choose to play a spell
  function resolveSpell(data, topLevel = true) {
    if (topLevel) data = setInitialState(data)

    if (spellIsCountered(data)) return counterSpell(data, topLevel)
    return castSpell(data, topLevel)
  }

  function counterSpell(data, topLevel) {
    data = spellFunctions[data.myCountersArray[0].abilities[0]]({
      ...data,
      counterSpellFunction: true,
    })
    data.myCountersArray.splice(0, 1)
    if (topLevel) {
      updateBoardState(data, true)
    } else {
      return data
    }
  }

  function updateBoardState(
    data,
    counterSpell,
    resolveTurnState,
    turnPass = false,
    startGame = false
  ) {
    if (!data.turnPass) {
      setLogs((logs) => [...logs, ...data.logs])
      if (data.extraTurn) {
        setExtraTurn(true)
      }
      if (data.pauseGame) {
        pauseGame.current = true
        setIsTurn(false)
        setPlayerSelection(true)
        if (data.discardNumber) {
          setDiscardNumber(
            data.discardNumber > data.myHand.length
              ? data.myHand.length
              : data.discardNumber
          )
          setDiscardSelection(true)
        }
      }
      if (counterSpell) {
        setSpellCast(null)
        setCastingSpell(false)
        setMyCountersArray((myCountersArray) => data.myCountersArray ? data.myCountersArray : myCountersArray)
      } else {
        if (!data.helsBargain) {
          if (data.callStack.length && !data.spellCast) {
            setSpellCast(data.callStack[0])
            data.callStack.shift()
            setCastingSpell(true)
          } else {
            setSpellCast(data.spellCast)
            if (data.spellCast) {
              setCastingSpell(true)
            } else {
              setCastingSpell(false)
            }
          }
        } else {
          setCastingSpell(false)
        }
        setMyCountersArray((myCountersArray) => data.myCountersArray ? data.myCountersArray : myCountersArray)
      }
      if (resolveTurnState) {
        setUsedPlayerAbility(false)
        setUsedThisAbility("")
        setAttacked(() =>
          data.tappedCreatures.length ? data.tappedCreatures : []
        )
        data.attacked = data.tappedCreatures
      } else {
        data.attacked = data.attacked.filter((card) =>
          data.attackerCurrentBoard.find((minion) => minion.id === card.id)
        )
        const uniqueCards = []
        const uniqueAttackedArr = []
        for (let i = 0; i < data.attacked.length; i++) {
          if (!uniqueCards.includes(data.attacked[i].id))
            uniqueAttackedArr.push(data.attacked[i])
        }
        setAttacked(uniqueAttackedArr)
        setUsedPlayerAbility(data.usedPlayerAbility)
      }
      setCallStack((callStack) => (data.callStack ? data.callStack : callStack))
      setAttacker(null)
      setTheirBoard(data.defenderCurrentBoard)
      setMyBoard(data.attackerCurrentBoard)
      setHand(data.myHand)
      setOpponentsHand(data.theirHand)
      setTheirGraveyard(data.theirCurrentGY)
      setMyGraveyard(data.myCurrentGY)
      setPlayer(data.myPlayer)
      setOpponent(data.theirPlayer)
      setCurrentMana(data.myCurrentMana)
      setGameDeck(data.deck)
      setTheirGameDeck(data.theirDeck)
      setSpellID(data.spellID ? data.spellID : null)
      setMana(data.mana)
      setDrawFromGraveyard(data.drawFromGraveyard)
      setOpponentDrawFromGraveyard(data.opponentDrawFromGraveyard)
      setTheirAttacked(data.theirAttacked)
      setOnDeathGlobals(data.onDeathGlobals)
      setOpponentDeathGlobals(data.opponentDeathGlobals)
      setOnCastGlobals(data.onCastGlobals)
      setOpponentCastGlobals(data.opponentCastGlobals)
      setOndrawGlobals(data.onDrawGlobals)
      setOpponentDrawGlobals(data.opponentDrawGlobals)
      setOnHealGlobals(data.onHealGlobals)
      setOpponentHealGlobals(data.opponentHealGlobals)
      setMyKingdomOfHeaven(data.myKingdomOfHeaven)
      setOpponentKingdomOfHeaven(data.opponentKingdomOfHeaven)
      setMyKingdomOfHell(data.myKingdomOfHell)
      setOpponentKingdomOfHell(data.opponentKingdomOfHell)
      setOnPlayGlobals(data.onPlayGlobals)
      setOpponentsCountersArray((opponentsCountersArray) => data.opponentsCountersArray ? data.opponentsCountersArray : opponentsCountersArray)
      setOpponentOnPlayGlobals(data.opponentOnPlayGlobals)
      setProphecyArray(data.prophecyArray)
      setDoubleCast(
        data.doubleCast.reset
          ? { doubleCast: false, reset: false }
          : data.doubleCast
      )
      if (!data.handFunction) {
        setHandChoices(data.handChoices)
        setHandNumber(data.handNumber)
        setHandSelection(data.handSelection)
      } else {
        setIsTurn(true)
      }
      setSpellReduction(
        data.spellReduction.reset
          ? {
              reduceCost: 0,
              reducedCost: false,
              lifeCost: { payLife: false, amount: 0 },
              dontSplice: false,
              reset: false,
            }
          : data.spellReduction
      )
      setCreatureReduction(
        data.creatureReduction.reset
          ? {
              reduceCost: 0,
              reducedCost: false,
              lifeCost: { payLife: false, amount: 0 },
              dontSplice: false,
              reset: false,
            }
          : data.creatureReduction
      )
      if (turnPass) {
        setNextCastDamage({ spellDamage: 0, reset: false })
      } else {
        setNextCastDamage(
          data.nextCastDamage.reset
            ? { spellDamage: 0, reset: false }
            : data.nextCastDamage
        )
      }
    }
    if (data.turnPass) {
      passTurn(data)
    } else if (turnPass || startGame) {
      setIsTurn((turnPass) => !turnPass)
    }
  }

  function removeElusive(data, location, card) {
    card.elusive = false
    if (card?.veilOfShadows) {
      data = resolveSpell(
        {
          ...data,
          myFunction: spellFunctions[card.abilities[0]],
          location: location,
          veilOfShadowsCard: card,
        },
        false
      )
    }
    return data
  }

  function castSpell(data, topLevel) {
    data = { ...data.myFunction({ ...data }) }
    if (topLevel) {
      if (
        data?.mySpellCast?.class === "sorcery" &&
        data.doubleCast.doubleCast === true
      ) {
        data.mySpellCast.doubleCast = true
        if (data.mySpellCast.activatesOn.includes("noTarget")) {
          data = data.myFunction(data)
          if (
            data.onCastGlobals.length &&
            data?.mySpellCast?.type === "spell"
          ) {
            for (let i = 0; i < data.onCastGlobals.length; i++) {
              if (data.onCastGlobals[i].activatesOn.includes("noTarget")) {
                data = resolveSpell(
                  {
                    ...data,
                    myFunction:
                      spellFunctions[data.onCastGlobals[i].abilities[0]],
                  },
                  false
                )
              } else {
                data = handleCallStack(data, data.onCastGlobals[i])
              }
            }
          }
        } else {
          data = handleCallStack(data, data.mySpellCast)
        }
        data.doubleCast.reset = true
      }
      if (data.onCastGlobals.length && data?.mySpellCast?.type === "spell") {
        for (let i = 0; i < data.onCastGlobals.length; i++) {
          data = handleCallStack(data, data.onCastGlobals[i])
        }
      }
      if (
        data.mySpellCast?.type === "spellcaster" &&
        !data.attacked.find((card) => card.id === data.mySpellCast?.id)
      ) {
        data.attacked =
          data.mySpellCast?.type === "spellcaster"
            ? [...data.attacked, data.mySpellCast]
            : data.attacked
      }
      updateBoardState(data)
    }
    return data
  }

  function handleCallStack(data, spell) {
    if (!data.spellCast) {
      data.spellCast = spell
      data.castingSpell = true
    } else {
      data.callStack.unshift(spell)
    }
    return data
  }

  function isSpectator(socketId, gameUser) {
    return socketId !== gameUser[0] && socketId !== gameUser[1]
  }

  function spellIsCountered(data) {
    return (
      data?.myCountersArray?.length &&
      !data.playerAbility &&
      (data?.target?.class !== "realm" ? true : false)
    )
  }

  function spellSplice(data) {
    if (
      data?.mySpellCast?.type === "spell" &&
      !data.recursion &&
      !data?.mySpellCast?.doubleCast
    ) {
      if (spellReduction.lifeCost.amount !== 10000) {
        data.myCurrentMana -= data.mySpellCast.cost - spellReduction.reduceCost
      }

      if (spellReduction.lifeCost.payLife) {
        if (spellReduction.lifeCost.amount === 10000) {
          data = resolveDamage(
            data,
            data.mySpellCast.cost - spellReduction.reduceCost,
            "self",
            false
          )
        } else {
          data = resolveDamage(
            data,
            spellReduction.lifeCost.amount - spellReduction.reduceCost,
            "self",
            false
          )
        }
      }

      if (!spellReduction?.dontSplice) {
        const myCastedSpell = data.myHand.splice(data.mySpellCast.index, 1)[0]
        if (data.mySpellCast.class !== "incarnate" && !data.mySpellCast.token) {
          data.myCurrentGY = [myCastedSpell, ...data.myCurrentGY]
        }
      }

      data.myPlayer.spellsCasted += 1
    } else if (data?.mySpellCast?.type === "spell" && data.recursion) {
      data.myCurrentMana -= data.mySpellCast.recursionCost
      data.myCurrentGY.splice(data.mySpellCast.index, 1)
      data.myPlayer.spellsCasted += 1
    }
    data.spellReduction.reset = true
    return data
  }
  // Handles cards that target your own GY
  function castOnGY(card, index) {
    let spellAbility
    if (spellCast) {
      spellAbility = spellCast.abilities[0]
    }
    resolveSpell({
      target: card,
      index,
      myFunction: spellFunctions[spellAbility],
    })
  }

  const canAttack = (data) => {
    return (
      ((data.target.type === "creature" ||
        data.target.type === "spellcaster") &&
        data.target.effects.includes("taunt")) ||
      ((data.target.type === "creature" ||
        data.target.type === "spellcaster") &&
        !data.defenderCurrentBoard.find((card) =>
          card.effects.includes("taunt")
        ))
    )
  }

  function randomId() {
    return Math.floor(
      (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        10000000
    )
  }

  const attackerElusive = (data) => {
    return data.attacker?.elusive
  }

  const targetElusive = (data) => {
    return data.target?.elusive
  }

  const targetDamageToAttacker = (data) => {
    const creature = data.attackerCurrentBoard.find(
      (card) => card.id === attacker.id
    )
    if (!attackerElusive(data)) {
      creature.damageTaken += data.target.attack
      if (creature.effects.includes("revenge")) {
        data = resolveSpell(
          {
            ...data,
            myFunction: spellFunctions[creature.abilities[0]],
            location: "attacker",
            damageReceived: data.target.attack,
            revenge: creature,
          },
          false
        )
      }
      if (data.target.effects.includes("bloodthirst")) {
        data = data.calculateHealing(data, data.target.attack, "opponent")
      }
      data.logs.push(
        `${creature.name} deals ${data.target.attack} damage to ${creature.name}`
      )
    } else {
      data = removeElusive(data, "attacker", creature)
      data.logs.push(
        `${creature.name} eluded an attack from ${data.target.name}`
      )
    }

    return data
  }

  const attackerDamageToTarget = (data) => {
    const creature = data.defenderCurrentBoard.find(
      (card) => card.id === data.target.id
    )
    if (!targetElusive(data)) {
      creature.damageTaken += data.attacker.attack
      if (creature.effects.includes("revenge")) {
        data = resolveSpell(
          {
            ...data,
            myFunction: spellFunctions[data.target.abilities[0]],
            location: "defender",
            damageReceived: data.attacker.attack,
            revenge: creature,
          },
          false
        )
      }
      if (data.attacker.effects.includes("bloodthirst")) {
        data = data.calculateHealing(data, data.attacker.attack)
      }
      data.logs.push(
        `${data.attacker.name} deals ${data.attacker.attack} damage to ${creature.name}`
      )
    } else {
      data = removeElusive(data, "defender", creature)
      data.logs.push(
        `${creature.name} eluded an attack from ${data.attacker.name}`
      )
    }

    return data
  }

  function resolveDamage(
    data,
    damage,
    target,
    topLevel = true,
    attackShouldGoOff = false,
    targetCreature
  ) {
    if (data.attacker && (topLevel || attackShouldGoOff) && !damage) {
      if (canAttack(data)) {
        data.didAttackOccur = true
        let combatNormal = true
        if (
          (!data.target.effects.includes("initialBlow") &&
            data.attacker.effects.includes("initialBlow")) ||
          (data.target.effects.includes("initialBlow") &&
            !data.attacker.effects.includes("initialBlow"))
        ) {
          combatNormal = false
        }
        if (combatNormal) {
          data = attackerDamageToTarget(data)
          data = targetDamageToAttacker(data)
        } else {
          if (data.attacker.effects.includes("initialBlow")) {
            if (
              data.target.defense <=
                data.target.damageTaken + data.attacker.attack &&
              !data.target?.elusive
            ) {
              data = attackerDamageToTarget(data)
            } else {
              data = attackerDamageToTarget(data)
              data = targetDamageToAttacker(data)
            }
          } else {
            if (
              data.attacker.defense <=
                data.attacker.damageTaken + data.target.attack &&
              !data.attacker?.elusive
            ) {
              data = targetDamageToAttacker(data)
            } else {
              data = attackerDamageToTarget(data)
              data = targetDamageToAttacker(data)
            }
          }
        }

        let empusaKill = false

        if (
          data.attacker.activatesOn.includes("onAttack") &&
          topLevel &&
          !attackShouldGoOff
        ) {
          data = resolveSpell(
            {
              ...data,
              myFunction: spellFunctions[data.attacker.abilities[0]],
            },
            false
          )
        }

        data = killCreature({
          ...data,
          location: "defender",
          card: data.target,
        })
        if (data.target.name === "Empusa") {
          data = killCreature({
            ...data,
            card: data.attacker,
            kill: true,
            location: "attacker",
          })
          empusaKill = true
        }
        if (!empusaKill) {
          data = killCreature({
            ...data,
            card: data.attacker,
            location: "attacker",
          })
        }

        if (
          data.target.activatesOn.includes("onKill") &&
          data?.defenderKill &&
          data?.defenderSurvive
        ) {
          data = resolveSpell(
            {
              ...data,
              location: "defender",
              myFunction: spellFunctions[data.target.abilities[0]],
            },
            false
          )
        }
        if (
          data.attacker.activatesOn.includes("onKill") &&
          data?.attackerKill &&
          data?.attackerSurvive
        ) {
          data = resolveSpell(
            {
              ...data,
              location: "attacker",
              target: data.attacker,
              index: data.attacker.index,
              myFunction: spellFunctions[data.attacker.abilities[0]],
            },
            false
          )
        }
        data.attackerSurvive = true
        data.defenderSurvive = true

        if (data.target.activatesOn.includes("onBlock")) {
          data = resolveSpell(
            { ...data, myFunction: spellFunctions[data.target.abilities[0]] },
            false
          )
        }
      } else if (
        data.target.type === "opponent" &&
        !data.defenderCurrentBoard.find((card) =>
          card.effects.includes("taunt")
        )
      ) {
        attackerFaceDamage(data, topLevel)
      }
    } else if (damage) {
      damage += data.nextCastDamage.spellDamage
      if (
        (data?.target?.type === "opponent" &&
          target !== "dontKill" &&
          target !== "self") ||
        target === "opponent"
      ) {
        data.theirPlayer.damageTaken += damage
      } else if (target === "self") {
        data.myPlayer.damageTaken += damage
      } else if (
        data?.target?.type === "creature" ||
        data?.target?.type === "spellcaster" ||
        target === "dontKill"
      ) {
        if (target === "dontKill") {
          if (!targetCreature?.elusive) {
            data.logs.push(`${targetCreature} takes ${damage} spell damage`)
            if (targetCreature.effects.includes("revenge")) {
              data = resolveSpell(
                {
                  ...data,
                  myFunction: spellFunctions[targetCreature.abilities[0]],
                  damageReceived: damage,
                  revenge: targetCreature,
                },
                false
              )
            }
            return damage
          } else {
            const theirMinion =
              data.defenderCurrentBoard[
                data.defenderCurrentBoard.findIndex(
                  (card) => card.id === targetCreature.id
                )
              ]
            const myMinion =
              data.attackerCurrentBoard[
                data.attackerCurrentBoard.findIndex(
                  (card) => card.id === targetCreature.id
                )
              ]
            if (theirMinion) data = removeElusive(data, "defender", theirMinion)
            if (myMinion) data = removeElusive(data, "attacker", myMinion)

            data.logs.push(`${targetCreature} eludes ${damage} spell damage`)
            return 0
          }
        }
        if (!data.target?.elusive) {
          data.logs.push(`${data.target.name} takes ${damage} spell damage`)
          data.target.damageTaken += damage
          data.defenderCurrentBoard[data.index].damageTaken += damage
          if (data.target.effects.includes("revenge")) {
            data = resolveSpell(
              {
                ...data,
                myFunction: spellFunctions[data.target.abilities[0]],
                damageReceived: damage,
                revenge: data.target,
              },
              false
            )
          }
        } else {
          data.logs.push(`${data.target.name} eludes ${damage} spell damage`)
          const minion =
            data.defenderCurrentBoard[
              data.defenderCurrentBoard.findIndex(
                (card) => card.id === data.target.id
              )
            ]
          if (minion) data = removeElusive(data, "defender", minion)
        }
        data = killCreature({
          ...data,
          location: "defender",
          card: data.defenderCurrentBoard[data.index],
        })
      }
    }
    return data
  }

  function attackerFaceDamage(data, topLevel) {
    data.didAttackOccur = true
    if (data.theirPlayer.durability === 0) {
      if (!data.attacker.effects.includes("noFaceDamage")) {
        data.theirPlayer.damageTaken += data.attacker.attack
        data.logs.push(
          `${data.attacker.name} deals ${data.attacker.attack} damage to ${data.theirPlayer.name}`
        )
        if (data.attacker.effects.includes("bloodthirst")) {
          data.logs.push(`${data.attacker.name} bloodthirst activates`)
          data = data.calculateHealing(data, data.attacker.attack)
        }
      }
    } else {
      data.theirPlayer.durability -= 1
      if (!data.attacker.effects.includes("noFaceDamage")) {
        data.attacker.attack > data.theirPlayer.block
          ? (data.theirPlayer.damageTaken +=
              data.attacker.attack - data.theirPlayer.block)
          : (data.theirPlayer.damageTaken += 0)
        data.logs.push(
          `${data.attacker.name} deals ${
            data.attacker.attack > data.theirPlayer.block
              ? data.attacker.attack - data.theirPlayer.block
              : 0
          } damage and removes 1 durability`
        )
      }
      data.theirPlayer.durability === 0
        ? (data.theirPlayer.block = 0)
        : (data.theirPlayer.block = data.theirPlayer.block)
      if (data.attacker.effects.includes("bloodthirst")) {
        data.logs.push(`${data.attacker.name} bloodthirst activates`)
        data = data.calculateHealing(data, data.attacker.attack)
      }
    }
    if (
      (data.attacker.activatesOn.includes("onAttack") ||
        data.attacker.effects.includes("poisonous")) &&
      topLevel
    ) {
      data = resolveSpell(
        {
          ...data,
          myFunction: spellFunctions[data.attacker.abilities[0]],
          onAttack: true,
        },
        false
      )
    }
    return data
  }

  // Handles damage calcuations and board states after those calculations
  function receiveDamage(target, index) {
    let data = setInitialState({
      target: target,
      index: index,
      myFunction: spellFunctions[spellCast?.abilities[0]],
    })
    if (
      castingSpell &&
      !target?.spellImmune &&
      !target?.opponentImmune &&
      (!data.mySpellCast.activatesOn.includes("friendly") ||
        data.mySpellCast.activatesOn.includes("anyMinion")) &&
      !data.mySpellCast.activatesOn.includes(
        "opponentChooses" &&
          !data.mySpellCast.activatesOn.includes("theirGraveyard")
      )
    ) {
      if (
        data.defenderCurrentBoard.find((card) =>
          card.effects.includes("guardian")
        )
      ) {
        data.index = data.defenderCurrentBoard.findIndex((card) =>
          card.effects.includes("guardian")
        )
        data.target = data.defenderCurrentBoard.find((card) =>
          card.effects.includes("guardian")
        )
      }
      resolveSpell({
        ...data,
        attacked: spellCast?.spellcaster
          ? [spellCast, ...data.attacked]
          : data.attacked,
      })
    } else if (attacker) {
      data = resolveDamage(data)
      if (data.didAttackOccur) {
        data.attacked = [...data.attacked, attacker]
        data.attackerAttacked = true
      }
      updateBoardState(data)
    }
  }
  // If you have a special incarnate card played this handles their abilities
  function castPlayerAbility(ability) {
    if (usedPlayerAbility) return
    resolveSpell({ myFunction: spellFunctions[ability], playerAbility: true })
    setUsedThisAbility(ability)
    setUsedPlayerAbility(true)
  }
  // Converts ability names to a readable string format
  const newString = (string) => {
    const splitLocation = string
      .split("")
      .findIndex((letter) => letter === letter.toUpperCase())
    return (
      string.slice(0, 1).toUpperCase() +
      string.slice(1, splitLocation) +
      " " +
      string.slice(splitLocation)
    )
  }

  function continueOn() {
    // navigate("/deckbuilder")
  }

  function drawCards(data, number, player = "self") {
    const drawFromGraveyard = {
      self: data.drawFromGraveyard,
      opponent: data.opponentDrawFromGraveyard,
    }
    const deck = {
      self: data.deck,
      opponent: data.theirDeck,
    }
    const hand = {
      self: data.myHand,
      opponent: data.theirHand,
    }
    const currentGY = {
      self: data.myCurrentGY,
      opponent: data.theirCurrentGY,
    }
    const playerObject = {
      self: data.myPlayer,
      opponent: data.theirPlayer,
    }

    const whoDraws = {
      self: player === "self" || player === "both",
      opponent: player === "opponent" || player === "both",
    }

    const drawnCards = {
      self: [],
      opponent: [],
    }

    for (let i = 0; i <= 1; i++) {
      const currentDraw = i === 0 ? "self" : "opponent"
      if (whoDraws[currentDraw]) {
        if (drawFromGraveyard[currentDraw]) {
          if (number > currentGY[currentDraw].length) {
            drawnCards[currentDraw].push.apply(
              drawnCards[currentDraw],
              currentGY[currentDraw].splice(0, currentGY[currentDraw].length)
            )
            playerObject[currentDraw].damageTaken +=
              drawnCards[currentDraw].length
          } else {
            for (let i = 0; i < number; i++) {
              const randomIndex = Math.floor(
                Math.random() * currentGY[currentDraw].length
              )
              drawnCards[currentDraw].push.apply(
                drawnCards[currentDraw],
                currentGY[currentDraw].splice(randomIndex, 1)
              )
            }
            playerObject[currentDraw].damageTaken +=
              drawnCards[currentDraw].length
          }
        } else {
          if (number > deck[currentDraw].length) {
            drawnCards[currentDraw].push.apply(
              drawnCards[currentDraw],
              deck[currentDraw].splice(0, deck[currentDraw].length)
            )
          } else {
            drawnCards[currentDraw].push.apply(
              drawnCards[currentDraw],
              deck[currentDraw].splice(0, number)
            )
          }
        }
      }
      hand[currentDraw].push.apply(hand[currentDraw], drawnCards[currentDraw])
    }

    data.drawnCards = [...drawnCards.self]
    data.theirDrawnCards = [...drawnCards.opponent]

    for (let i = 0; i < data.onDrawGlobals.length; i++) {
      data.draw = "myDraw"
      data = spellFunctions[data.onDrawGlobals[i].abilities[0]](data)
    }
    for (let i = 0; i < data.opponentDrawGlobals.length; i++) {
      data.draw = "opponentDraw"
      data = spellFunctions[data.opponentDrawGlobals[i].abilities[0]](data)
    }

    for (let i = 0; i < drawnCards.self.length; i++) {
      if (drawnCards.self[i].effects.includes("whenDrawn")) {
        data = spellFunctions[drawnCards.self[i].abilities[0]]({
          ...data,
          drawnCardLoop: drawnCards.self[i],
          location: "attacker",
        })
        delete data.drawnCardLoop
      }
      if (drawnCards.self[i].effects.includes("playWhenDrawn")) {
        const playedCreature = data.myHand.splice(
          data.myHand.findIndex((card) => card.id === drawnCards.self[i].id),
          1
        )[0]
        data = playCreature({
          ...data,
          creature: playedCreature,
          castSpell: true,
        })
      }
    }

    for (let i = 0; i < drawnCards.opponent.length; i++) {
      if (drawnCards.opponent[i].effects.includes("whenDrawn")) {
        data = spellFunctions[drawnCards.opponent[i].abilities[0]]({
          ...data,
          theirDrawnCardLoop: drawnCards.opponent[i],
          location: "defender",
        })
        delete data.theirDrawnCardLoop
      }
      // if (drawnCards.opponent[i].effects.includes("playWhenDrawn")) {
      //   const playedCreature = data.theirHand.splice(
      //     data.theirHand.findIndex(
      //       (card) => card.id === drawnCards.opponent[i].id
      //     ),
      //     1
      //   )[0]
      //   data = playCreatureOnOpponentBoard({
      //     ...data,
      //     creature: playedCreature,
      //     castSpell: true,
      //   })
      // }
    }

    data.drawnCards = [...drawnCards.self]
    data.theirDrawnCards = [...drawnCards.opponent]
    return data
  }

  function playerChoice(index) {
    if (
      spellCast?.activatesOn?.includes("selfChoice") ||
      spellCast?.spellcaster
    ) {
      setPlayerSelectionOptions([])
      setIsTurn(true)
      playerChoiceIndex.current = index
      pauseGame.current = false
    } else {
      setPlayerSelection(false)
      setPlayerSelectionOptions([])
      const isRecursionHappening = recursionTrue
      setRecursionTrue(false)
      // socket.current.emit("choice-picked", {
      //   room: room.current,
      //   socketID: socket.current.id,
      //   index,
      //   recursion: isRecursionHappening,
      // })
    }
  }

  function realmCard(card, index) {
    if (card.activatesOn.includes("castAbility")) {
      resolveSpell({
        myFunction: spellFunctions[card.abilities[0]],
        castAbility: true,
      })
    } else {
      // TODO: Fix this section of code to use updateBoardState Function
      const data = setInitialState({})
      data.myCurrentGY = [data.myHand.splice(index, 1)[0], ...data.myCurrentGY]
      data.myCurrentMana -= card.cost
      updateBoardState(data)
    }
  }

  function discardCards(data, number, random, player, updateBoard = false) {
    data = data || setInitialState({})

    const players = {
      self: player === "self" || player === "both",
      opponent: player === "opponent" || player === "both",
    }

    const hands = {
      self: data.myHand,
      opponent: data.theirHand,
    }

    const graveyards = {
      self: data.myCurrentGY,
      opponent: data.theirCurrentGY,
    }

    const resolveSpells = {
      self: [],
      opponent: [],
    }

    const discardedCards = {
      self: [],
      opponent: [],
    }

    if (random) {
      for (let i = 0; i <= 1; i++) {
        const currentDiscard = i === 0 ? "self" : "opponent"
        const discardAmount =
          number > hands[currentDiscard].length
            ? hands[currentDiscard].length
            : number

        if (players[currentDiscard]) {
          for (let i = 0; i < discardAmount; i++) {
            const randomIndex = Math.floor(
              Math.random() * hands[currentDiscard].length
            )
            const discardCard = hands[currentDiscard].splice(
              hands[currentDiscard][randomIndex],
              1
            )[0]
            discardedCards[currentDiscard].push(discardCard)
            if (discardCard.effects.includes("ambush")) {
              if (discardCard.type !== "spell") {
                if (currentDiscard === "self") {
                  data = playCreature({
                    ...data,
                    creature: discardCard,
                    castSpell: true,
                    location: "attacker",
                  })
                } else {
                  data = playCreatureOnOpponentBoard({
                    ...data,
                    creature: discardCard,
                    castSpell: true,
                    location: "defender",
                  })
                }
              } else {
                resolveSpells[currentDiscard].push(discardCard)
                graveyards[currentDiscard].unshift(discardCard)
              }
            } else {
              graveyards[currentDiscard].unshift(discardCard)
            }
          }
        }
      }
    } else {
      for (let i = 0; i < discardChoices.length; i++) {
        if (discardChoices[i].effects.includes("ambush")) {
          if (discardChoices[i].type !== "spell") {
            data = playCreature({
              ...data,
              creature: discardChoices[i],
              castSpell: true,
            })
            hands["self"] = hands["self"].filter(
              (card) => card.id !== discardChoices[i].id
            )
          } else {
            graveyards["self"] = [
              hands["self"].splice(
                hands["self"].findIndex(
                  (card) => card.id === discardChoices[i].id
                ),
                1
              )[0],
              ...graveyards["self"],
            ]
            resolveSpells["self"].push(discardChoices[i])
          }
        } else {
          graveyards["self"] = [
            hands["self"].splice(
              hands["self"].findIndex(
                (card) => card.id === discardChoices[i].id
              ),
              1
            )[0],
            ...graveyards["self"],
          ]
        }
      }
    }

    data.discardedCards = discardedCards

    for (let i = 0; i <= 1; i++) {
      const currentCaster = i === 0 ? "self" : "opponent"

      if (resolveSpells[currentCaster].length) {
        for (let i = 0; i < resolveSpells[currentCaster].length; i++) {
          data = resolveSpell(
            {
              ...data,
              myFunction:
                spellFunctions[resolveSpells[currentCaster][i].abilities[0]],
              location: currentCaster === "self" ? "attacker" : "defender",
              discardCard: true,
            },
            false
          )
        }
      }
    }

    if (updateBoard) {
      updateBoardState(data)
      setIsTurn(true)
    }
    return data
  }

  const receiveHealing = () => {
    if (spellCast?.activatesOn.includes("self")) {
      let data = setInitialState({})
      data = resolveSpell(
        {
          ...data,
          myFunction: spellFunctions[spellCast.abilities[0]],
          target: player,
        },
        false
      )
      updateBoardState(data)
    }
  }

  function handSelectionFunction() {
    let data = setInitialState({})
    data.handFunction = true
    data = resolveSpell(
      { ...data, myFunction: spellFunctions[spellCast.abilities[0]] },
      false
    )
    updateBoardState(data)
  }

  const dataFlip = () => {
    const data = setInitialState({})
    setTheirBoard(data.attackerCurrentBoard)
    setMyBoard(data.defenderCurrentBoard)
    setHand(data.theirHand)
    setOpponentsHand(data.myHand)
    setTheirGraveyard(data.myCurrentGY)
    setMyGraveyard(data.theirCurrentGY)
    setPlayer(data.theirPlayer)
    setOpponent(data.myPlayer)
    setCurrentMana(opponentsCurrentMana)
    setOpponentsCurrentMana(data.myCurrentMana)
    setMana(opponentsMana)
    setOpponentsMana(data.mana)
    setGameDeck(data.theirDeck)
    setTheirGameDeck(data.deck)
    setDrawFromGraveyard(data.opponentDrawFromGraveyard)
    setOpponentDrawFromGraveyard(data.drawFromGraveyard)
    setTheirAttacked(attacked)
    setAttacked(data.theirAttacked)
    setOnDeathGlobals(data.opponentDeathGlobals)
    setOpponentDeathGlobals(data.onDeathGlobals)
    setOnCastGlobals(data.opponentCastGlobals)
    setOpponentCastGlobals(data.onCastGlobals)
    setOndrawGlobals(data.opponentDrawGlobals)
    setOpponentDrawGlobals(data.onDrawGlobals)
    setOnHealGlobals(data.opponentHealGlobals)
    setOpponentHealGlobals(data.onHealGlobals)
    setMyKingdomOfHeaven(data.opponentKingdomOfHeaven)
    setOpponentKingdomOfHeaven(data.myKingdomOfHeaven)
    setMyKingdomOfHell(data.opponentKingdomOfHell)
    setOpponentKingdomOfHell(data.myKingdomOfHell)
    setOnPlayGlobals(data.opponentOnPlayGlobals)
    setOpponentOnPlayGlobals(data.onPlayGlobals)
    setProphecyArray(data.opponentProphecyArray)
    setOpponentProphecyArray(data.prophecyArray)
    setMyCountersArray(data.opponentsCountersArray)
    setOpponentsCountersArray(data.myCountersArray)
    setOpponentsTurn((opponentsTurn) => !opponentsTurn)
  }

  const boardFlip = () => {
    flipBoard.current = false
    clickCount.current = 1
    if (activePlayer.current === "playerTwo") {
      turnCount.current += 1
    }
    if (activePlayer.current === "playerOne") {
      activePlayer.current = "playerTwo"
    } else {
      activePlayer.current = "playerOne"
    }
    dataFlip()
  }

  const setupInitialState = () => {
    setReplayActive((replayActive) => !replayActive)
    updateBoardState(replayData.initialState)
  }

  const nextAction = (repeat) => {
    const action =
      replayData[activePlayer.current][turnCount.current][clickCount.current]

    if (!isTurn && !flipBoard.current && !pauseGame.current) {
      setIsTurn(true)
    } else if (action.action === "clickLocation" || action.action === "clickCard") {
      clickLocation(
        action.clickedCard,
        action.clickedCard.index,
        action.clickedCard.location
      )
      clickCount.current += 1
    } else if (action.action === "passTurn" && !flipBoard.current) {
      flipBoard.current = true
      passTurn()
    } else if (action.action === "cancelSpell") {
      clickCount.current += 1
      cancelSpell()
    } else if (action.action === "cancelSummon") {
      pauseGame.current = false
      clickCount.current += 1
      cancelSummon()
    } else if (action.action === "receiveDamage") {
      clickCount.current += 1
      if (!action.clickedCard) {
        receiveDamage({ type: "opponent" })
      }
    } else if (action.action === "worshipSummon") {
      clickCount.current += 1
      playCreature(
        null,
        action.clickedCard,
        action.clickedCard.index,
        false,
        true
      )
    } else if (action.action === "opponentChoice") {
      clickCount.current += 1
      pauseGame.current = false
      setIsTurn(true)
      setOpponentChoice(action.clickedCard.index)
      setRecursionTrue(action.clickedCard.recursion)
    } else if (action.action === "playerAbility") {
      clickCount.current += 1
      castPlayerAbility(action.clickedCard.ability)
    } else if (action.action === "playerChoice") {
      clickCount.current += 1
      pauseGame.current = false
      playerChoice(action.clickedCard.index)
      setPlayerSelection(true)
    } else if (action.action === "discardCards") {
      clickCount.current += 1
      pauseGame.current = false
      discardCards(null, null, null, null, true)
    } else if (action.action === "receiveHealing") {
      clickCount.current += 1
      receiveHealing(spellCast)
    } else if (action.action === "handSelectionFunction") {
      clickCount.current += 1
      pauseGame.current = false
      handSelectionFunction()
    } else if (flipBoard.current) {
      boardFlip()
    }
    if (repeat) setTimeout(() => startReplay(), 333)
  }

  const lastAction = () => {}

  const startReplay = () => {
    if (!autoPlayCancel.current) {
      setAutoPlay(true)
    } else {
      autoPlayCancel.current = false
    }
    setTimeout(() => setAutoPlay(false), 333)
  }

  const pauseReplay = () => {
    autoPlayCancel.current = true
  }

  return (
    <div id="mainDivContainer">
      <h1 className="gameFontColors">Under Construction</h1>
      {replayData.initialState && (
        <button className="biggerButton" onClick={setupInitialState}>
          {replayActive ? "End Replay" : "Setup Replay"}
        </button>
      )}
      {replayActive && (
        <button className="biggerButton" onClick={startReplay}>
          Auto Play
        </button>
      )}
      {replayActive && (
        <button className="biggerButton" onClick={pauseReplay}>
          Pause Replay
        </button>
      )}
      {replayActive && (
        <button className="biggerButton" onClick={() => nextAction(false)}>
          Next Action
        </button>
      )}
      {replayActive && (
        <button className="biggerButton" onClick={lastAction}>
          Last Action
        </button>
      )}
      <InfoText infoText={infoText} />
      {replayActive && !opponentsTurn && (
        <div>
          <Board
            hand={opponentsHand}
            graveyard={theirGraveyard}
            spellCast={spellCast}
            board={theirBoard}
            player={opponent}
            playerAbilityClick={false}
            discardChoices={discardChoices}
            attacked={theirAttacked}
            playerSelection={playerSelection}
            discardSelection={discardSelection}
            user={opponentUser}
            expandGY={expandOpponentGY}
            deck={theirGameDeck}
            spellCounter={opponentsCountersArray}
            mulliganedCards={mulliganedCards}
            resolveMulligan={resolveMulligan}
            // mulliganSelection={mulliganSelection}
            // clickLocation={clickLocation}
            // discardCards={discardCards}
            // sendMulligan={sendMulligan}
            // playCreature={playCreature}
            discardNumber={discardNumber}
            spectate={spectate}
            sideBoarding={sideBoarding}
            opponentSideBoarding={opponentSideBoarding}
            whoseSide={"opponentSide"}
            usedThisAbility={usedThisAbility}
            newString={newString}
            // castPlayerAbility={castPlayerAbility}
            mana={opponentsMana}
            currentMana={opponentsCurrentMana}
            // receiveDamage={receiveDamage}
            // receiveHealing={receiveHealing}
            handChoices={handChoices}
            handNumber={handNumber}
            handSelection={handSelection}
            // handSelectionFunction={handSelectionFunction}
            worship={worship}
            worshipArray={worshipArray}
            // availableWorshipMana={availableWorshipMana}
            // cancelSummon={cancelSummon}
            prophecyArray={prophecyArray}
            opponentProphecyArray={opponentProphecyArray}
            handReveal={handReveal}
          />
          <div className="space-between">
            <button
              className="biggerButton"
              // onClick={() => passTurn()}
              disabled={!isTurn || callStack.length}
            >
              Pass Turn
            </button>
            {spellCast ? (
              <button onClick={cancelSpell} className="biggerButton">
                Cancel Spell
              </button>
            ) : (
              <div></div>
            )}
          </div>
          <Board
            hand={hand}
            graveyard={myGraveyard}
            spellCast={spellCast}
            board={myBoard}
            player={player}
            playerAbilityClick={true}
            discardChoices={discardChoices}
            attacked={attacked}
            playerSelection={playerSelection}
            discardSelection={discardSelection}
            user={user}
            expandGY={expandMyGY}
            deck={gameDeck}
            spellCounter={myCountersArray}
            mulliganedCards={mulliganedCards}
            resolveMulligan={resolveMulligan}
            // mulliganSelection={mulliganSelection}
            // clickLocation={clickLocation}
            // discardCards={discardCards}
            // sendMulligan={sendMulligan}
            // playCreature={playCreature}
            discardNumber={discardNumber}
            spectate={spectate}
            sideBoarding={sideBoarding}
            opponentSideBoarding={opponentSideBoarding}
            whoseSide={"mySide"}
            usedThisAbility={usedThisAbility}
            newString={newString}
            // castPlayerAbility={castPlayerAbility}
            mana={mana}
            currentMana={currentMana}
            // receiveDamage={receiveDamage}
            // receiveHealing={receiveHealing}
            handChoices={handChoices}
            handNumber={handNumber}
            handSelection={handSelection}
            // handSelectionFunction={handSelectionFunction}
            worship={worship}
            worshipArray={worshipArray}
            // availableWorshipMana={availableWorshipMana}
            // cancelSummon={cancelSummon}
            prophecyArray={prophecyArray}
            opponentProphecyArray={opponentProphecyArray}
            isolationCheck={isolationCheck}
          />
        </div>
      )}
      {replayActive && opponentsTurn && (
        <div>
          <Board
            hand={hand}
            graveyard={myGraveyard}
            spellCast={spellCast}
            board={myBoard}
            player={player}
            playerAbilityClick={true}
            discardChoices={discardChoices}
            attacked={attacked}
            playerSelection={playerSelection}
            discardSelection={discardSelection}
            user={opponentUser}
            expandGY={expandMyGY}
            deck={gameDeck}
            spellCounter={myCountersArray}
            mulliganedCards={mulliganedCards}
            resolveMulligan={resolveMulligan}
            // mulliganSelection={mulliganSelection}
            // clickLocation={clickLocation}
            // discardCards={discardCards}
            // sendMulligan={sendMulligan}
            // playCreature={playCreature}
            discardNumber={discardNumber}
            spectate={spectate}
            sideBoarding={sideBoarding}
            opponentSideBoarding={opponentSideBoarding}
            whoseSide={"opponentSide"}
            usedThisAbility={usedThisAbility}
            newString={newString}
            // castPlayerAbility={castPlayerAbility}
            mana={mana}
            currentMana={currentMana}
            // receiveDamage={receiveDamage}
            // receiveHealing={receiveHealing}
            handChoices={handChoices}
            handNumber={handNumber}
            handSelection={handSelection}
            // handSelectionFunction={handSelectionFunction}
            worship={worship}
            worshipArray={worshipArray}
            // availableWorshipMana={availableWorshipMana}
            // cancelSummon={cancelSummon}
            prophecyArray={prophecyArray}
            opponentProphecyArray={opponentProphecyArray}
            isolationCheck={isolationCheck}
            handReveal={handReveal}
          />
          <div className="space-between">
            <button
              className="biggerButton"
              // onClick={() => passTurn()}
              disabled={!isTurn || callStack.length}
            >
              Pass Turn
            </button>
            {spellCast ? (
              <button onClick={cancelSpell} className="biggerButton">
                Cancel Spell
              </button>
            ) : (
              <div></div>
            )}
          </div>
          <Board
            hand={opponentsHand}
            graveyard={theirGraveyard}
            spellCast={spellCast}
            board={theirBoard}
            player={opponent}
            playerAbilityClick={false}
            discardChoices={discardChoices}
            attacked={theirAttacked}
            playerSelection={playerSelection}
            discardSelection={discardSelection}
            user={user}
            expandGY={expandOpponentGY}
            deck={theirGameDeck}
            spellCounter={opponentsCountersArray}
            mulliganedCards={mulliganedCards}
            resolveMulligan={resolveMulligan}
            // mulliganSelection={mulliganSelection}
            // clickLocation={clickLocation}
            // discardCards={discardCards}
            // sendMulligan={sendMulligan}
            // playCreature={playCreature}
            discardNumber={discardNumber}
            spectate={spectate}
            sideBoarding={sideBoarding}
            opponentSideBoarding={opponentSideBoarding}
            whoseSide={"mySide"}
            usedThisAbility={usedThisAbility}
            newString={newString}
            // castPlayerAbility={castPlayerAbility}
            mana={opponentsMana}
            currentMana={opponentsCurrentMana}
            // receiveDamage={receiveDamage}
            // receiveHealing={receiveHealing}
            handChoices={handChoices}
            handNumber={handNumber}
            handSelection={handSelection}
            // handSelectionFunction={handSelectionFunction}
            worship={worship}
            worshipArray={worshipArray}
            // availableWorshipMana={availableWorshipMana}
            // cancelSummon={cancelSummon}
            prophecyArray={prophecyArray}
            opponentProphecyArray={opponentProphecyArray}
            isolationCheck={isolationCheck}
          />
        </div>
      )}
      {playerSelection && playerSelectionOptions.length > 0 && (
        <div id="userSelectionBox">
          <div></div>
          <div id="optionBoxDiv">
            <div>Choose One:</div>
            {playerSelectionOptions.map((option, index) => (
              <div
                onClick={() => playerChoice(index)}
                className="playerSelectionOptions"
              >
                {option}
              </div>
            ))}
          </div>
          <div></div>
        </div>
      )}
      {!sideBoarding && gameIsFinished.current && (
        <div id="endGameBox">
          <div id="endGameScreen">
            {(infoText === "Victory!" ||
              infoText === "Defeat!" ||
              infoText === "Game is over and room is closed!") && (
              <button onClick={continueOn} className="biggerButton">
                Continue
              </button>
            )}
          </div>
        </div>
      )}
      {logs.length > 0 && <GameLog logs={logs} />}
    </div>
  )
}

export default Replay
