import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const ReloadGame = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate("/play")
  }, [])

  return <h1 className="gameFontColors">Loading...</h1>
}

export default ReloadGame
