import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

export default function GameLog({ logs }) {
  // Create a state variable to store the reversed log
  const [reversedLogs, setReversedLogs] = useState([]);

  useEffect(() => {
    // Reverse the log array when it's initially received as a pro
    setReversedLogs(logs.slice().reverse());
  }, [logs]); // Only update when the `logs` prop changes

  return (
    <GameLogContainer>
      <div>Game Log</div>
      {reversedLogs.map((log, index) => {
        return <pre key={index}>{JSON.stringify(log)}</pre>;
      })}
    </GameLogContainer>
  );
}

export function logAction(message, setLogs) {
  setLogs((prevLogs) => {
    return [...prevLogs, message];
  });
}

const GameLogContainer = styled.div`
height: 400px;
width: 80%;
display: none;
overflow-y: auto;
border: 1px solid black;
border-radius: 5px;
background-color: white;
justify-self: center;
`