// Import sound files
import cardShuffleSound from "./sounds/cardShuffle.mp3"
import pageSwitchSound from "./sounds/pageSwitch.wav"
import cardAttack from "./sounds/cardAttack.wav"
import turnFlip from "./sounds/turnFlip.wav"

const muted = {}
muted.current = false

// Define function to play sound effect
const playSound = (soundFile) => {
  if (muted.current) return
  const audio = new Audio(soundFile)
  audio.volume = 0.5 // Set volume
  audio.play()
}

// Define sound effects object
const soundEffects = {
  muteSounds: () => {
    muted.current = true
  },
  unmuteSounds: () => {
    muted.current = false
  },
  cardShuffle: () => {
    playSound(cardShuffleSound)
  },
  pageSwitch: () => {
    playSound(pageSwitchSound)
  },
  cardAttack: () => {
    playSound(cardAttack)
  },
  flipTurn: () => {
    playSound(turnFlip)
  },
}

export default soundEffects
