import io from "socket.io-client"
import { config } from "../constants"
import { useEffect, useRef } from "react"

const url = config.url.API_URL

const useSocket = () => {
  const socket = useRef(null)

  useEffect(() => {
    if (!socket.current) {
      socket.current = io(url, {
        transports: ["websocket"],
        pingInterval: 5000,
        pongInterval: 5000,
      })
    }

    socket.current.activeGame = false

    socket.current.on("disconnect", () => {
      console.log("Disconnected from the server")
    })

    socket.current.on("connect", () => {
      if (socket.current?.activeGame && !socket.current?.inQueue) {
        console.log("Reconnecting to the game")
        socket.current.emit("reconnect-to-game", {
          room: socket.current.room,
          email: socket.current.email,
          ranked: socket.current?.ranked,
          uniqueId: socket.current.uniqueId,
          gameId: socket.current?.dontSend ? null : socket.current.gameId,
          firstTurn: socket.current?.firstTurn
        })
      } else if (socket.current.inQueue) {
        socket.current.emit("leave-queue", socket.current.user)
        socket.current.inQueue = false
        socket.current.activeGame = false
      }
      if (socket.current.uniqueId) {
        socket.current.emit("update-user-email", socket.current.uniqueId)
      }
    })
  }, [])

  return socket
}

export default useSocket
