import authHook from "../customHooks/authHook"
import { useContext } from "react"
import { GameContext } from "../App"

const Contact = () => {
  authHook()
  const { user } = useContext(GameContext)
  return (
    <div className="formPage">
      <div className="contactBox">
        <form name="contact" method="post" data-netlify="true" action="/">
          <input type="hidden" name="form-name" value="contact" />
          <h2>Contact Us</h2>
          <div className="contactInputBox">
            <input
              value={`${user.email}`}
              type="text"
              htmlFor="email"
              name="email"
              required
            />
            <span>Email</span>
            <i></i>
          </div>
          <div className="contactInputBoxMessage">
            <textarea
              className="contactInputMessage"
              type="text"
              htmlFor="message"
              name="message"
              required
            />
            <span>Message</span>
            <i></i>
          </div>
          <input type="submit" value="Send Message" />
        </form>
      </div>
    </div>
  )
}

export default Contact
