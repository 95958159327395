import Card from "./card"
import Carousel from "./global/carousel"
import ManaDisplay from "./manaDisplay"

function Board({
  setExpandMyArtifacts,
  setExpandMyGY,
  hand,
  graveyard,
  spellCast,
  board,
  player,
  discardChoices,
  attacked,
  playerSelection,
  discardSelection,
  user,
  expandGY,
  deck,
  spellCounter,
  mulliganedCards,
  resolveMulligan,
  mulliganSelection,
  clickLocation,
  discardCards,
  attacker,
  sendMulligan,
  discardNumber,
  spectate,
  sideBoarding,
  opponentSideBoarding,
  whoseSide,
  usedThisAbility,
  newString,
  castPlayerAbility,
  mana,
  currentMana,
  receiveDamage,
  receiveHealing,
  handSelectionFunction,
  handChoices,
  handSelection,
  handNumber,
  worship,
  worshipArray,
  playCreature,
  cancelSummon,
  prophecyArray,
  playerAbilityClick,
  opponentProphecyArray,
  isolationCheck,
  cardBack,
  opponentCardBack,
  handReveal = false,
  artifacts,
  expandArtifacts,
  gyOrArtifacts,
  changeGYDisplay,
  exileZone,
  playedFirst,
}) {
  const boardState = () => {
    return (
      <div
        id={whoseSide === "mySide" ? "myBoard" : "theirBoard"}
        className="basicFlex"
      >
        {board.map((card, index) => {
          const iconsArray = []
          if (!attacked.map((card) => card.id).includes(card.id)) {
            if(!card.effects.includes("cantAttack")) iconsArray.push({ class: "attacked", name: "swords" })
          }
          if (card.effects.includes("provoke")) {
            iconsArray.push({
              class: "provoke",
              name: "brightness_alert",
            })
          }
          if (card.effects.includes("frozen")) {
            iconsArray.push({ class: "frozen", name: "ac_unit" })
          }
          if (card?.elusive) {
            iconsArray.push({ class: "elusive", name: "mystery" })
          }

          const id =
            whoseSide === "mySide"
              ? spellCast?.id === card.id ||
                worshipArray.find((arrayCard) => arrayCard.id === card.id) ||
                attacker?.id === card.id
                ? "casting"
                : !attacked
                    .map((attackedCard) => attackedCard.id)
                    .includes(card.id) && card.attack > 0 && !card.effects.includes("cantAttack")
                ? "attacked"
                : card.elusive
                ? "elusive"
                : card.effects.includes("provoke")
                ? "provoke"
                : card.effects.includes("frozen")
                ? "frozen"
                : null
              : card.elusive
              ? "elusive"
              : card.effects.includes("provoke")
              ? "provoke"
              : card.effects.includes("frozen")
              ? "frozen"
              : !attacked
                  .map((attackedCard) => attackedCard.id)
                  .includes(card.id) && card.attack > 0
              ? "attacked"
              : null

          return (
            <Card
              id={id}
              className="bottomSide"
              cardStyle={user.usersettings?.[card.name]?.style}
              icons={iconsArray}
              card={card}
              onClick={() =>
                clickLocation(
                  card,
                  index,
                  whoseSide === "mySide" ? "myBoard" : "theirBoard"
                )
              }
              key={card.id}
            />
          )
        })}
      </div>
    )
  }

  const playerState = () => {
    const {
      defense,
      damageTaken,
      durability,
      block,
      poison,
      revitalize,
      spellsCasted,
      healAbsorb,
      sacrifices,
    } = player
    return (
      <div
        id={handReveal ? "theirPlayerInfo" : "myPlayerInfo"}
        className="gameFontColors"
      >
        <span
          id={whoseSide === "mySide" ? "myLife" : "opponentLife"}
          className={"lifeAndStatus"}
          onClick={() =>
            whoseSide === "mySide" ? receiveHealing() : receiveDamage(player)
          }
        >
          Life: {`${defense - damageTaken}/${defense}`}
          {!attacker?.id && !spellCast?.id && (
            <div
              id={
                whoseSide === "mySide" ? "hideDisplay" : "hideDisplayOpponent"
              }
            >
              {hand.length}
              {hand.length > 1
                ? " Cards "
                : hand.length === 0
                ? " Cards "
                : " Card "}
              in hand / {defense - damageTaken} damage until defeat /
              {` ${graveyard.length}`}
              {graveyard.length > 1
                ? " Cards "
                : graveyard.length === 0
                ? " Cards "
                : " Card "}
              in the graveyard
            </div>
          )}
        </span>
        <span className={"lifeAndStatus"}>
          {durability ? ` Armor: ${block}/${durability}` : ""}
          <div
            id={whoseSide === "mySide" ? "hideDisplay" : "hideDisplayOpponent"}
          >
            Block / Durability - Armor mitigates incoming damage from enemy
            attacks. It absorbs X amount of damage from the next minion attack.
            However, for each attack it absorbs, the durability of the armor
            decreases by 1.
          </div>
        </span>
        <span className={"lifeAndStatus"}>
          {poison ? ` Poison: ${poison}` : ""}
          <div
            id={whoseSide === "mySide" ? "hideDisplay" : "hideDisplayOpponent"}
          >
            At the start of the afflicted players turn they receive X damage
            equal to the amount of poison accumulated, then the amount decreases
            by 1
          </div>
        </span>
        <span className={"lifeAndStatus"}>
          {revitalize ? ` Revitalize: ${revitalize}` : ""}
          <div
            id={whoseSide === "mySide" ? "hideDisplay" : "hideDisplayOpponent"}
          >
            At the start of the controlling players turn they receive X healing
            equal to the amount of revitalize accumulated, then the amount
            decreases by 1
          </div>
        </span>
        {spellsCasted &&
        (deck.some((card) => card.effects.includes("totalSpellCount")) ||
          hand.some((card) => card.effects.includes("totalSpellCount")) ||
          graveyard.some((card) =>
            card.effects.includes("totalSpellCount")
          )) ? (
          <span
            className={"lifeAndStatus"}
          >{` Casted Spells: ${spellsCasted}`}</span>
        ) : (
          ""
        )}
        {spellCounter?.length > 0 ? (
          <span className="counterSpellSpan">
            {`Counterspells: ${spellCounter?.length}`}
            <div
              id={
                whoseSide === "mySide" ? "hideDisplay" : "hideDisplayOpponent"
              }
            >
              {spellCounter[0].counterText}
            </div>
          </span>
        ) : (
          ""
        )}
        <span className={"lifeAndStatus"}>
          {healAbsorb ? ` Heal Absorb: ${healAbsorb}` : ""}
          <div
            id={whoseSide === "mySide" ? "hideDisplay" : "hideDisplayOpponent"}
          >
            Prevents the next X healing
          </div>
        </span>
        <span className={"lifeAndStatus"}>
          {sacrifices &&
          [...board, ...deck, ...hand, ...graveyard].find((card) =>
            card?.text.includes("Soul Harvest:")
          )
            ? ` Sacrifices: ${sacrifices}`
            : ""}
        </span>
      </div>
    )
  }

  const renderExileZone = () => {
    return (
      <div id="exileZone">
        {exileZone.map((card) => {
          return (
            <Card
              id={card.Id}
              user={user}
              finalPosition={{}}
              isCasting={spellCast && spellCast.id === card?.id}
              card={card}
              key={card.id}
              cardStyle={user.usersettings?.[card.name]?.style}
            />
          )
        })}
      </div>
    )
  }

  const handState = () => {
    if (whoseSide === "mySide") {
      const totalCards = hand.length
      const totalRotation = 10
      const step = totalRotation / totalCards
      const handWidth = document.getElementById("hand")?.offsetWidth
      const handSeperation =
        hand.length > 15 ? 75 : hand.length > 10 ? 125 : 175

      const separation = Math.min(handWidth / totalCards, handSeperation)

      // Calculate the initial offset to center the cards within #hand
      const initialOffset = 0 - separation * Math.trunc(totalCards / 2)

      return (
        <div
          id={handReveal ? "cardsInTheirHand" : "hand"}
          style={expandGY ? { zIndex: 0 } : { zIndex: 1 }}
        >
          {hand.map((card, index) => {
            const rotation = -5 + step * index
            const translateX = initialOffset + separation * index

            const cardId =
              spellCast?.id === card.id ||
              worship?.id === card.id ||
              mulliganedCards.find((choice) => choice.id === card.id) ||
              discardChoices.find((choice) => choice.id === card.id) ||
              handChoices.find((choice) => choice.id === card.id)
                ? "casting"
                : card?.isolation && isolationCheck(deck)
                ? "attacked"
                : null

            return (
              <Card
                id={cardId}
                user={user}
                finalPosition={{}}
                isCasting={spellCast && spellCast.id === card?.id}
                className="topSide"
                card={card}
                key={card.id}
                rotation={rotation}
                translate={translateX}
                cardStyle={user.usersettings?.[card.name]?.style}
                onClick={() =>
                  resolveMulligan
                    ? mulliganSelection(card, index)
                    : clickLocation(card, index, "hand")
                }
              />
            )
          })}
        </div>
      )
    } else {
      return (
        <div id={`${handReveal ? "revealedHand" : "cardsInTheirHand"}`}>
          {handReveal ? (
            <Carousel>
              {hand.slice(0, hand.length).map((card) => (
                <Card
                  key={card.id}
                  card={card}
                  user={user}
                  className="topSide"
                />
              ))}
            </Carousel>
          ) : (
            hand
              .slice(0, 10)
              .map((card) => (
                <div
                  key={card.id}
                  className={`deck ${
                    opponentCardBack ? opponentCardBack : "defaultCardBack"
                  }`}
                ></div>
              ))
          )}

          {hand.length > 10 && !handReveal && (
            <h1 className="gameFontColors handPlus">+</h1>
          )}
        </div>
      )
    }
  }

  const functionButton = () => (
    <>
      {playerSelection && discardSelection && (
        <div id="gameFunctionButton" className="basicCenter">
          <button
            className="biggerButton glow-button"
            disabled={discardNumber !== discardChoices?.length}
            onClick={() => discardCards(null, null, null, null, true)}
          >
            Discard {discardNumber}
          </button>
        </div>
      )}

      {playerSelection && handSelection && (
        <div id="gameFunctionButton" className="basicCenter">
          <button
            className="biggerButton"
            disabled={handNumber !== handChoices?.length}
            onClick={() => handSelectionFunction()}
          >
            Choose {handNumber}
          </button>
        </div>
      )}

      {resolveMulligan &&
        deck?.length &&
        !opponentSideBoarding &&
        !sideBoarding &&
        spectate.current <= 0 && (
          <div id="gameFunctionButton">
            {playedFirst ? (
              <div className="gameFontColors biggerText turnPosition">You play First</div>
            ) : (
              <div className="gameFontColors biggerText turnPosition">You play Second</div>
            )}
            <button
              onClick={sendMulligan}
              className="biggerButton  glow-button"
            >
              Finish Mulligan
            </button>
          </div>
        )}

      {worship.id && (
        <div id="gameFunctionButton" className="basicCenter">
          {currentMana >= worship.cost && (
            <button
              className="biggerButton"
              onClick={() =>
                playCreature(
                  null,
                  worship,
                  false,
                  true
                )
              }
            >
              Play Creature
            </button>
          )}
          <button
            className="biggerButton"
            disabled={worshipArray.length === 0}
            onClick={() =>
              playCreature(
                null,
                worship,
                false,
                true
              )
            }
          >
            {worshipArray.length === 0
              ? `Click Creatures to sacrifice`
              : `Worship Summon For ${
                  worship.cost - worshipArray?.length
                } Mana)`}
          </button>
          <button className="biggerButton" onClick={cancelSummon}>
            Cancel
          </button>
        </div>
      )}
    </>
  )
  // TODO: DISPLAY TOP 2 CARDS IN GY
  const graveyardAndDeck = () => {
    const renderGraveyardCards = (graveyard, spellCast) => {
      if (graveyard?.length && !expandGY) {
        const card = graveyard[0]
        return (
          <Card
            id={
              prophecyArray.find((prophecy) => card.id === prophecy.id) ||
              opponentProphecyArray.find((prophecy) => card.id === prophecy.id)
                ? "casting"
                : ""
            }
            finalPosition={{}}
            expandGY={expandGY}
            cardStyle={user.usersettings?.[card.name]?.style}
            className="bottomSide"
            onClick={() =>
              clickLocation(
                card,
                0,
                whoseSide === "mySide" ? "myGraveyard" : "theirGraveyard"
              )
            }
            card={card}
            key={card.id}
          />
        )
      } else {
        return (
          graveyard?.length > 0 && (
            <>
              <span
                onClick={() => setExpandMyGY((expandGY) => !expandGY)}
                className="gameFontColors graveyardCloseButton"
              >
                X
              </span>
              <div style={{ display: "flex" }}>
                {graveyard.map((card, index) => (
                  <Card
                    id={
                      prophecyArray.find(
                        (prophecy) => prophecy.id === card.id
                      ) ||
                      opponentProphecyArray.find(
                        (prophecy) => card.id === prophecy.id
                      ) ||
                      (spellCast && spellCast.id === card.id)
                        ? "casting"
                        : ""
                    }
                    cardStyle={user.usersettings?.[card.name]?.style}
                    key={card.id}
                    className="bottomSide"
                    onClick={() =>
                      clickLocation(
                        card,
                        index,
                        whoseSide === "mySide"
                          ? "myGraveyard"
                          : "theirGraveyard"
                      )
                    }
                    card={card}
                  />
                ))}
              </div>
            </>
          )
        )
      }
    }

    return (
      <div
        id="GYDeck"
        className={whoseSide === "mySide" ? "deckAndGY" : "theirDeckAndGY"}
      >
        <div className="flexRow">
          <div
            className={`deck ${
              cardBack || opponentCardBack || "defaultCardBack"
            }`}
          >
            <div>{deck?.length}</div>
          </div>
          {whoseSide === "mySide" && artifacts?.length > 0 ? (
            <button
              className="biggerButton myFriendlyButton"
              onClick={changeGYDisplay}
            >
              Change Display
            </button>
          ) : null}
          <div
            id={whoseSide === "mySide" ? `myGYLocation` : null}
            className={`${expandGY ? "expandedGY" : "myGY"} ${
              whoseSide === "mySide" ? "myExpandedGY" : ""
            }`}
          >
            {artifacts?.length === 0
              ? renderGraveyardCards(graveyard, spellCast)
              : gyOrArtifacts
              ? renderGraveyardCards(graveyard, spellCast)
              : displayArtifacts()}
          </div>
          {whoseSide !== "mySide" && artifacts?.length > 0 ? (
            <button
              className="biggerButton theirNonFriendlyButton"
              onClick={changeGYDisplay}
            >
              Change Display
            </button>
          ) : null}
        </div>
      </div>
    )
  }

  const playerInfo = () => {
    return (
      <div id="playerInfo">
        <div id="playerName">
          {user.dev ? <span className="devTag">DEVELOPER</span> : null}
          <span className="userName">{player.userName}</span>
        </div>

        <ManaDisplay mana={currentMana} totalMana={mana} />

        {player.abilities ? (
          player.abilities.map((ability, index) => (
            <div
              id={ability === usedThisAbility ? "usedAbility" : null}
              key={`${ability}${index}`}
              onClick={() =>
                !playerAbilityClick ? null : castPlayerAbility(ability)
              }
              className="canClick"
            >
              <div className="abilityName">{newString(ability)}</div>
              <div className="abilityDescription">
                {player.abilityDescription[index]}
              </div>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
    )
  }

  function displayArtifacts() {
    if (artifacts?.length && !expandArtifacts) {
      const card = artifacts[0]
      return (
        <Card
          finalPosition={{}}
          expandArtifacts={expandArtifacts}
          cardStyle={user.usersettings?.[card.name]?.style}
          className="bottomSide"
          onClick={() =>
            clickLocation(
              card,
              0,
              whoseSide === "mySide" ? "myArtifacts" : "theirArtifacts"
            )
          }
          card={card}
          key={card.id}
        />
      )
    } else {
      return (
        artifacts?.length > 0 && (
          <>
            <span
              onClick={() =>
                setExpandMyArtifacts((expandArtifacts) => !expandArtifacts)
              }
              className="gameFontColors graveyardCloseButton"
            >
              X
            </span>
            <div style={{ display: "flex" }}>
              {artifacts.map((card, index) => (
                <Card
                  cardStyle={user.usersettings?.[card.name]?.style}
                  key={card.id}
                  className="bottomSide"
                  onClick={() =>
                    clickLocation(
                      card,
                      index,
                      whoseSide === "mySide" ? "myArtifacts" : "theirArtifacts"
                    )
                  }
                  card={card}
                />
              ))}
            </div>
          </>
        )
      )
    }
  }

  const boardDisplay = () => {
    if (whoseSide === "mySide") {
      return (
        <div>
          {renderExileZone()}
          <div id="MyBoard">{boardState()}</div>
          {playerState()}
          {handState()}
          <div className="deckAndGY">
            {functionButton()}
            {graveyardAndDeck()}
            {playerInfo()}
          </div>
        </div>
      )
    } else {
      return (
        <div>
          {renderExileZone()}
          <div id="TheirInfo">
            {graveyardAndDeck()}
            {handState()}
            {playerInfo()}
          </div>
          {playerState()}
          <div id="TheirBoard">{boardState()}</div>
        </div>
      )
    }
  }

  return (
    <div key={whoseSide === "mySide" ? "mySide" : "opponentSide"}>
      {boardDisplay()}
    </div>
  )
}

export default Board
