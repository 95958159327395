import { useContext, useEffect, useState } from "react"
import { GameContext } from "../../App"
import StatDisplay from "../../components/statsDisplay"

const CardStats = () => {
  const { user, socket, cards } = useContext(GameContext)
  const [cardStats, setCardStats] = useState([])

  useEffect(() => {
    if (socket.current) {
      socket.current.emit("request-card-stats")

      socket.current.on("receive-card-stats", (data) => {
        setCardStats(data)
      })
    }

    return () => {
      // socket.current.off("send-card-list")
      socket.current.off("receive-card-stats")
    }
  }, [])

  return (
    <>
      <div className="gameFontColors">
        <h1>Card Stats</h1>
        <div className="biggerText">
          <div className="biggerText">Your Win/Loss</div>
          {`Wins: ${user.wins} Losses: ${user.losses} Win Rate: ${
            user.wins + user.losses === 0
              ? "N/A"
              : `${Math.round((user.wins / (user.wins + user.losses)) * 100)}%`
          }`}
        </div>
        <StatDisplay stats={cardStats} cards={cards} user={user} />
      </div>
    </>
  )
}

export default CardStats
