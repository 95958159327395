import { GameContext } from "../App"
import { useContext, useState } from "react"
import CardList from "../components/cardList"
import { useNavigate } from "react-router-dom"
import Cardbacks from "../components/cardBackComponent"
import cardBacks from "../cardBacks/cardBacks.json"
import PointImage from "../background-images/pointLogo.png"
import authHook from "../customHooks/authHook"

const Store = () => {
  authHook()
  const { user, socket } = useContext(GameContext)
  const [purchasingPoints, setPurchasingPoints] = useState(false)
  const navigate = useNavigate()
  const focusCard = (card) => {
    navigate("/store/card/" + card.id)
  }
  const tabs = ["CardsTab", "CardBackTab"]
  const [activeTab, setActiveTab] = useState(tabs[0])

  const buyPoints = () => {
    setPurchasingPoints((purchasingPoints) => !purchasingPoints)
  }

  const buyCardback = (cardBack) => {
    socket.current.emit("buy-card-back", { email: user.email, cardBack })
  }

  return purchasingPoints ? (
    <div>
      <button className="biggerButton" onClick={buyPoints}>
        Return
      </button>
      <div>
        <stripe-buy-button
          buy-button-id="buy_btn_1O7qaaI8Giu6hwH7QIf7CqCp"
          publishable-key="pk_test_51O7QyWI8Giu6hwH7vpL2EFUEpQkyPekQOVQ4JBgzspJXbiUd8h7DoiK8CORRoT3IZklvZJAPxOrhOPGcMe4Ko0UV008iHo0EXX"
          customer-email={user.email}
        ></stripe-buy-button>
        <stripe-buy-button
          buy-button-id="buy_btn_1O7S0NI8Giu6hwH7ExPk0GcP"
          publishable-key="pk_test_51O7QyWI8Giu6hwH7vpL2EFUEpQkyPekQOVQ4JBgzspJXbiUd8h7DoiK8CORRoT3IZklvZJAPxOrhOPGcMe4Ko0UV008iHo0EXX"
          customer-email={user.email}
        ></stripe-buy-button>
        <stripe-buy-button
          buy-button-id="buy_btn_1O7qqSI8Giu6hwH7y2OVVul0"
          publishable-key="pk_test_51O7QyWI8Giu6hwH7vpL2EFUEpQkyPekQOVQ4JBgzspJXbiUd8h7DoiK8CORRoT3IZklvZJAPxOrhOPGcMe4Ko0UV008iHo0EXX"
          customer-email={user.email}
        ></stripe-buy-button>
        <stripe-buy-button
          buy-button-id="buy_btn_1O7qr8I8Giu6hwH7CgL7tTpq"
          publishable-key="pk_test_51O7QyWI8Giu6hwH7vpL2EFUEpQkyPekQOVQ4JBgzspJXbiUd8h7DoiK8CORRoT3IZklvZJAPxOrhOPGcMe4Ko0UV008iHo0EXX"
          customer-email={user.email}
        ></stripe-buy-button>
        <stripe-buy-button
          buy-button-id="buy_btn_1O7qrmI8Giu6hwH7DhoD2OMb"
          publishable-key="pk_test_51O7QyWI8Giu6hwH7vpL2EFUEpQkyPekQOVQ4JBgzspJXbiUd8h7DoiK8CORRoT3IZklvZJAPxOrhOPGcMe4Ko0UV008iHo0EXX"
          customer-email={user.email}
        ></stripe-buy-button>
        <stripe-buy-button
          buy-button-id="buy_btn_1O7qsGI8Giu6hwH7Hb3UJxGz"
          publishable-key="pk_test_51O7QyWI8Giu6hwH7vpL2EFUEpQkyPekQOVQ4JBgzspJXbiUd8h7DoiK8CORRoT3IZklvZJAPxOrhOPGcMe4Ko0UV008iHo0EXX"
          customer-email={user.email}
        ></stripe-buy-button>
        <stripe-buy-button
          buy-button-id="buy_btn_1O7qv5I8Giu6hwH7jmdqwu9S"
          publishable-key="pk_test_51O7QyWI8Giu6hwH7vpL2EFUEpQkyPekQOVQ4JBgzspJXbiUd8h7DoiK8CORRoT3IZklvZJAPxOrhOPGcMe4Ko0UV008iHo0EXX"
          customer-email={user.email}
        ></stripe-buy-button>
      </div>
    </div>
  ) : (
    <div>
      <div>
        <button
          className="biggerButton"
          onClick={(e) => {
            setActiveTab(tabs[0])
          }}
        >
          Cards
        </button>
        {
          <button
            className="biggerButton"
            onClick={(e) => {
              setActiveTab(tabs[1])
            }}
          >
            CardBacks
          </button>
        }
      </div>
      <div className={`collectionTab ${activeTab === tabs[0] ? "active" : ""}`}>
        <button className="biggerButton" onClick={buyPoints}>
          Buy Points
        </button>
        <CardList onCardClick={focusCard} />
      </div>
      <div className={`collectionTab ${activeTab === tabs[1] ? "active" : ""}`}>
        <div className="basicFlexDisplay">
          {cardBacks.map((cardBack, index) => (
            <div key={index} className="cardBackPageStyling">
              {
                <div
                  className={`${
                    user?.owned?.[cardBack] || cardBack === "defaultCardBack"
                      ? "cardBackOwned"
                      : ""
                  }`}
                >
                  <Cardbacks cardBack={cardBack} />
                  {!user?.owned?.[cardBack] &&
                    cardBack !== "defaultCardBack" && (
                      <div
                        className="pointDisplayCostCardBack"
                        onClick={() => buyCardback(cardBack)}
                      >
                        <img
                          className="pointImgStore"
                          src={PointImage}
                          alt="pointLogo"
                        />
                        <div>100</div>
                      </div>
                    )}
                </div>
              }
            </div>
          ))}
        </div>
      </div>
      <div></div>
    </div>
  )
}

export default Store
