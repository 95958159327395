import { useContext, useState, useEffect } from "react"
import { GameContext } from "../App"

const DeckPageMenu = ({
  createGame,
  joinRankedMatchmaking,
  joinNormalMatchmaking,
  socketConnected,
  deck,
  deckList,
  user,
  deckName,
  loadDeck,
  createDeck,
  deleteDeck,
  modifyDeck,
  importDeck,
  shareDeck,
  changeDeckNameFunction
}) => {
  const [filteredDeckList, setFilteredDeckList] = useState(deckList)
  const [prebuiltDecks, setPrebuiltDecks] = useState(false)
  const { socket, signedIn } = useContext(GameContext)

  useEffect(() => {
    if (joinNormalMatchmaking && joinRankedMatchmaking) {
      const filteredDecks = deckList.filter(
        (deck) => deck.cardList.length === 40
      )
      setFilteredDeckList(filteredDecks)
    } else {
      setFilteredDeckList(deckList)
    }
  }, [joinNormalMatchmaking, joinRankedMatchmaking, deckList])

  const swapDeckList = () => {
    if (!prebuiltDecks) {
      setPrebuiltDecks(true)
      socket.current.emit("load-decks", "cardsofmythology@gmail.com")
    } else {
      setPrebuiltDecks(false)
      socket.current.emit("load-decks", user.email)
    }
  }

  const checkModifyDeck = () => {
    const deckNameList = deckList.map((deck) => deck.name)
    return deckNameList.includes(deckName)
  }

  return (
    <div className="gamePageMenu">
      {user.email !== "cardsofmythology@gmail.com" && user.email && (
        <button onClick={swapDeckList} className="biggerButton">
          {prebuiltDecks ? "Load my decks" : "Load prebuilt decks"}
        </button>
      )}
      <h1 className="gameFontColors">{`${
        deck.length < 40 && deck.length !== 0 ? "Your deck needs 40 cards" : ""
      }`}</h1>
      <h1 className="gameFontColors">{`${
        !(createDeck && deleteDeck)
          ? "Select a Deck"
          : "Build or Modify your decks"
      }`}</h1>
      <div className="gamePageDeckList">
        {filteredDeckList.map((deck, index) => (
          <div
            key={index}
            className="biggerText gameFontColors gamePageDeckIcon"
            onClick={() => loadDeck(deck?.name)}
          >
            <span style={{ cursor: "pointer" }} onClick={changeDeckNameFunction}>{deck?.name}</span>
            <div
              key={deck?.name}
              className={`${
                deckName === deck?.name && deckName.length > 0
                  ? "deckIconSelectedDeck"
                  : ""
              } ${deck.cardList.length < 40 ? "invalidDeckBefore" : ""}`}
            >
              <div
                className={`${
                  user?.usersettings?.cardBack?.[deck?.name]
                    ? user.usersettings?.cardBack?.[deck?.name]
                    : "defaultCardBack"
                } deckSelectedGameIcon ${
                  deck.cardList.length < 40 ? "invalidDeck" : ""
                }`}
              ></div>
            </div>
          </div>
        ))}
      </div>
      {joinNormalMatchmaking && joinRankedMatchmaking && (
        <div>
          {/* <button
            className="biggerButton"
            disabled={!socketConnected || deck.length !== 40}
            onClick={joinNormalMatchmaking}
          >
            Join Normal Matchmaking
          </button> */}

          <button
            className="biggerButton"
            disabled={!socketConnected || deck.length !== 40}
            onClick={joinRankedMatchmaking}
          >
            Join Queue
          </button>
        </div>
      )}
      {createDeck && deleteDeck && shareDeck && importDeck && signedIn && (
        <div>
          {checkModifyDeck() && (
            <button onClick={() => modifyDeck(true)} className="biggerButton">
              Modify Deck
            </button>
          )}
          <button
            className="biggerButton"
            disabled={!socketConnected}
            onClick={createDeck}
          >
            Create Deck
          </button>

          <button
            className="biggerButton"
            disabled={!socketConnected}
            onClick={deleteDeck}
          >
            Delete Deck
          </button>
          <button onClick={importDeck} className="biggerButton">
            Import Deck
          </button>
          {checkModifyDeck() && (
            <button onClick={shareDeck} className="biggerButton">
              Share Deck
            </button>
          )}
        </div>
      )}
    </div>
  )
}

export default DeckPageMenu
