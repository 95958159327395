import React from "react"
import Card from "./card"
import Carousel from "./global/carousel"
import styled from "styled-components"
import Chart from "../components/manaCurveChart"

export default function Sideboard({
  gameDeck,
  gameSideBoard,
  addToSideBoard,
  addToDeck,
  startMulligan,
  theirGameDeck,
  theirGameSideBoard,
  playedFirst,
  user,
  opponentUser
}) {
  const [tab, setTab] = React.useState("mythics")

  return (
    <div id="sideboard-container">
      <ButtonContainer>
        <TabContainer>
          <Tab active={tab === "mythics"} onClick={() => setTab("mythics")}>
            Their Mythics
          </Tab>
          <Tab active={tab === "sideboard"} onClick={() => setTab("sideboard")}>
            Sideboard
          </Tab>
        </TabContainer>
        {playedFirst ? (
          <div className="gameFontColors biggerText">You play First</div>
        ) : (
          <div className="gameFontColors biggerText">You play Second</div>
        )}
        <button
          disabled={gameDeck.length !== 40}
          onClick={startMulligan}
          className="biggerButton glow-button"
        >
          Finished Sideboarding
        </button>
      </ButtonContainer>
      {tab === "mythics" ? (
        <div className="theirMythics">
          <Carousel>
            {[...theirGameDeck, ...theirGameSideBoard]
              .filter((card) => card.rarity === "mythic" || card.rarity === "omnipotent")
              .sort((a, b) => a.cost - b.cost)
              .map((card, index) => (
                <Card id="card" className="topSide" card={card} key={index} cardStyle={opponentUser.usersettings?.[card.name]?.style}/>
              ))}
          </Carousel>
        </div>
      ) : (
        <div className="sideboard">
          <Carousel>
            {gameSideBoard.map((card, index) => (
              <Card
                id="card"
                className="topSide"
                card={card}
                key={index}
                onClick={() => addToDeck(index)}
                cardStyle={user.usersettings?.[card.name]?.style}
              />
            ))}
          </Carousel>
        </div>
      )}

      <h3 style={{ marginBottom: "0px" }} className="gameFontColors">
        Deck: {gameDeck.length}/40
      </h3>
      <div className="yourDeck">
        <Carousel>
          {gameDeck.map((card, index) => (
            <Card
              id="card"
              className="topSide"
              card={card}
              key={index}
              onClick={() => addToSideBoard(index)}
              cardStyle={user.usersettings?.[card.name]?.style}
            />
          ))}
        </Carousel>
      </div>
      <Chart cards={gameDeck} />
    </div>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const TabContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
`
const Tab = styled.button`
  width: 150px;
  height: 50px;
  color: ${(p) => (p.active ? "black" : "white")};
  background: ${(p) => (p.active ? "white" : "rgba(0, 0, 0, 0.5)")};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: none;
  cursor: pointer;
`
