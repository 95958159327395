const GameResult = ({ gameObject: { date, gameId, win, opponent } }) => {
    const watchReplay = () => {
      // Find replay by gameId if saved
    }
    return (
      <div className="gameFontColors">
        <h1>Match Vs {opponent}</h1>
        <div>{win ? "Victory!" : "Defeat!"}</div>
        <div>{date}</div>
        <a onClick={watchReplay}>Replay Link</a>
      </div>
    );
  };
  
  export default GameResult;
  