import { useState, useEffect, useContext } from "react"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import DownloadIcon from "@mui/icons-material/Download"
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow"
import Chart from "../components/manaCurveChart"
import { GameContext } from "../App"
import CardList from "../components/cardList"
import { rarityIndex } from "../constants"
import deckFunctions from "../deckFunctions"
import Card from "../components/card"
import DeckPageMenu from "../components/deckPageMenu"
import authHook from "../customHooks/authHook"

const { reduceDeck } = deckFunctions

//Prebuilt decks need work

// Deck builder
const DeckBuilder = () => {
  authHook()
  const {
    deck,
    setDeck,
    sideBoard,
    setSideBoard,
    deckList,
    setDeckList,
    customDecksTrue,
    signedIn,
    deckName,
    setDeckName,
    user,
    cards,
    socket,
  } = useContext(GameContext)
  const [deckSearchID, setDeckSearchID] = useState("")
  const [oldDeckInformation, setOldDeckInformation] = useState({
    name: "",
    deck: [],
    sideboard: [],
  })
  const [saveDisabled, setSaveDisabled] = useState(false)
  const [creatingDeck, setCreatingDeck] = useState(false)
  const [deckNameSize, setDeckNameSize] = useState("")
  const [loadDeckBuilder, setLoadDeckBuilder] = useState(false)
  const [deckSideSelect, setDeckSideSelect] = useState(true)
  const [importingDeck, setImportingDeck] = useState(false)
  const [changeDeckName, setChangeDeckName] = useState(false)
  const [newDeckName, setNewDeckName] = useState("")

  // Loads card list on page render
  useEffect(() => {
    if (socket.current) {
      socket.current.on("reload-decks-response", () => {
        setLoadDeckBuilder(false)
        loadDecks()
        setSaveDisabled(false)
      })

      socket.current.on("share-deck-response", (data) => {
        console.log(data)
        navigator.clipboard.writeText(data)
        alert("Deck ID copied to clipboard!")
      })

      socket.current.on("import-deck-response", (data) => {
        if (data) {
          const importedDeckInformation = {
            name: data.name,
            deck: JSON.parse(data.deck),
            sideboard: JSON.parse(data.sideboard),
          }
          setOldDeckInformation(importedDeckInformation)
          setImportingDeck(false)
          setLoadDeckBuilder(true)
        }
      })
      loadDecks()
    }
    return () => {
      socket.current.off("import-deck-response")
      socket.current.off("share-deck-response")
      socket.current.off("reload-decks-response")
    }
  }, [signedIn, socket.current])

  useEffect(() => {
    if (oldDeckInformation.deck.length) {
      loadImportedDeck()
    }
  }, [oldDeckInformation])
  // Adds a card to your current deck
  function addCardToDeck(card) {
    if (card.token || card.prerelease) return
    let tempDeck = [...deck]
    let tempSideBoard = [...sideBoard]
    if (tempDeck.length < 40 && deckSideSelect) {
      const cardCount =
        [...tempDeck, ...tempSideBoard].reduce((acc, ele) => {
          acc[ele.name] = acc[ele.name] ? acc[ele.name] + 1 : 1
          return acc
        }, {})[card.name] || 0
      if (process.env.NODE_ENV === "development" ? false : true) {
        if (cardCount < 3 && card.rarity === "common") {
          setDeck([{ ...card }, ...tempDeck].sort((a, b) => a.cost - b.cost))
        }
        if (cardCount < 2 && card.rarity === "legendary") {
          setDeck([{ ...card }, ...tempDeck].sort((a, b) => a.cost - b.cost))
        }
        if (cardCount < 1 && card.rarity === "mythic") {
          setDeck([{ ...card }, ...tempDeck].sort((a, b) => a.cost - b.cost))
        }
        if (
          !tempDeck.find((cardInDeck) => cardInDeck.rarity === "omnipotent") &&
          card.rarity === "omnipotent"
        ) {
          setDeck([{ ...card }, ...tempDeck].sort((a, b) => a.cost - b.cost))
        }
      } else {
        setDeck([{ ...card }, ...tempDeck].sort((a, b) => a.cost - b.cost))
      }
    } else {
      if (sideBoard.length < 15) {
        const cardCount =
          [...tempDeck, ...tempSideBoard].reduce((acc, ele) => {
            acc[ele.name] = acc[ele.name] ? acc[ele.name] + 1 : 1
            return acc
          }, {})[card.name] || 0

        if (process.env.NODE_ENV === "development" ? false : true) {
          if (cardCount < 3 && card.rarity === "common") {
            setSideBoard(
              [{ ...card }, ...tempSideBoard].sort((a, b) => a.cost - b.cost)
            )
          }
          if (cardCount < 2 && card.rarity === "legendary") {
            setSideBoard(
              [{ ...card }, ...tempSideBoard].sort((a, b) => a.cost - b.cost)
            )
          }
          if (cardCount < 1 && card.rarity === "mythic") {
            setSideBoard(
              [{ ...card }, ...tempSideBoard].sort((a, b) => a.cost - b.cost)
            )
          }
        } else {
          setSideBoard(
            [{ ...card }, ...tempSideBoard].sort((a, b) => a.cost - b.cost)
          )
        }
      }
    }
  }

  // Removes a card from your current deck
  function removeCardFromDeck(index) {
    const newDeck = [...deck]
    newDeck.splice(index, 1)
    setDeck([...newDeck])
  }
  function removeCardFromSideBoard(index) {
    const newSideBoard = [...sideBoard]
    newSideBoard.splice(index, 1)
    setSideBoard(newSideBoard)
  }

  function loadImportedDeck() {
    const newDeck = []
    const newSideBoard = []
    for (let i = 0; i < oldDeckInformation.deck.length; i++) {
      const card = cards.find(
        (cardNumber) => cardNumber.id === oldDeckInformation.deck[i]
      )
      newDeck.push({ ...card })
    }
    for (let i = 0; i < oldDeckInformation.sideboard.length; i++) {
      const card = cards.find(
        (cardNumber) => cardNumber.id === oldDeckInformation.sideboard[i]
      )
      newSideBoard.push({ ...card })
    }
    setDeck([...newDeck].sort((a, b) => a.cost - b.cost))
    setDeckName(oldDeckInformation.name)
    setSideBoard([...newSideBoard])
  }

  // Saves your deck for future use and to play
  function saveDeck(deck, name, sideBoard, e, newDeckName) {
    if (e) e.preventDefault()
    if (newDeckName && deckList.find((deck) => deck.name === newDeckName)) {
      setNewDeckName("")
      setChangeDeckName(false)
      alert("That Deck already exists!")
      return
    }
    if (
      signedIn &&
      // deck.length === 40 &&
      name.length &&
      !saveDisabled &&
      (process.env.NODE_ENV === "development"
        ? true
        : user.userName !== "COMDemoAccount")
    ) {
      const stringDeck = JSON.stringify(deck.map((card) => card.id))
      const stringSideBoard = JSON.stringify(sideBoard.map((card) => card.id))
      socket.current.emit("save-deck", {
        email: user.email,
        name,
        stringDeck,
        stringSideBoard,
        user: user,
        newDeckName: newDeckName ? newDeckName : null,
      })
      setSaveDisabled(true)
      if (newDeckName) {
        setNewDeckName("")
        setChangeDeckName(false)
      }
    }
  }
  // Loads your saved decks for you to pick and choose one
  function loadDecks(click) {
    if (signedIn) {
      if (!click) {
        socket.current.emit("load-decks", user.email)
      } else {
        socket.current.emit("load-decks", "cardsofmythology@gmail.com")
      }
    } else {
      socket.current.emit("load-decks", "cardsofmythology@gmail.com")
    }
  }

  //Loads a deck and sets it as your game deck
  function loadDeck(deckName) {
    const oldDeckInformation = deckList.find(
      (nameOfDeck) => deckName === nameOfDeck.name
    )
    const newDeck = []
    const newSideBoard = []
    for (let i = 0; i < oldDeckInformation.cardList.length; i++) {
      const card = cards.find(
        (cardNumber) => cardNumber.id === oldDeckInformation.cardList[i]
      )
      newDeck.push({ ...card })
    }
    for (let i = 0; i < oldDeckInformation.sideBoard.length; i++) {
      const card = cards.find(
        (cardNumber) => cardNumber.id === oldDeckInformation.sideBoard[i]
      )
      newSideBoard.push({ ...card })
    }
    setDeck([...newDeck].sort((a, b) => a.cost - b.cost))
    setDeckName(oldDeckInformation.name)
    setSideBoard(newSideBoard)
  }
  // Allows you to permanently delete a deck
  function deleteDeck() {
    if (signedIn) {
      if (localStorage.getItem(`defaultDeck${user.userName}`) === deckName)
        localStorage.removeItem(`defaultDeck${user.userName}`)
      socket.current.emit("delete-deck", { email: user.email, name: deckName })
    } else {
      localStorage.removeItem(deckName)
      localStorage.removeItem(deckName + "sideBoard")
      loadDecks()
    }
    setDeck([])
    setDeckName("")
    setSideBoard([])
  }

  function shareDeck() {
    socket.current.emit("share-deck", { email: user.email, name: deckName })
  }

  function importDeck() {
    setImportingDeck(true)
  }

  const deckListNames = () => {
    return deckList.map((deck) => deck.name)
  }

  const uniqueDeckName = () => {
    const uniqueNames = deckList.map((deck) => deck.name)
    return !uniqueNames.includes(deckName)
  }

  const createDeck = () => {
    if (deckList.length > 14) return

    if (!creatingDeck) {
      setCreatingDeck(true)
      setDeckName("")
    } else if (
      deckName.length > 0 &&
      deckName.length < 32 &&
      uniqueDeckName()
    ) {
      setCreatingDeck(false)
      setDeckNameSize("")
      setLoadDeckBuilder(true)
      setDeck([])
      setSideBoard([])
    } else {
      setDeckNameSize("Name must be unique and 1-32 characters")
    }
  }

  const handleInputChange = (e) => {
    e.preventDefault()
    const inputValue = e.target.value
    const truncatedValue = inputValue.slice(0, 32)
    setDeckSearchID(truncatedValue)
  }

  const changeDeckNameFunction = () => {
    setChangeDeckName(true)
  }

  const cancelDeckNameChange = () => {
    setChangeDeckName(false)
    setNewDeckName("")
  }

  return (
    <div>
      {!loadDeckBuilder && (
        <>
          <DeckPageMenu
            socketConnected={socket.current?.connected ? true : false}
            deck={deck}
            deckList={deckList}
            user={user}
            deckName={deckName}
            loadDeck={loadDeck}
            deleteDeck={deleteDeck}
            createDeck={createDeck}
            modifyDeck={setLoadDeckBuilder}
            importDeck={importDeck}
            shareDeck={shareDeck}
            changeDeckNameFunction={changeDeckNameFunction}
          />
          {creatingDeck && (
            <div className="createDeckPopout">
              <h1 className="gameFontColors deckCreateWarning">
                {deckNameSize}
              </h1>
              <div className="deckBuilderInputBar">
                <input
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      createDeck()
                    }
                  }}
                  autoFocus
                  value={deckName}
                  onChange={(e) => {
                    e.preventDefault()
                    const inputValue = e.target.value
                    const truncatedValue = inputValue.slice(0, 25)
                    setDeckName(truncatedValue)
                  }}
                  type="text"
                  name="search"
                  required
                />
                <span>Deck Name</span>
                <i></i>
              </div>
              <button
                className="biggerButton createNewDeckButton"
                onClick={createDeck}
              >
                Create New Deck
              </button>
              <button
                onClick={() => setCreatingDeck(false)}
                className="biggerButton createDeckCancel"
              >
                Cancel
              </button>
            </div>
          )}
          {importingDeck && (
            <div className="createDeckPopout">
              <h1 className="gameFontColors deckCreateWarning">
                {deckNameSize}
              </h1>
              <div className="deckBuilderInputBar">
                <input
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      socket.current.emit("import-deck", deckSearchID)
                    }
                  }}
                  autoFocus
                  value={deckSearchID}
                  onChange={handleInputChange}
                  type="text"
                  name="search"
                  required
                />
                <span>Import ID</span>
                <i></i>
              </div>
              <button
                className="biggerButton importNewDeckButton"
                onClick={() => socket.current.emit("import-deck", deckSearchID)}
              >
                Import Deck
              </button>
              <button
                onClick={() => setImportingDeck(false)}
                className="biggerButton importDeckCancel"
              >
                Cancel
              </button>
            </div>
          )}
          {changeDeckName && (
            <div className="createDeckPopout">
              <h1 className="gameFontColors deckCreateWarning">
                {deckNameSize}
              </h1>
              <div className="deckBuilderInputBar">
                <input
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      newDeckName.length > 0 &&
                        saveDeck(deck, deckName, sideBoard, e, newDeckName)
                    }
                  }}
                  autoFocus
                  value={newDeckName}
                  onChange={(e) => {
                    e.preventDefault()
                    const inputValue = e.target.value
                    const truncatedValue = inputValue.slice(0, 25)
                    setNewDeckName(truncatedValue)
                  }}
                  type="text"
                  name="search"
                  required
                />
                <span>New Deck Name</span>
                <i></i>
              </div>
              <button
                className="biggerButton changeDeckNameButton"
                onClick={(e) =>
                  newDeckName.length > 0 &&
                  saveDeck(deck, deckName, sideBoard, e, newDeckName)
                }
              >
                Change Deck Name
              </button>
              <button
                onClick={cancelDeckNameChange}
                className="biggerButton changeDeckNameCancel"
              >
                Cancel
              </button>
            </div>
          )}
        </>
      )}
      {loadDeckBuilder && (
        <>
          <div id="deckBuilder">
            <div style={{ width: "100%" }}>
              <CardList
                deckListNames={deckListNames()}
                deckList={reduceDeck(deck)}
                newSideBoard={reduceDeck(sideBoard)}
                onCardClick={addCardToDeck}
                deckbuilder={true}
                saveDeck={saveDeck}
                setLoadDeckBuilder={setLoadDeckBuilder}
              />
            </div>
            <div className="spaceCalculate">
              <Chart cards={deck} />
              <div className="currentDeck">
                <h2 className="currDeckTitle">
                  <span
                    className="deckSelectorSpan"
                    onClick={() => setDeckSideSelect(true)}
                  >
                    <DoubleArrowIcon className="deckLeftArrowIcon" />
                    Deck
                  </span>
                  <span className="deckSideboardSeperator">|</span>
                  <span
                    className="sideboardSelectorSpan"
                    onClick={() => setDeckSideSelect(false)}
                  >
                    Sideboard
                    <DoubleArrowIcon className="rightArrowIcon" />
                  </span>
                </h2>
                <div className="currentDeckLength">
                  # Cards: {deckSideSelect ? deck.length : sideBoard.length}/
                  {deckSideSelect ? 40 : 15}
                </div>
                {reduceDeck(deckSideSelect ? deck : sideBoard).map(
                  (card, index) => (
                    <div
                      key={index}
                      className={`biggerText currentDeckCard ${
                        reduceDeck([...deck, ...sideBoard]).find(
                          (cardInList) => cardInList.name === card.name
                        )?.count > rarityIndex[card.rarity]
                          ? "deckLimitExceeded"
                          : ""
                      }`}
                      onClick={() =>
                        deckSideSelect
                          ? removeCardFromDeck(card.indicies[0])
                          : removeCardFromSideBoard(card.indicies[0])
                      }
                    >
                      {card.count}x {card.cost}-{card.name}
                      <div className="redIcon">
                        <DeleteForeverIcon />
                      </div>
                      {
                        <Card
                          className={"hideCard"}
                          card={card}
                          cardStyle={user.usersettings?.[card.name]?.style}
                        />
                      }
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default DeckBuilder
