const deckFunctions = {
  reduceDeck: (cards) => {
    const reducedDeckArray = []
    let card
    const newCards = cards.reduce((acc, card, index) => {
      if (!acc[card.name]) {
        acc[card.name] = { ...card }
        acc[card.name].count = 1
        acc[card.name].indicies = [index]
      } else {
        acc[card.name].count += 1
        acc[card.name].indicies.push(index)
      }
      return acc
    }, {})
    for (card in newCards) {
      reducedDeckArray.push(newCards[card])
    }
    return reducedDeckArray
  },
}

export default deckFunctions
