import { FaBars } from "react-icons/fa"
import { NavLink as Link } from "react-router-dom"
import styled from "styled-components"

export const Nav = styled.nav`
  display: flex;
  justify-content: center;
  background-image: linear-gradient(rgb(54, 54, 54), rgb(24, 21, 21));
  background-repeat: no-repeat;
  font-size: 25px;
  font-family: arial;
  height: 58.75px;

  @media screen and (max-width: 799px), screen and (max-height: 599px) {
    display: none;
    height: calc(97vh * 2);
    position: absolute;
    width: 103%;
  }
`

export const NavLink = styled(Link)`
  color: #9a6529;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  cursor: pointer;
  padding: 5px;
  position: relative; /* Add this to create a positioning context */

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px; /* Adjust the underline height as needed */
    background: #e0953f; /* Underline color on hover */
    bottom: 0;
    left: 50%; /* Start from the middle */
    transform: translateX(-50%) scaleX(0); /* Initially, no width and centered */
    transform-origin: 50%; /* Start from the middle */
    transition: transform 0.5s ease; /* Transition effect on hover */
  }

  &:hover {
    color: #e0953f; /* Text color on hover */
    &::after {
      transform: translateX(-50%) scaleX(1); /* Expand the underline on hover */
    }
  }

  &.active {
    color: #ff8800;
    &::after {
      background: #ff8800; /* Adjust the underline color for the active link */
      transform: translateX(-50%) scaleX(1);
    }
  }

  @media screen and (max-width: 799px), screen and (max-height: 599px) {
    font-size: 40px;
  }
`

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2%;
  flex-grow: 1;

  @media screen and (max-width: 799px), screen and (max-height: 599px) {
    flex-direction: column;
  }

  @media (min-width: 800px) and (max-width: 1060px) {
    gap: 1%;
    margin-left: 233px;
  }
`