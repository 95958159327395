import { Link } from "react-router-dom"
import Card from "../components/card"

function statsDisplay({ stats, cards, user }) {
  const statsCopy = [...stats]

  const cardHighestWinrates = () => {
    const sortedWinRates = statsCopy.sort((a, b) => {
      const winrateA = a.win === 0 ? 0 : a.win / (a.win + a.loss)
      const winrateB = b.win === 0 ? 0 : b.win / (b.win + b.loss)

      if (winrateA > winrateB) {
        return -1 // Negative value to move 'a' before 'b'
      } else if (winrateA < winrateB) {
        return 1 // Positive value to move 'b' before 'a'
      } else {
        // If win rates are equal, sort by totalTimesPlayed in ascending order
        return b.totalTimesPlayed - a.totalTimesPlayed
      }
    })
    return sortedWinRates
  }

  const cardLowestWinrates = () => {
    const sortedWinRates = statsCopy.sort((a, b) => {
      const winrateA = a.win === 0 ? 0 : a.win / (a.win + a.loss)
      const winrateB = b.win === 0 ? 0 : b.win / (b.win + b.loss)

      if (winrateA > winrateB) {
        return -1 // Negative value to move 'a' before 'b'
      } else if (winrateA < winrateB) {
        return 1 // Positive value to move 'b' before 'a'
      } else {
        // If win rates are equal, sort by totalTimesPlayed in ascending order
        return a.totalTimesPlayed - b.totalTimesPlayed
      }
    })
    return sortedWinRates
  }

  const playRateOfCards = () => {
    return statsCopy.sort((a, b) => b.totalTimesPlayed - a.totalTimesPlayed)
  }

  const botFive = cardLowestWinrates().slice(cardLowestWinrates().length - 5)
  const topFive = cardHighestWinrates().slice(0, 5)
  const cardPlayRates = playRateOfCards().slice(0, 5)
  const allCardsWinrates = cardLowestWinrates().slice(0)
  const unplayedCards = cards.filter((card) => {
    if (stats.find((cardInStats) => cardInStats.card === card.name)) {
      return false
    }
    return true
  })

  function getCardByName(name) {
    return cards.find((allCards) => allCards.name === name)
  }

  return (
    <div>
      <div className="biggerText">Top 5 Cards</div>
      <div className="statDisplay">
        {topFive.map((cardStats) => (
          <div key={`${cardStats}${cardStats.card}topFive`}>
            <Link
              to={"/cards/" + getCardByName(cardStats.card)?.id}
              relative="path"
            >
              <Card
                id={"card"}
                key={
                  cards.find((allCards) => allCards.name === cardStats.card)
                    ?.id +
                  "topFive" +
                  cardStats.card
                }
                card={cards.find(
                  (allCards) => allCards.name === cardStats.card
                )}
                cardStyle={user.usersettings?.[cardStats.card]?.style}
              />
            </Link>
            <div className="space-around">
              <div>
                {cardStats.win === 0
                  ? "0%" // Handle card with only losses
                  : cardStats.loss === 0
                  ? "100%" // Handle card with only wins
                  : `${Math.round(
                      (cardStats.win / (cardStats.win + cardStats.loss)) * 100
                    )}%`}
              </div>
              <div>Played: {cardStats.totalTimesPlayed}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="biggerText">Bottom 5 Cards</div>
      <div className="statDisplay">
        {botFive.map((cardStats) => (
          <div key={`botFiv${cardStats.card}FiveBot${cardStats}`}>
            <Link
              to={"/cards/" + getCardByName(cardStats.card)?.id}
              relative="path"
            >
              <Card
                id={"card"}
                card={cards.find(
                  (allCards) => allCards.name === cardStats.card
                )}
                key={
                  cards.find((allCards) => allCards.name === cardStats.card)
                    ?.id +
                  "botFive" +
                  cardStats.card
                }
                cardStyle={user.usersettings?.[cardStats.card]?.style}
              />
            </Link>
            <div className="space-around">
              <div>
                {cardStats.win === 0
                  ? "0%" // Handle card with only losses
                  : cardStats.loss === 0
                  ? "100%" // Handle card with only wins
                  : `${Math.round(
                      (cardStats.win / (cardStats.win + cardStats.loss)) * 100
                    )}%`}
              </div>
              <div>Played: {cardStats.totalTimesPlayed}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="biggerText">Most played cards</div>
      <div className="statDisplay">
        {cardPlayRates.map((cardStats) => (
          <div key={`${cardStats.card}playRates${cardStats}`}>
            <Link
              to={"/cards/" + getCardByName(cardStats.card)?.id}
              relative="path"
            >
              <Card
                id={"card"}
                key={
                  cards.find((allCards) => allCards.name === cardStats.card)
                    ?.id +
                  "playRates" +
                  cardStats.card
                }
                card={cards.find(
                  (allCards) => allCards.name === cardStats.card
                )}
                cardStyle={user.usersettings?.[cardStats.card]?.style}
              />
            </Link>
            <div className="space-around">
              <div>
                {cardStats.win === 0
                  ? "0%" // Handle card with only losses
                  : cardStats.loss === 0
                  ? "100%" // Handle card with only wins
                  : `${Math.round(
                      (cardStats.win / (cardStats.win + cardStats.loss)) * 100
                    )}%`}
              </div>
              <div>Played: {cardStats.totalTimesPlayed}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="biggerText">All card stats</div>
      <div className="statDisplay overFlowScroll">
        {allCardsWinrates.map((cardStats) => (
          <div key={`${cardStats.card}${cardStats}winRates`}>
            <Link
              to={"/cards/" + getCardByName(cardStats.card)?.id}
              relative="path"
            >
              <Card
                id={"card"}
                key={
                  cards.find((allCards) => allCards.name === cardStats.card)
                    ?.id +
                  "winRates" +
                  cardStats.card
                }
                card={cards.find(
                  (allCards) => allCards.name === cardStats.card
                )}
                cardStyle={user.usersettings?.[cardStats.card]?.style}
              />
            </Link>
            <div className="space-around">
              <div>
                {cardStats.win === 0
                  ? "0%" // Handle card with only losses
                  : cardStats.loss === 0
                  ? "100%" // Handle card with only wins
                  : `${Math.round(
                      (cardStats.win / (cardStats.win + cardStats.loss)) * 100
                    )}%`}
              </div>
              <div>Played: {cardStats.totalTimesPlayed}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="biggerText">Unplayed Cards</div>
      <div className="statDisplay overFlowScroll">
        {unplayedCards
          .filter((card) => !card.token)
          .map((card) => (
            <div key={`${card}${card?.id}${card.name}${card.mythology}`}>
              <Link to={"/cards/" + card?.id} relative="path">
                <Card
                  id={"card"}
                  card={card}
                  key={card?.id + "unplayedCard" + card.name}
                  cardStyle={user.usersettings?.[card.name]?.style}
                />
              </Link>
            </div>
          ))}
      </div>
    </div>
  )
}

export default statsDisplay
