import { useContext, useEffect, useState } from "react"
import { GameContext } from "../../App"
import cardBacks from "../../cardBacks/cardBacks.json"
import Cardbacks from "../../components/cardBackComponent"
import { useNavigate } from "react-router-dom"
import CardList from "../cardList"
import GamePageMenu from "../deckPageMenu"

const Collection = () => {
  const { user, socket, cards, deck, deckList, setDeck, setDeckName, setSideBoard, deckName } = useContext(GameContext)
  const [updateUserDisabled, setUpdateUserDisabled] = useState(false)
  const [ownedStyles, setOwnedStyles] = useState([])
  const tabs = ["CardsTab", "CardBackTab"]
  const [activeTab, setActiveTab] = useState(tabs[0])
  const navigate = useNavigate()

  useEffect(() => {
    setOwnedStyles(user.owned)
  }, [])

  function changeCardBack(input) {
    if ((ownedStyles?.[input] || input === "defaultCardBack") && deckName) {
      if (
        !updateUserDisabled &&
        (process.env.NODE_ENV === "development"
          ? true
          : user.userName !== "COMDemoAccount")
      ) {
        // setUpdateUserDisabled(true)
        socket.current.emit("update-user", {
          ...user,
          type: "changeCardBack",
          cardBack: input,
          deckName: deckName
        })
      }
    }
  }

  const normalCardArts = () => {
    if (
      !updateUserDisabled &&
      (process.env.NODE_ENV === "development"
        ? true
        : user.userName !== "COMDemoAccount")
    ) {
      // setUpdateUserDisabled(true)
      socket.current.emit("update-user", {
        ...user,
        type: "normalCardArts",
      })
    }
  }

  const fullArtCards = () => {
    if (
      !updateUserDisabled &&
      (process.env.NODE_ENV === "development"
        ? true
        : user.userName !== "COMDemoAccount")
    ) {
      // setUpdateUserDisabled(true)
      socket.current.emit("update-user", {
        ...user,
        type: "fullArtCard",
      })
    }
  }

  const threeDCards = () => {
    if (
      !updateUserDisabled &&
      (process.env.NODE_ENV === "development"
        ? true
        : user.userName !== "COMDemoAccount")
    ) {
      // setUpdateUserDisabled(true)
      socket.current.emit("update-user", {
        ...user,
        type: "threeDCard",
      })
    }
  }

  const onClickNavigateToDetail = (card) => {
    navigate("/cards/" + card.id, { state: { from: "/profile" } })
  }

  function loadDeckGamePage(deckName) {
    const oldDeckInformation = deckList.find(
      (nameOfDeck) => deckName === nameOfDeck.name
    )
    const newDeck = []
    const newSideBoard = []
    for (let i = 0; i < oldDeckInformation.cardList.length; i++) {
      const card = cards.find(
        (cardNumber) => cardNumber.id === oldDeckInformation.cardList[i]
      )
      newDeck.push({ ...card })
    }
    for (let i = 0; i < oldDeckInformation.sideBoard.length; i++) {
      const card = cards.find(
        (cardNumber) => cardNumber.id === oldDeckInformation.sideBoard[i]
      )
      newSideBoard.push({ ...card })
    }
    setDeck([...newDeck].sort((a, b) => a.cost - b.cost))
    setDeckName(oldDeckInformation.name)
    setSideBoard(newSideBoard)
  }

  return (
    <>
      <div className="gameFontColors">
        <div className="biggerText">
          Welcome to Cards of Mythology {user.userName}
        </div>
        <button className="biggerButton" onClick={normalCardArts}>
          Normal Card Art
        </button>
        <button className="biggerButton" onClick={fullArtCards}>
          Full Art Cards
        </button>
        <button className="biggerButton" onClick={threeDCards}>
          3d Art Cards
        </button>

        <div>
          <h1>Collection</h1>
          <button
            className="biggerButton"
            onClick={(e) => {
              setActiveTab(tabs[0])
            }}
          >
            Cards
          </button>
          <button
            className="biggerButton"
            onClick={(e) => {
              setActiveTab(tabs[1])
            }}
          >
            CardBacks
          </button>
        </div>
        <div
          className={`collectionTab ${activeTab === tabs[0] ? "active" : ""}`}
        >
          <CardList onCardClick={onClickNavigateToDetail} />
        </div>
        <div
          className={`collectionTab ${activeTab === tabs[1] ? "active" : ""}`}
        >
          <GamePageMenu
          loadDeckGamePage={loadDeckGamePage}
          socket={socket.current?.connected ? true : false}
          deck={deck}
          deckList={deckList}
          user={user}
          deckName={deckName}
        />
          <div className="biggerText">Select a card back!</div>
          <div className="basicFlexDisplay">
            {cardBacks.map((cardBack, index) => {
              if (ownedStyles?.[cardBack] || cardBack === "defaultCardBack") {
                return (
                  <Cardbacks
                    key={index}
                    cardBack={cardBack}
                    selectedCardBack={user?.usersettings?.cardBack}
                    onClick={() => changeCardBack(cardBack)}
                  />
                )
              } else {
                return null // Render nothing if the card back is not owned
              }
            })}
          </div>
        </div>
      </div>
    </>
  )
}
export default Collection
