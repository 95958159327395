import React from "react"
import styled from "styled-components"
import { throttle } from "lodash"

export default function Carousel(props) {
  const ref = React.useRef(null)
  const [leftShadow, setLeftShadow] = React.useState(false)
  const [rightShadow, setRightShadow] = React.useState(false)

  const addShadowOnScroll = throttle((item, directions = ["left", "right"]) => {
    const directionFunctions = {
      left: () => {
        if (!item.scrollLeft && item.clientWidth <= item.scrollWidth) {
          setLeftShadow(false)
        } else {
          setLeftShadow(true)
        }
      },
      right: () => {
        if (item.scrollLeft + item.clientWidth >= item.scrollWidth) {
          setRightShadow(false)
        } else {
          setRightShadow(true)
        }
      },
    }

    directions.forEach((direction) => {
      directionFunctions[direction]()
    })
  }, 50)

  React.useEffect(() => {
    const carouselWindow = ref.current
    if (ref.current) addShadowOnScroll(carouselWindow, ["left", "right"])
  }, [addShadowOnScroll])

  return (
    <CarouselContainer>
      {/* <div id='carouselLeft' onClick={props.leftClick}>
        <div id='carouselLeftArrow'></div>
      </div>
      <div id='carouselRight' onClick={props.rightClick}>
        <div id='carouselRightArrow'></div>
      </div> */}
      <div
        id="carouselWindow"
        ref={ref}
        onScroll={({ target }) => addShadowOnScroll(target, ["left", "right"])}
      >
        <div id="carouselContent">
          <div
            className={`carousel-beginning ${leftShadow && "end-shadow-left"}`}
          />
          {props.children}
          <div
            className={`carousel-ending ${rightShadow && "end-shadow-right"}`}
          />
        </div>
      </div>
    </CarouselContainer>
  )
}

const CarouselContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  #carouselLeft {
    position: absolute;
    top: 0;
    left: 0;
    width: 10%;
    height: 50%;
    cursor: pointer;
    #carouselLeftArrow {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 3px dashed green;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid white;
    }
  }
  #carouselRight {
    position: relative;
    top: 0;
    right: 0;
    width: 10%;
    height: 100%;
    cursor: pointer;
    #carouselRightArrow {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 3px dashed green;
      width: 10%;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid white;
    }
  }
  #carouselWindow {
    overflow-y: hidden;
    overflow-x: auto;
    scroll-behavior: smooth;
    position: relative;
    height: 100%;
    width: 100%;
  }

  #carouselContent {
    position: absolute;
    top: 0;
    height: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    background: rgba(0, 0, 0, 0.5);
  }

  .carousel-beginning {
    position: sticky;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 100;
  }

  .carousel-ending {
    position: sticky;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 100;
  }

  .end-shadow-right {
    position: sticky;
    top: 0;
    right: 0;
    height: 100%;
  }

  .end-shadow-right::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
    width: 75px;
    height: 100%;
    z-index: 100;
    pointer-events: none;
  }
  .end-shadow-left::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
    width: 75px;
    height: 100%;
    z-index: 100;
    pointer-events: none;
  }
`
