import Card from "./card"
import cardFilter from "../cardFilter"

//Pages to display X cards at a time
const CardPages = (props) => {
  const maxCount = (card) => {
    const deckCard = (props.deckList || props.deck || []).find(
      (cardInDeck) => card.name === cardInDeck.name
    )

    const sideboardCard = (props.sideBoardList || props.sideBoard || []).find(
      (cardInSideboard) => cardInSideboard.name === card.name
    )

    if (deckCard?.count || sideboardCard?.count) {
      if (deckCard?.count || sideboardCard?.count) {
        return Number(deckCard?.count || 0) + Number(sideboardCard?.count || 0)
        // return "attacked"
      } else return ""
      //  else {
      // return "casting"
      // }
      // } else if (deckCard || sideboardCard) {
      //   // return "casting"
    }
  }

  const rarityCopies = (type) => {
    if (type === "common") return 3
    if (type === "legendary") return 2
    if (type === "mythic" || type === "omnipotent") return 1
  }

  return (
    <div id="cardList">
      {cardFilter(props)
        .slice((props.page - 1) * props.cardCount, props.page * props.cardCount)
        .map((card) =>
          props.deckbuilder && maxCount(card) > 0 ? (
            <div
              style={{ position: "relative", cursor: "pointer" }}
              key={`${card.id}${card.index}`}
            >
              <div onClick={() => props.onClick(card)}
                style={{
                  fontSize: "x-large",
                  position: "absolute",
                  top: "35%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 3,
                  opacity: "none",
                  width: 224,
                  textAlign: "center",
                  color: "white",
                }}
              >
                <span className={maxCount(card) >= rarityCopies(card.rarity)  ? "" : "applyYellow"}>{maxCount(card)}</span>{`/${rarityCopies(card.rarity)} COPIES`}
              </div>
              <div className={`${maxCount(card) > 0 ? "maxCopies" : ""}`}>
                <Card
                  cardStyle={props.user.usersettings?.[card.name]?.style}
                  card={card}
                  onClick={() => props.onClick(card)}
                />
              </div>
            </div>
          ) : (
            <Card
              cardStyle={props.user.usersettings?.[card.name]?.style}
              key={`${card.id}${card.index}`}
              card={card}
              onClick={() => props.onClick(card)}
            />
          )
        )}
    </div>
  )
}

export default CardPages
