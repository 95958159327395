import { useState, useEffect, useContext } from "react"
import cardPopUps from "../data/cardPopUp.json"
import { useQuery } from "react-query"
import animateCard from "./animateCard"
import keywordMessage from ".././keywordMessages.json"
import keywords from ".././keyword.json"

const Card = (props) => {
  const [localStaticImageData, setLocalStaticImageData] = useState(null)
  const [localPopupImageData, setLocalPopupImageData] = useState(null)
  const [zIndexPriority, setZIndexPriority] = useState(false)
  const [popUpImg, setPopUpImg] = useState(false)
  const { data, error, isLoading } = useQuery(
    ["staticImage", props?.card?.image],
    () => fetchStaticImage(props?.card?.image)
  )
  const popupQueryResponse = useQuery(["popupImage", props?.card?.image], () =>
    fetchPopupImage(props?.card?.image)
  )
  const dataTwo = popupQueryResponse.data
  const errorTwo = popupQueryResponse.error
  const isLoadingTwo = popupQueryResponse.isLoading

  useEffect(() => {
    if (props?.card) {
      if (props.cardStyle === "threeDCard") {
        import(`../images/${props.card.image}.png`)
          .then(() => {
            setPopUpImg(true)
          })
          .catch((err) => {
            // console.error(err)
          })
      }
    }
  }, [])

  const cardClass = () => {
    const userCardDesign = props?.cardStyle
    if (userCardDesign === "threeDCard" && popUpImg) {
      return "threeDCard"
    } else if (
      userCardDesign === "fullArtCard" ||
      (userCardDesign === "threeDCard" && !popUpImg)
    ) {
      return "fullArtCard"
    } else {
      return "normalCard"
    }
  }

  async function fetchStaticImage() {
    // Check if props and props.card are defined
    if (!props || !props.card) {
      throw new Error("Props or props.card is undefined")
    }

    let imageString = props.card.image

    // Check if imageString includes "_gif_" to determine file extension
    const isGif = imageString.includes("_gif_")

    // Dynamically import the image module with appropriate file extension
    const imageModule = await import(
      `../images/${imageString}${isGif ? ".gif" : ".jpg"}`
    )

    return imageModule.default
  }

  const StaticImageComponent = () => {
    if (isLoading && !localStaticImageData) {
      return <p>Loading...</p>
    }

    if (error && !localStaticImageData) {
      return <p>Error loading image: {error.message}</p>
    }

    return (
      <img
        src={data || localStaticImageData}
        alt={props.card.image}
        className={cardClass() !== "normalCard" ? "cover-image" : ""}
        style={
          cardClass() === "normalCard" ? { width: "100%", height: "100%" } : {}
        }
      />
    )
  }

  async function fetchPopupImage() {
    try {
      const imageModule = await import(`../images/${props.card.image}.png`)
      return imageModule.default
    } catch (err) {
      const imageModule = await import(`../images/${props.card.image}.jpg`)
      return imageModule.default
    }
  }

  const PopupImageComponent = () => {
    if (isLoadingTwo && !localPopupImageData) {
      return <p>Loading...</p>
    }

    if (errorTwo && !localPopupImageData) {
      return <p>Error loading image: {error.message}</p>
    }

    return (
      <img
        src={dataTwo || localPopupImageData}
        alt={props.card.image}
        className="character"
        id={props.card.image}
      />
    )
  }

  if (!props.card) return <div>A Bug Occurred</div>

  function parseText(text) {
    const parts = text.split(
      new RegExp(
        `(${keywords
          .map((keyword) => keyword.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&"))
          .join("|")})`,
        "gi"
      )
    )

    // Map the words and wrap all the key words into spans with mouseovers
    return parts.map((part, index) => {
      if (keywordMessage[part.toLowerCase()]) {
        return (
          <span
            className="spanReplacement"
            onMouseEnter={() => {
              setZIndexPriority(true)
            }}
            onMouseLeave={() => {
              setZIndexPriority(false)
            }}
            key={index}
          >
            {part}
            <div
              id={
                props.card?.abilityText === part
                  ? "hideAbilityDisplay"
                  : "hideDisplay"
              }
            >
              {keywordMessage[part.toLowerCase()]}
            </div>
          </span>
        )
      } else if (
        cardPopUps.find((cardName) => cardName.name === part) ||
        (part === "Corpses" &&
          cardPopUps.find((cardName) => cardName.name === "Corpse")) ||
        (part === "Fiends" &&
          cardPopUps.find((cardName) => cardName.name === "Fiend")) ||
        (part === "Worshippers" &&
          cardPopUps.find((cardName) => cardName.name === "Worshipper")) ||
        ((part === "Pegasus" || part === "Hercules, Son of Zeus") &&
          cardPopUps.find(
            (cardName) => cardName.name === "Hercules, Skyward Sovereign"
          )) ||
        (part === "First Piece" &&
          cardPopUps.find((card) => card.name === "First piece of R'lyeh")) ||
        (part === "Second Piece" &&
          cardPopUps.find((card) => card.name === "Second piece of R'lyeh")) ||
        (part === "Third Piece" &&
          cardPopUps.find((card) => card.name === "Third piece of R'lyeh")) ||
        (part === "Fourth Piece" &&
          cardPopUps.find((card) => card.name === "Fourth piece of R'lyeh")) ||
        (part === "Fifth Piece" &&
          cardPopUps.find((card) => card.name === "Fifth piece of R'lyeh")) ||
        (part === "Demon of Darkness" &&
          cardPopUps.find(
            (card) => card.name === "Apophis, Demon of Darkness"
          )) ||
        (part === "Curses of Affliction" &&
          cardPopUps.find((card) => card.name === "Curse of Affliction")) ||
        (part === "Curses of Binding" &&
          cardPopUps.find((card) => card.name === "Curse of Binding")) ||
          (part === "Curses of Subjugation" &&
            cardPopUps.find((card) => card.name === "Curse of Subjugation"))
      ) {
        return (
          <span
            className="spanCardReplacement"
            onMouseEnter={() => {
              setZIndexPriority(true)
            }}
            onMouseLeave={() => {
              setZIndexPriority(false)
            }}
          >
            {part}
            <div className="hideCardFromView">
              <Card
                id="card"
                card={
                  cardPopUps.find((cardName) => cardName.name === part) ||
                  (part === "Corpses" &&
                    cardPopUps.find(
                      (cardName) => cardName.name === "Corpse"
                    )) ||
                  (part === "Fiends" &&
                    cardPopUps.find((cardName) => cardName.name === "Fiend")) ||
                  (part === "Worshippers" &&
                    cardPopUps.find(
                      (cardName) => cardName.name === "Worshipper"
                    )) ||
                  ((part === "Pegasus" || part === "Hercules, Son of Zeus") &&
                    cardPopUps.find(
                      (cardName) =>
                        cardName.name === "Hercules, Skyward Sovereign"
                    )) ||
                  (part === "First Piece" &&
                    cardPopUps.find(
                      (card) => card.name === "First piece of R'lyeh"
                    )) ||
                  (part === "Second Piece" &&
                    cardPopUps.find(
                      (card) => card.name === "Second piece of R'lyeh"
                    )) ||
                  (part === "Third Piece" &&
                    cardPopUps.find(
                      (card) => card.name === "Third piece of R'lyeh"
                    )) ||
                  (part === "Fourth Piece" &&
                    cardPopUps.find(
                      (card) => card.name === "Fourth piece of R'lyeh"
                    )) ||
                  (part === "Fifth Piece" &&
                    cardPopUps.find(
                      (card) => card.name === "Fifth piece of R'lyeh"
                    )) ||
                  (part === "Demon of Darkness" &&
                    cardPopUps.find(
                      (card) => card.name === "Apophis, Demon of Darkness"
                    )) ||
                  (part === "Curses of Affliction" &&
                    cardPopUps.find(
                      (card) => card.name === "Curse of Affliction"
                    )) ||
                  (part === "Curses of Binding" &&
                    cardPopUps.find(
                      (card) => card.name === "Curse of Binding"
                    )) ||
                    (part === "Curses of Subjugation" &&
                      cardPopUps.find((card) => card.name === "Curse of Subjugation"))
                }
              />
            </div>
          </span>
        )
      } else if (part.includes("#")) {
        return part.replace(
          "#",
          props.card?.prophecyCounter || props.card?.construction
        )
      }
      return part
    })
  }

  const cardName = () => {
    let name = props.card.name
    name = name.split("")
    if (name.find((character) => character === "(")) {
      return name
        .slice(
          0,
          name.findIndex((character) => character === "(")
        )
        .join("")
    } else {
      return name.join("")
    }
  }

  const iconMessage = {
    attacked: "This creature can attack",
    frozen: "This creature cannot attack",
    provoke: "This creature must be attacked first",
    elusive: "This creature blocks the next instance of damage",
  }

  const icons = () => {
    return props.icons?.map((icon, index) => {
      return (
        <span key={index} className={`${icon.class} material-symbols-outlined`}>
          {icon.name}
          <div id="hideDisplay">{iconMessage[icon.class]}</div>
        </span>
      )
    })
  }

  function isClickToCast() {
    return (
      props.card?.activatesOn?.indexOf("noTarget") != -1 ||
      props.card?.activatesOn?.indexOf("selfChoice") != -1
    )
  }
  function isClickToTarget() {
    return (
      props.card?.activatesOn?.indexOf("friendly") != -1 ||
      props.card?.activatesOn?.length == 0
    )
  }
  return (
    <div
      id={props.card.id}
      className={`${
        props.className === "hideCard" ? "hideCard" : ""
      } parentAnchor ${
        cardClass() === "threeDCard" && !zIndexPriority ? "add3dHover" : ""
      } ${zIndexPriority ? "zIndexBoost" : ""} 
      ${props.isCasting && isClickToCast() ? "clicktocast" : ""} 
      ${props.isCasting && isClickToTarget() ? "clicktotarget" : ""}
      `}
      style={
        props.rotation
          ? {
              transform: `rotate(${props.rotation}deg) translateX(${props.translate}px)`,
            }
          : props.style
      }
      onClick={props.onClick}
    >
      <div id={props.id}>
        <div className={cardClass()}>
          <div className="cardImageHolder">
            {cardClass() === "normalCard" && StaticImageComponent()}
          </div>
          {cardClass() !== "normalCard" && (
            <div className="wrapper">{StaticImageComponent()}</div>
          )}
        </div>
      </div>

      <div className="cardName">
        <div
          className={`${
            props.card?.name?.length > 21
              ? "largerShrunkCardName "
              : props.card.name.length > 17
              ? "shrunkCardName "
              : ""
          }zIndex30`}
        >
          {cardName()}
        </div>
        <div className="zIndex30">{props.card.cost}</div>
      </div>

      <div className={`belowTextCardBox`}>
        <div className="cardTyping zIndex30">
          <div>
            <div>
              {props.card.type[0].toUpperCase() + props.card.type.slice(1)}-
              {props.card.class[0].toUpperCase() + props.card.class.slice(1)}
            </div>
          </div>
          <div>
            {props.card.rarity[0].toUpperCase() + props.card.rarity.slice(1)}
          </div>
        </div>
        <div
          className={icons()?.length ? "modifiedCardTextBox" : "cardTextBox"}
        >
          {parseText(props.card.text)}
        </div>
        <div className="iconDiv">{icons()}</div>
      </div>

      {cardClass() === "threeDCard" && PopupImageComponent()}

      <div className="mythosAndStats">
        <div>
          {props.card.mythology[0].toUpperCase() +
            props.card.mythology.slice(1)}
        </div>
        <div id="cardStats">
          <div>{props.card.attack}</div>
          {typeof props.card.attack === "number" || props.card.attack === "X"
            ? "/"
            : null}
          <div id={props.card.damageTaken ? "damaged" : null}>
            {props.card.attack === "X"
              ? "X"
              : typeof props.card.attack === "number"
              ? props.card.defense - props.card.damageTaken
              : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default animateCard(Card)
