import { useContext, useEffect, useState } from "react"
import { GameContext } from "../App"
import { useNavigate } from "react-router-dom"
import { Checkbox } from "@mui/material"
import { config } from "../constants"
import ResetPassword from "../components/Auth/resetPassword"

const Authentication = () => {
  const navigate = useNavigate()
  const {
    user,
    signedIn,
    socket,
    signUpMessages,
    signUpRedirect,
    setSignUpRedirect,
  } = useContext(GameContext)
  const [userName, setUserName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [signUpTrue, setSignUpTrue] = useState(false)
  const [stayLoggedIn, setStayLoggedIn] = useState(false)
  const [resetTrue, setResetTrue] = useState(false)

  useEffect(() => {
    if (signUpRedirect) {
      setSignUpRedirect(false)
      signIn(email, password)
    }
  }, [signUpRedirect, email, password])

  useEffect(() => {
    if (signedIn) {
      navigate("/profile")
    }
  }, [signedIn])

  function signUp(userName, email, password, confirmPassword, e) {
    e.preventDefault()
    socket.current.emit("sign-up", {
      userName,
      email,
      password,
      confirmPassword,
    })
  }

  const clearSignedIn = async () => {
    try {
      const url = config.url.API_URL

      const response = await fetch(`${url}/authenticate/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: user.email }),
        credentials: "include", // Include credentials (cookies) in the request
      })
      if (response.ok) {
        console.log("Logout Successful auth")
      } else {
        console.error("Logout failed")
      }
    } catch (error) {
      console.error("Error during sign-out:", error)
    }
  }

  async function signIn(email, password, e) {
    if (e) e.preventDefault()
    if (stayLoggedIn) {
      try {
        const url = config.url.API_URL

        const response = await fetch(`${url}/authenticate`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
          credentials: "include", // Include credentials (cookies) in the request
        })
        if (response.ok) {
          console.log("Success")
        } else {
          console.error("Sign-in failed")
        }
      } catch (error) {
        console.error("Error during sign-in:", error)
      }
    } else {
      clearSignedIn()
    }
    socket.current.emit("sign-in", { email, password })
  }

  return (
    <div id="authentication">
      {signUpMessages.map((msg, index) => (
        <div className="gameFontColors biggerText textAlign" key={index}>
          {msg}
        </div>
      ))}
      {!signUpTrue && !resetTrue && !signedIn && (
        <>
          <div className="formPage">
            <div className="box">
              <form>
                <h2>Sign In</h2>
                <div className="inputBox">
                  <input
                    type="text"
                    required
                    onChange={(e) => {
                      e.preventDefault()
                      setEmail(e.target.value)
                    }}
                  />
                  <span>Email</span>
                  <i></i>
                </div>
                <div className="inputBox">
                  <input
                    type="password"
                    required
                    onChange={(e) => {
                      e.preventDefault()
                      setPassword(e.target.value)
                    }}
                  />
                  <span>Password</span>
                  <i></i>
                </div>
                <input
                  type="submit"
                  value="Login"
                  onClick={(e) => signIn(email, password, e)}
                />
                <label className="checkBoxLabel">
                  <Checkbox
                    checked={stayLoggedIn}
                    onChange={() =>
                      setStayLoggedIn((stayLoggedIn) => !stayLoggedIn)
                    }
                  ></Checkbox>
                  Stay Logged In
                </label>
                <div className="links">
                  <a onClick={() => setResetTrue(true)} href="#">
                    Forgot Password?
                  </a>
                  <a onClick={() => setSignUpTrue(true)} href="#">
                    Sign Up
                  </a>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
      {!signedIn && signUpTrue && (
        <div className="formPage">
          <div className="boxSignUp">
            <form autoComplete="off">
              <h2>Sign Up</h2>
              <div className="inputBox">
                <input
                  type="text"
                  required
                  onChange={(e) => {
                    e.preventDefault()
                    setUserName(e.target.value)
                  }}
                />
                <span>Username</span>
                <i></i>
              </div>
              <div className="inputBox">
                <input
                  type="text"
                  required
                  onChange={(e) => {
                    e.preventDefault()
                    setEmail(e.target.value)
                  }}
                />
                <span>Email</span>
                <i></i>
              </div>
              <div className="inputBox">
                <input
                  type="password"
                  required
                  onChange={(e) => {
                    e.preventDefault()
                    setPassword(e.target.value)
                  }}
                />
                <span>Password</span>
                <i></i>
              </div>
              <div className="inputBox">
                <input
                  type="password"
                  required
                  onChange={(e) => {
                    e.preventDefault()
                    setConfirmPassword(e.target.value)
                  }}
                />
                <span>Confirm Password</span>
                <i></i>
              </div>
              <input
                type="submit"
                value="Create Account"
                onClick={(e) =>
                  signUp(userName, email, password, confirmPassword, e)
                }
              />
            </form>
          </div>
        </div>
      )}
      {!signedIn && resetTrue && <ResetPassword />}
    </div>
  )
}

export default Authentication
