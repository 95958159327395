import React, { useEffect, useState, useContext } from "react"
import { GameContext } from "../App"
import { useLocation, useNavigate } from "react-router-dom"
import cardFilter from "../cardFilter"
import CardPages from "./cardPages"
const CardList = ({
  deckListNames,
  deckList,
  onCardClick,
  newSideBoard,
  deckbuilder = false,
  saveDeck,
  setLoadDeckBuilder,
}) => {
  const { cards, user, deck, deckName, sideBoard } = useContext(GameContext)

  const navigate = useNavigate()
  const location = useLocation()

  const [page, setPage] = useState(1)
  const [inputSearchTerm, setInputSearchTerm] = useState("")
  const [mythologyList, setMythologyList] = useState([])
  const [costList, setCostList] = useState([])
  const [rarityList, setRarityList] = useState([])
  const [selectedMythology, setSelectedMythology] = useState("All")
  const [selectedCost, setSelectedCost] = useState("All")
  const [selectedRarity, setSelectedRarity] = useState("All")
  const [cardCount, setCardCount] = useState(0)
  const [cardRecount, setCardRecount] = useState(false)

  useEffect(() => {
    // Function to update the card count based on window width
    const updateCardCount = () => {
      const mainContainer = document.getElementById("container")
      const availableSpace = mainContainer?.offsetWidth || 0

      const otherDivs = document.querySelectorAll(".spaceCalculate")

      const otherSpace = Array.from(otherDivs).map((element) => {
        const styles = getComputedStyle(element)
        const marginLeft = parseFloat(styles.marginLeft)
        const marginRight = parseFloat(styles.marginRight)
        return element.offsetWidth + marginLeft + marginRight
      })

      const cardWidth = 230 // Width of each card

      // Check for scrollbar width
      const hasScrollbar =
        document.documentElement.clientWidth < window.innerWidth
      const scrollbarWidth = hasScrollbar
        ? window.innerWidth - document.documentElement.clientWidth
        : 0

      const maxCards = Math.floor(
        (availableSpace -
          scrollbarWidth -
          otherSpace.reduce((acc, curr) => acc + curr, 0)) /
          cardWidth
      )
      setCardRecount(true)
      setCardCount(maxCards * 3)
    }

    // Call the function on component mount
    updateCardCount()

    // Listen for window resize events and update the card count
    window.addEventListener("resize", updateCardCount)

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateCardCount)
    }
  }, [cards])

  useEffect(() => {
    if (cardRecount) {
      const cardContainer = document.getElementById("cardList")
      const availableSpace = cardContainer?.offsetWidth
      const cardWidth = 230 // Width of each card
      const maxCards = Math.floor(availableSpace / cardWidth)
      setCardCount(maxCards * 3)
      setCardRecount(false)
    }
  }, [cardRecount])

  const applyFilterWithoutHistory = (newFilter) => {
    const searchParams = new URLSearchParams(newFilter).toString()
    navigate(
      {
        pathname: location.pathname, // Keeps the current path
        search: `?${searchParams}`,
      },
      { replace: true }
    )
  }

  useEffect(() => {
    applyFilterWithoutHistory(buildFilter())
  }, [inputSearchTerm, selectedCost, selectedMythology, selectedRarity, page])

  // useEffect(() => {
  //   if(deckListNames?.includes(deckName)) setInputSearchTerm("mydeck")
  // }, [deckName])

  useEffect(() => {
    setMythologyList(["All", ...mythologies()])
    setCostList(["All", ...manaCosts()])
    setRarityList(["All", "Omnipotent", "Mythic", "Legendary", "Common", "Token"])

    //Check params to see if we need to initialize a search
    const query = new URLSearchParams(location.search)

    const searchTerm = query.get("search") || ""
    const mythology = query.get("myth") || "All"
    const cost = parseInt(query.get("cost")) || "All"
    const rarity = query.get("rarity") || "All"
    const pageQstr = parseInt(query.get("page")) || 1

    setInputSearchTerm(searchTerm)
    setSelectedMythology(mythology)
    setSelectedCost(cost)
    setSelectedRarity(rarity)
    setPage(pageQstr)
  }, [cards])

  function incrementPage() {
    if (
      page <
      Math.ceil(
        cardFilter({
          cards,
          selectedMythology,
          selectedCost,
          selectedRarity,
          inputSearchTerm,
          deck,
          sideBoard,
        }).length / cardCount
      )
    ) {
      setPage((page) => page + 1)
    }
  }

  function decrementPage() {
    if (page !== 1) {
      setPage((page) => page - 1)
    }
  }

  const mythologies = () => {
    const mythologySet = new Set()
    cards.forEach((card) => {
      mythologySet.add(card.mythology)
    })

    return Array.from(mythologySet)
  }

  const manaCosts = () => {
    const costSet = new Set()
    cards.forEach((card) => {
      costSet.add(card.cost)
    })

    return Array.from(costSet)
  }

  const handleSelectionChangeMythos = (event) => {
    setSelectedMythology(event.target.value)
    setPage(1)
  }

  const handleSelectionChangeCost = (event) => {
    setSelectedCost(event.target.value)
    setPage(1)
  }

  const handleSelectionChangeRarity = (event) => {
    setSelectedRarity(event.target.value)
    setPage(1)
  }

  const buildFilter = () => {
    return {
      search: inputSearchTerm,
      rarity: selectedRarity,
      cost: selectedCost,
      myth: selectedMythology,
      page: page,
    }
  }

  const searchOptions = ["All", "Current Deck", "Sacrifice", "Tokens", "Prerelease"]
  const changeHelperFunction = (event) => {
    const key = {
      All: "",
      "Current Deck": "mydeck",
      Sacrifice: "sacrifice",
      Tokens: "token",
      Prerelease: "prerelease"
    }

    setInputSearchTerm(key[event.target.value])
    setPage(1)
  }

  const handleInputChange = (event) => {
    const key = {
      all: "",
      mydeck: "Current Deck",
      sacrifice: "Sacrifice",
      token: "Tokens",
    }

    const inputBar = document.querySelector(".cardSearchBar")

    const inputValueLowercase = event.target.value.toLowerCase()
    setInputSearchTerm(event.target.value)
    setPage(1)

    const selectedValue = key[inputValueLowercase]

    if (selectedValue) {
      const index = Object.values(key).indexOf(selectedValue)
      inputBar.selectedIndex = index
    } else {
      inputBar.selectedIndex = 0
    }
  }

  return (
    <div>
      <div className="cardListOptionsBox">
        <select className="cardSearchBar" onChange={changeHelperFunction}>
          {searchOptions.map((option) => (
            <option
              key={option}
              value={option}
              selected={inputSearchTerm === option}
            >
              {option}
            </option>
          ))}
        </select>
        <div className="inputSearchBar">
          <input
            value={inputSearchTerm}
            onChange={handleInputChange}
            // className="cardSearchBar"
            type="text"
            name="search"
            required
          />
          <span>Search Cards</span>
          <i></i>
        </div>
        <select
          className="cardSearchBar"
          onChange={handleSelectionChangeMythos}
        >
          {mythologyList.map((mythology) => (
            <option
              key={mythology}
              value={mythology}
              selected={selectedMythology === mythology}
            >
              {mythology[0].toUpperCase() + mythology.slice(1)}
            </option>
          ))}
        </select>
        <select className="cardSearchBar" onChange={handleSelectionChangeCost}>
          {costList.map((cost) => (
            <option key={cost} value={cost} selected={selectedCost === cost}>
              {cost}
            </option>
          ))}
        </select>
        <select
          className="cardSearchBar"
          onChange={handleSelectionChangeRarity}
        >
          {rarityList.map((rarity) => (
            <option
              key={rarity}
              value={rarity}
              selected={selectedRarity === rarity}
            >
              {rarity[0].toUpperCase() + rarity.slice(1)}
            </option>
          ))}
        </select>
        {saveDeck && (
          <button
            className="biggerButton saveDeckButton"
            onClick={(e) => saveDeck(deck, deckName, sideBoard, e)}
          >
            Save Deck
            {deck.length < 40 && (
              <span id="hideDisplay" className="gameFontColors">
                Your deck needs {40 - deck.length} more{" "}
                {deck.length === 39 ? "card" : "cards"} before it can be played
              </span>
            )}
          </button>
        )}
        {setLoadDeckBuilder && (
          <button
            onClick={() => setLoadDeckBuilder(false)}
            className="biggerButton"
          >
            Exit
          </button>
        )}
      </div>
      <div>
        <CardPages
          inputSearchTerm={inputSearchTerm}
          selectedMythology={selectedMythology}
          selectedCost={selectedCost}
          selectedRarity={selectedRarity}
          page={page}
          cards={cards}
          user={user}
          cardCount={cardCount}
          onClick={(card) => {
            onCardClick(card, buildFilter())
          }}
          deckList={deckList}
          sideBoardList={newSideBoard}
          deck={deck}
          sideBoard={sideBoard}
          deckbuilder={deckbuilder}
        />
        <button className="biggerButton" onClick={() => decrementPage()}>
          Previous Page
        </button>
        <button className="biggerButton" onClick={() => incrementPage()}>
          Next Page
        </button>
        <span className="biggerText gameFontColors"> {page}</span>
      </div>
    </div>
  )
}

export default CardList
