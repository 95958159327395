import { GameContext } from "../../App"
import { useContext, useEffect, useState } from "react"

const Leaderboard = () => {
  const { socket } = useContext(GameContext)
  const [leaderboard, setLeaderboard] = useState([])

  useEffect(() => {
    if (socket.current) {
      socket.current.emit("send-leaderboard-info")

      socket.current.on("info-leaderboard-send", (data) => {
        // Sort the data first by MMR and then by wins
        const sortedLeaderboard = data.sort((a, b) => {
          if (b.mmr === a.mmr) {
            return b.wins - a.wins // If MMR is the same, sort by wins
          }
          return b.mmr - a.mmr // Sort by MMR
        })

        setLeaderboard(sortedLeaderboard)
      })
    }

    return () => {
      socket.current.off("info-leaderboard-send")
    }
  }, [])

  return (
    <div className="leaderboardContainer">
      <h1 className="gameFontColors infoText">Leaderboard</h1>
      <table className="leaderboardTable">
        <thead>
          <tr>
            <th>Rank</th>
            <th>MMR</th>
            <th>Username</th>
            <th>Wins</th>
            <th>Losses</th>
          </tr>
        </thead>
        <tbody>
          {leaderboard.map((user, index) => (
            <tr key={user.userId}>
              <td>{index + 1}</td>
              <td>{user.mmr}</td>
              <td>{user.userName}</td>
              <td>{user.wins}</td>
              <td>{user.losses}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Leaderboard
