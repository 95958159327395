const cardFilter = (props) => {
  const humanClasses = [
    "human",
    "demigod",
    "cultist",
    "shapeshifter",
    "nephilim",
    "vampire",
    "werewolf",
  ]
  const monsterClasses = ["monster", "giant", "shapeshifter", "werewolf"]
  const angelClasses = [
    "angel",
    "archangel",
    "valkyrie",
    "seraphim",
    "nephilim",
  ]
  const godClasses = ["god", "demigod", "titan"]
  const undeadClasses = ["undead", "vampire"]

  return props.cards.filter((card) => {
    const searchTerm = props.inputSearchTerm.toLowerCase()
    const cardClass = card.class.toLowerCase()
    const cardName = card.name.toLowerCase()
    const cardText = card.text.toLowerCase()
    const cardRarity = card.rarity.toLowerCase()
    const cardType = card.type.toLowerCase()
    const cardMythology = card.mythology.toLowerCase()
    const selectedMythology = props.selectedMythology.toLowerCase()
    const selectedCost = Number(props.selectedCost)
    const selectedRarity = props.selectedRarity.toLowerCase()

    return (
      ((cardName.includes(searchTerm) ||
        cardText.includes(searchTerm) ||
        cardRarity.includes(searchTerm) ||
        cardType.includes(searchTerm) ||
        cardClass.includes(searchTerm) ||
        (searchTerm === "human" && humanClasses.includes(cardClass)) ||
        (searchTerm === "monster" && monsterClasses.includes(cardClass)) ||
        (searchTerm === "angel" && angelClasses.includes(cardClass)) ||
        (searchTerm === "god" && godClasses.includes(cardClass)) ||
        (searchTerm === "undead" && undeadClasses.includes(cardClass)) ||
        cardMythology.includes(searchTerm)) &&
        (props.selectedMythology === "All" ||
          cardMythology === selectedMythology) &&
        (props.selectedCost === "All" || Number(card.cost) === selectedCost) &&
        (props.selectedRarity === "All" || cardRarity === selectedRarity) &&
        !card.token &&
        !card.prerelease) ||
      (searchTerm === "token" && card.token) ||
      (props.selectedRarity === "Token" && card.token) ||
      (searchTerm === "prerelease" && card.prerelease) ||
      (searchTerm === "mydeck" &&
        [...props.deck, ...props.sideBoard].find(
          (cardInDeck) => cardInDeck.name === card.name
        )) ||
      (searchTerm === "sacrifice" && cardText.includes("Soul Harvest"))
    )
  })
}

export default cardFilter
