import React, { useContext, useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { config } from "../../constants"
import { GameContext } from "../../App"

const SideMenu = () => {
  const { user, setUser, setSignedIn, socket, setSignUpMessages } =
    useContext(GameContext)
  const navigate = useNavigate()
  const clearSignedIn = async () => {
    try {
      const url = config.url.API_URL

      const response = await fetch(`${url}/authenticate/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: user.email }),
        credentials: "include", // Include credentials (cookies) in the request
      })
      if (response.ok) {
        navigate("/")
        console.log("Logout Successful")
      } else {
        console.error("Logout failed")
      }
    } catch (error) {
      console.error("Error during sign-out:", error)
    }
  }

  const logout = () => {
    setSignedIn(false)
    setUser({ userName: "Guest" })
    clearSignedIn()
    setSignUpMessages([])
    socket.current.emit("sign-out")
  }

  return (
    <>
      <div className={"side-menu-container sticky spaceCalculate"}>
        <div>
          <ul className="navMenu side-menu-options">
            <NavLink className="navLink" to="/profile/collection">
              Collection
            </NavLink>
            <NavLink className="navLink" to="/profile/cardstats">
              Card Stats
            </NavLink>
            <NavLink className="navLink" to="/profile/account">
              Account
            </NavLink>
            <NavLink className="navLink" to="/profile/replay">
              Replays
            </NavLink>
            <NavLink className="navLink" to="/profile/matchhistory">
              Match History
            </NavLink>
            <NavLink className="navLink" to="/profile/leaderboard">
              Leaderboard
            </NavLink>
            <NavLink className="navLink" to="/profile/settings">
              Settings
            </NavLink>
          </ul>
          <div>
            <a className="logout navLink" onClick={logout}>
              Logout
            </a>
          </div>
        </div>
      </div>
    </>
  )
}
export default SideMenu
