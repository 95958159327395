import React, { useState, useEffect } from "react"

const animateCard = (WrappedComponent) => {
  return ({ finalPosition, card, ...props }) => {
    const [position, setPosition] = useState({ display: "none" })
    props.card = card
    useEffect(() => {
      if (card?.casting) {
        card.casting = false
        if (card?.type === "creature" || card?.type === "spellcaster") {
          // Get the element with id "myBoard"
          const myBoard = document.getElementById("myBoard")

          // Get the dimensions of the element
          const boardRect = myBoard.getBoundingClientRect()

          // Calculate the vertical distance between the center of the element and the center of the screen
          const elementCenterY = boardRect.top + boardRect.height / 2
          const screenHeight = window.innerHeight
          const screenCenterY = screenHeight / 2
          const verticalDistance = Math.abs(screenCenterY - elementCenterY)

          // Assuming you have the reference to the card element
          const cardInBoard = document.getElementById(`${card.id}`) // Replace "card-id" with the actual id of the card element

          // Check if myBoard is an ancestor of the card element
          const isChildOfMyBoard = myBoard.contains(cardInBoard)
          // Center the card based on how many on board
          const numberOfChildren = isChildOfMyBoard
            ? myBoard.children.length - 1
            : document.getElementById("theirBoard").children.length - 1
          const offCenterOffSet = numberOfChildren * 115

          setPosition({
            transition: "all 1.5s ease",
            zIndex: 100,
            top: isChildOfMyBoard ? -verticalDistance : verticalDistance,
            right: offCenterOffSet,
            transform: "scale(1.75)",
          })
          setTimeout(() => {
            setPosition({ right: "0px", top: "0px" }) // Change position after showing in center
            setTimeout(() => {
              setPosition({})
            }, 1500)
          }, 1250) // Delay to show the card in the center first
        } else if (card?.token) {
          // Get the width of the screen
          const screenWidth = document.getElementById('container').offsetWidth

          // Get the width of the element with id "exileZone"
          const exileZoneWidth =
            document.getElementById("exileZone").offsetWidth

          // Calculate the position to center the element
          const centerPosition = (screenWidth / 2) + (exileZoneWidth / 2)

          setPosition({
            transition: "all 1.5s ease",
            zIndex: 100,
            top: "0px",
            left: centerPosition,
            transform: "scale(1.75)",
          })

          // Uncomment and adjust the timeout as needed
          setTimeout(() => {
            setPosition({ left: "0px", top: "0px" }); // change position after showing in center
            setTimeout(() => {
              setPosition({});
            }, 1500);
          }, 1250); // Delay to show the card in the center first
        } else {
          // Get the element with id "myBoard"
          const myGY = document.getElementById("myGYLocation")

          // Get the dimensions of the element
          const gyRect = myGY.getBoundingClientRect()

          // Calculate the vertical distance between the center of the element and the center of the screen
          const elementCenterY = gyRect.top + gyRect.height / 2
          const screenHeight = window.innerHeight
          const screenCenterY = screenHeight / 2
          const verticalDistance = Math.abs(screenCenterY - elementCenterY)

          // Assuming you have the reference to the card element
          const cardInBoard = document.getElementById(`${card.id}`) // Replace "card-id" with the actual id of the card element

          // Check if myBoard is an ancestor of the card element
          const isChildOfMyBoard = myGY.contains(cardInBoard)

          setPosition({
            transition: "all 1.5s ease",
            zIndex: 100,
            top: isChildOfMyBoard
              ? -verticalDistance * 1.2
              : verticalDistance * 0.8,
            left:
              (document.getElementById("myBoard").offsetWidth -
                document.getElementById("GYDeck").offsetWidth) /
              2,
            transform: "scale(1.75)",
          })
          setTimeout(() => {
            setPosition({ zIndex: 100, left: "0px", top: "0px" }) // Change position after showing in center
            setTimeout(() => {
              setPosition({})
            }, 1500)
          }, 1250) // Delay to show the card in the center first
        }
      } else {
        setPosition({})
      }
    }, [card, finalPosition])

    return <WrappedComponent style={position} {...props} />
  }
}

export default animateCard
