import { useContext } from "react"
import { Routes, Route } from "react-router-dom"
import SideMenu from "./sideMenu"
import Account from "./account"
import CardStats from "./cardstats"
import Replay from "./replay"
import MatchHistory from "./matchhistory"
import Leaderboard from "./leaderboard"
import Settings from "./settings"
import { GameContext } from "../../App"
import Collection from "./collection"
import authHook from "../../customHooks/authHook"

const Profile = () => {
  authHook()
  const { signedIn } = useContext(GameContext)

  return (
    <>
      <div style={{ display: "flex" }}>
        {signedIn && <SideMenu />}
        <div className="profile-content">
          <Routes>
            <Route path="/" element={<Collection />} />
            <Route path="account" element={<Account />} />
            <Route path="cardstats" element={<CardStats />} />
            <Route path="collection" element={<Collection />} />
            <Route path="replay" element={<Replay />} />
            <Route path="matchhistory" element={<MatchHistory />} />
            <Route path="leaderboard" element={<Leaderboard />}/>
            <Route path="settings" element={<Settings/>}/>
          </Routes>
        </div>
      </div>
    </>
  )
}

export default Profile
