import { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { GameContext } from "../App"

const ResetPasswordConfirm = () => {
  const navigate = useNavigate()

  const { socket, signUpMessages, setSignUpMessages } = useContext(GameContext)
  const [newPassword, setNewPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()

  useEffect(() => {
    if (!socket.current) return

    socket.current.on("confirm-reset-password-response", (data) => {
      setSignUpMessages([data])

      if (data !== "Success") return

      navigate("/")
    })

    return () => {
      socket.current.off("confirm-reset-password-response")
    }
  }, [socket, socket.current, navigate, setSignUpMessages])

  function changePassword(newPassword, confirmPassword, e) {
    const query = new URLSearchParams(location.search)
    const token = query.get("token")

    if (!token) {
      return
    }

    e.preventDefault()
    socket.current.emit("confirm-reset-password", {
      token: token,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    })
  }

  const location = useLocation()

  return (
    <>
      <div id="authentication">
        {signUpMessages.map((msg, index) => (
          <div className="gameFontColors biggerText textAlign" key={index}>
            {msg}
          </div>
        ))}

        <div className="formPage">
          <div className="box">
            <form>
              <h2>Change Password</h2>
              <div className="inputBox">
                <input
                  type="password"
                  required
                  onChange={(e) => {
                    e.preventDefault()
                    setNewPassword(e.target.value)
                  }}
                />
                <span>New Password</span>
                <i></i>
              </div>
              <div className="inputBox">
                <input
                  type="password"
                  required
                  onChange={(e) => {
                    e.preventDefault()
                    setConfirmPassword(e.target.value)
                  }}
                />
                <span>Confirm Password</span>
                <i></i>
              </div>
              <input
                type="submit"
                value="Change Password"
                onClick={(e) => changePassword(newPassword, confirmPassword, e)}
              />
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPasswordConfirm
