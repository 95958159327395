import { useContext, useState, useEffect  } from "react"
import { GameContext } from "../../App"

const ResetPassword = () => {

  const [email, setEmail] = useState("")
  const [isButtonEnabled, setIsButtonEnabled] = useState(true);
  const {
    socket,
  } = useContext(GameContext)

  function sendResetPassword() {
    setIsButtonEnabled(false); // Disable the button
    const timer = setTimeout(() => setIsButtonEnabled(true), 2000);
    socket.current.emit("send-reset-password", {
      email, 
    })
    
    return () => clearTimeout(timer);
  }


  useEffect(() => {
    return () => clearTimeout(sendResetPassword);
  }, []);

  return (
    <>
      <div className="formPage">
        <div className="boxSignUp">
          <form autoComplete="off">
            <h2>Reset Password</h2>
            <div className="inputBox">
              <input
                type="text"
                required
                onChange={(e) => {
                  e.preventDefault()
                  setEmail(e.target.value)
                }}
              />
              <span>Email</span>
              <i></i>
            </div>
            <input
              type="submit"
              value="Reset Password"
              onClick={sendResetPassword}
              disabled={!isButtonEnabled}
            />
          </form>
        </div>
      </div>
    </>
  )
}

export default ResetPassword